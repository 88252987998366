import { CONFIRM_REQUEST, CONFIRM_SUCCESS } from '../actions/confirm';
import apiCall from '../../utils/api';

const actions = {
  [CONFIRM_REQUEST]: ({commit, dispatch}, attributes) => {
    return new Promise((resolve, reject) => {
      apiCall.post('/confirmation', { data: { 
                                              type: "confirmations", 
                                              attributes: attributes 
                                            }})
      .then(function (response) {
        commit(CONFIRM_SUCCESS)
        resolve(response)
      })
      .catch(function (error) {
        var message;
        if (error.response.data.errors[0].status === "422") {
          message = error.response.data.errors[0].detail
        } else {
          message = 'Something went wrong'
        }
        dispatch('showAlertMessage', message)
        reject(error)
      });
    })
  }
}

const mutations = {
  [CONFIRM_SUCCESS]: (state, attributes) => {
    state.confirmationToken = null
    state.registrationPhone = null
  }
}

export default {
  actions,
  mutations
}