<template>
  <div class="notification">
    <div class="notification__block">
      <div v-if="notification.type === 'success'">
        <span class="notification__icon--success fax-icon--done-green fax-icon"></span>
      </div>
      <div v-else>
        <span class="notification__icon--error fax-icon--red-cross fax-icon"></span>
      </div>
      <div>
        {{notification.text}}
      </div>
      <div @click="triggerClose(notification)" class="close-button" aria-label="Close alert" type="button">
        <span class="fax-icon--red-cross fax-icon"></span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    props: ['notification'],
    data: function () {
    	return { timer: null }
  	},
    mounted: function () {
      let timeout = this.notification.hasOwnProperty('timeout') ? this.notification.timeout : true;
      if (timeout) {
    	   let delay = this.notification.delay || 15000;
         this.timer = setTimeout(() => {
           this.triggerClose(this.notification)
         }, delay);
      }
    },
    methods: {
      triggerClose: function (notification) {
      	clearTimeout(this.timer);
        this.$store.commit('removeNotification', notification);
      }
    }
}
</script>
