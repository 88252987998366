import {
    GET_REMOTE_REGIONS,
    GET_REMOTE_REGIONS_SUCCESS,
    RESET_REMOTE_REGIONS,
    RESET_REMOTE_REGIONS_SUCCESS
} from '../actions/remote_regions';
import apiCall from '../../utils/api';

const state = {
  remoteRegions: []
}

const getters = {
  remoteRegions: state => state.remoteRegions
}

const actions = {
  [GET_REMOTE_REGIONS]: ({commit, dispatch}, country_id) => {
    return new Promise((resolve, reject) => {
      apiCall.get('/regions?filter[country_id]=' + country_id)
      .then(function (response) {
        commit(GET_REMOTE_REGIONS_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        dispatch('showAlertMessage', 'Something went wrong')
        reject(error);
      });
    })
  },
  [RESET_REMOTE_REGIONS]: ({commit}) => {
    commit(RESET_REMOTE_REGIONS_SUCCESS);
  }
}

const mutations = {
  [GET_REMOTE_REGIONS_SUCCESS]: (state, resp) => {
    state.remoteRegions = resp.data.data
  },
  [RESET_REMOTE_REGIONS_SUCCESS]: (state) => {
    state.remoteRegions = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
