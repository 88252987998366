import { RESTORE_REQUEST, RESTORE_SUCCESS, RESTORE_FAILURE } from '../actions/password_recovery';
import apiCall from '../../utils/api';

const state = {
  restorePassPhone: null
}

const getters = {
  userRestorePhone: state => state.restorePassPhone
}

const actions = {
  [RESTORE_REQUEST]: ({commit, dispatch}, attributes) => {
    return new Promise((resolve, reject) => {
      apiCall.post('/password_recovery', {
        data: {
          type: "password_recoveries",
          attributes: attributes
        }
      })
      .then(function (response) {
        commit(RESTORE_SUCCESS, attributes)
        resolve(response)
      })
      .catch(function (error) {
        var message;
        if (error.response.data.errors[0].status === "422") {
          message = error.response.data.errors[0].detail
        } else {
          message = 'Something went wrong'
        }
        commit(RESTORE_FAILURE)
        dispatch('showAlertMessage', message)
        reject(error)
      });
    })
  }
}

const mutations = {
  [RESTORE_SUCCESS]: (state, attributes) => {
    state.restorePassPhone = attributes.phone_number
  },
  [RESTORE_FAILURE]: (state) => {
    state.restorePassPhone = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
