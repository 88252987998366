export const GET_ORDERS = 'GET_ORDERS'
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS'
export const CREATE_PLAN_ORDER = 'CREATE_PLAN_ORDER'
export const CREATE_DID_ORDER = 'CREATE_DID_ORDER'

export const GET_POSSIBLE_FAX_PAGES = 'GET_POSSIBLE_FAX_PAGES'
export const GET_POSSIBLE_FAX_PAGES_SUCCESS = 'GET_POSSIBLE_FAX_PAGES_SUCCESS'

export const GET_PLANS = 'GET_PLANS'
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS'

export const PREPARE_PLAN_FOR_ORDER = 'PREPARE_PLAN_FOR_ORDER'
export const UPDATE_SELECTED_PLAN = 'UPDATE_SELECTED_PLAN'
