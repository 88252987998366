import store from '../store'

export default {
  /*
      access: {
          requiresLogin: true
      },
   */
  authorize(requiresLogin) {
    let isAuthenticated = store.getters.isAuthenticated;
    let result = 'notAuthorized';

    if (requiresLogin === true && isAuthenticated === false) {
      return 'loginIsRequired';
    }

    if (requiresLogin === true && isAuthenticated === true) {
      return 'authorized';
    }

    return result
  }
}
