import {assign} from "lodash/object";
import {union} from "lodash/array";
import {
  GET_FAX_HISTORIES,
  GET_FAX_HISTORIES_SUCCESS,
  REMOVE_FAX_HISTORY_RECORD,
  REMOVE_FAX_HISTORY_RECORD_SUCCESS,
  RESET_FAX_HISTORY
} from '../actions/fax_histories';
import apiCall from '../../utils/api';
import {SHOW_ALERT_MESSAGE} from "../actions/notifications";
import findIndex from 'lodash/findIndex';

const defaultState = {
  fax_histories: [],
  fax_histories_count: 0,
  loaded_page: null,
  all_pages: null
};
const state = {
  ...defaultState,
};

const getters = {
  faxHistories: state => state.fax_histories,
  faxHistoriesCount: state => state.fax_histories_count
};

const actions = {
  [GET_FAX_HISTORIES]: ({commit, dispatch, state}) => {
    return new Promise((resolve, reject) => {
      if (state.all_pages !== null && state.loaded_page === state.all_pages) {
        resolve()
      } else {
        const params = {
          params: {
            sort: '-created_at',
            'page[number]': state.loaded_page === null ? 1 : state.loaded_page + 1,
            'page[size]': 10
          }
        };
        apiCall
          .get("/fax_histories", params)
          .then(function (response) {
            commit(GET_FAX_HISTORIES_SUCCESS, response);
            resolve(response)
          })
          .catch(function (error) {
            dispatch('showAlertMessage', 'Can`t load history. Please try again later.');
            reject(error);
          });
      }
    })
  },
  [RESET_FAX_HISTORY]: ({commit}) => {
    console.log(Date.now(), "action", RESET_FAX_HISTORY);
    commit(RESET_FAX_HISTORY);
  },
  [REMOVE_FAX_HISTORY_RECORD]: ({commit, dispatch}, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload.id) {
        reject();
      }

      apiCall.delete(`/fax_histories/${payload.id}`)
        .then(() => {
          commit(REMOVE_FAX_HISTORY_RECORD_SUCCESS, payload);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

const mutations = {
  [GET_FAX_HISTORIES_SUCCESS]: (state, {data}) => {
    state.fax_histories = union(state.fax_histories, data.data);
    state.fax_histories_count = data.meta.total_records;
    state.loaded_page = data.meta.page.number;
    state.all_pages = data.meta.page.total_number;
  },
  [RESET_FAX_HISTORY]: (state) => {
    console.log(Date.now(), "mutation", RESET_FAX_HISTORY);
    assign(state, defaultState);
  },
  [REMOVE_FAX_HISTORY_RECORD_SUCCESS]: (state, payload) => {
    const index = findIndex(state.fax_histories, {id: payload.id});

    if (index === -1) {
      return;
    }

    state.fax_histories.splice(index, 1);
    state.fax_histories_count -= 1;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
}
