import Vue from 'vue';
import vbclass from 'vue-body-class';
import lodash from 'lodash';

import router from './router';
import middleware from './middleware';
import store from './store';
import "./scss/main.scss";

import App from './App.vue';

Object.defineProperty(Vue.prototype, '$lodash', { value: lodash });

Vue.use(require('@websanova/vue-upload'));
Vue.use(vbclass, router);

middleware(router);

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
});

// ToDo move to env variables
Vue.config.devtools = true;
