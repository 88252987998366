import {SHOW_ALERT_MESSAGE, SHOW_SUCCESS_MESSAGE} from "../actions/notifications";
import {Duration} from "../../utils/Duration";

const state = {
  NotificationStore: []
};

const actions = {
  [SHOW_SUCCESS_MESSAGE]: ({commit}, message) => {
    commit('addNotification', {
      text: message || "Success",
      type: "success",
      timeout: true,
      delay: Duration.seconds(5),
      id: Date.now(),
    })
  },
  [SHOW_ALERT_MESSAGE]: ({commit}, message) => {
    commit('addNotification', {
      text: message || "Something went wrong..",
      type: "alert",
      id: Date.now(),
      // timeout not specified - defaults to true
      // delay not specified, defaults to 3000
    });
  }
};

const getters = {
  getNotificationStore: state => state.NotificationStore
};

const mutations = {
  addNotification(state, notification) {
    const sameNotificationIndex = state.NotificationStore.findIndex(item => {
      return item.text === notification.text
          && item.type === notification.type;
    });

    if (sameNotificationIndex !== -1) {
      state.NotificationStore[sameNotificationIndex] = notification;
      return;
    }

    state.NotificationStore.push(notification);
  },
  removeNotification(state, notification) {
    const index = state.NotificationStore.indexOf(notification);
    state.NotificationStore.splice(index, 1)
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
