import Authorization from './Authorization'

export function requiresLogin(route) {
  return route.meta.requiresLogin !== undefined
    && route.meta.requiresLogin;
}

export default function UserHasPermissions(router) {
  router.beforeEach((to, from, next) => {
    let authorized = false;

    if (requiresLogin(to)) {
      authorized = Authorization.authorize(
        to.meta.requiresLogin,
      );

      if (authorized === 'loginIsRequired') {
        return router.push({name: 'login'});
      }

      if (authorized === 'notAuthorized') {
        return router.push({name: 'login'});
      }
    }

    return next();
  });
}
