<template>
    <div class="left_side">
        <router-link to="configurations" class="white-button">
            <span class="fax-icon--fax fax-icon"></span>
            <span>Sender Numbers</span>
        </router-link>
        <router-link to="receiving-options" class="white-button">
            <span class="fax-icon--receiving fax-icon"></span>
            <span>Receiving Options</span>
        </router-link>
        <router-link to="billing" class="white-button">
            <span class="fax-icon--billing fax-icon"></span>
            <span>Billing</span>
        </router-link>
        <router-link to="orders" class="white-button">
            <span class="fax-icon--doc-fax fax-icon"></span>
            <span>Orders</span>
        </router-link>
        <router-link to="remote-storages" class="white-button">
            <span class="fax-icon--remote-storage fax-icon"></span>
            <span>Remote Storages</span>
        </router-link>
        <router-link to="account-details" class="white-button">
            <span class="fax-icon--user fax-icon"></span>
            <span>Account Details</span>
        </router-link>
    </div>
</template>

<script>
  export default {
    data() {
      return {}
    }
  }
</script>
