<template>
  <div class="fx-fax-details">
    <div class="fx-fax-recipients">
      <div class="fx-fax-recipients__item" v-for="recipient in entity.fax_history_items">
        <div class="fx-fax-recipients__name">
          <Participant
              prefix="Recipient:"
              type="user"
              :with-flag="false"
              :name="recipient.to_name"
              :number="recipient.to"
          ></Participant>
        </div>
        <div class="fx-fax-recipients__status">
          <FaxStatus :status="recipient.status"></FaxStatus>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import FaxStatus from "./FaxStatus.vue";
  import Participant from "./Participant.vue";

  export default {
    name: "FaxDetails",
    components: {
      Participant,
      FaxStatus,
    },
    props: {
      entity: Object,
    },
  }
</script>

<style scoped lang="scss">
  .fx-fax-details {
    padding: 24px 32px 32px;
  }
  .fx-fax-recipients {
    &__item {
      display: flex;

      & + & {
        margin-top: 8px;
      }
    }

    &__name {
      flex: 1 1 auto;
      max-width: 324px;
      display: flex;
      align-items: center;

      font-weight: 600;
    }

    &__icon {
      flex: 0 0 auto;
    }

    &__label {
      flex: 0 0 auto;
      margin-left: 4px;
      color: #99A8BD;
    }

    &__value {
      flex: 1 1 auto;
      margin-left: 8px;
    }

    &__status {
      flex: 0 0 auto;
    }
  }
</style>
