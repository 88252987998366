<template>
  <div class="auth_block">
    <app-auth-top title="Registration"></app-auth-top>
      <div class="auth_block__middle">
        <form @submit.prevent="submitForm">
          <p class="auth_block__middle_decription">
              Please fill the form below to finish your registration.
          </p>
          <div class="form_group form_group_separate">
            <input type="text" v-model="phone_number" autocomplete="username" name="username" style="display: none;" />
            <input type="text" v-model="full_name" placeholder="Full Name" autocomplete="false" />
            <input type="password" v-model="password" placeholder="Password" autocomplete="new-password" />
            <input type="password" v-model="confirm_password" placeholder="Password confirmation" autocomplete="new-password" />
          </div>
          <div class="form_group_button">
              <button type="submit" class="blue-button" v-bind:disabled="!full_name || !password || !confirm_password">
                  REGISTER
              </button>
          </div>
        </form>
      </div>
  </div>
</template>

<script>
import AuthBlockTop from './components/welcome/AuthBlockTop.vue';
import { CONFIRM_REQUEST } from '../store/actions/confirm';

export default {
  components: {
    'app-auth-top': AuthBlockTop
  },
	data() {
	  return {
      full_name: '',
      password: '',
      confirm_password: ''
    }
	},
  created: function () {
    if (this.userConfirmationToken === null) {
      this.$router.push({name: 'registration'})
      this.$store.dispatch('showAlertMessage', 'Registration session was expired')
    }
  },
  methods: {
    submitForm: function(el) {
      const  confirmation_token = this.userConfirmationToken;
      const  { full_name, password, confirm_password } = this;
      this.$store.dispatch(CONFIRM_REQUEST, { full_name, password, confirm_password, confirmation_token }).then(() => {
        this.$router.push({name: 'login'})
        this.$store.dispatch('showSuccessMessage', 'You successfully registered. Please use entered credetials as login information.');
      })
    }
  },
  computed: {
    userConfirmationToken: function() {
      return this.$store.getters.userConfirmationToken;
    },
    phone_number: function () {
      return this.$store.getters.userRegistrationPhone;
    }
  }
}
</script>
