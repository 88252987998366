<template>
  <div class="spinner">
    <div class="spinner__icon rotating fax-icon fax-icon--spinner"></div>
  </div>
</template>

<script>
  export default {
    name: "Spinner"
  }
</script>

<style lang="scss" scoped>
  .spinner {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;

    &__icon {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -43px;
      margin-top: -43px;
      height: 86px;
      width: 86px;
    }
  }
</style>
