<template>
    <div class="page__content page__content--welcome">
        <app-notifications position="top"></app-notifications>

        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
import Notifications from './components/Notifications.vue';

export default {
    components: {
        'app-notifications': Notifications
    }
}
</script>
