import {CREATE_FAX, CREATE_FAX_SUCCESS} from '../actions/faxes';
import apiCall from '../../utils/api';
import {RESET_FAX_HISTORY} from "../actions/fax_histories";
import {SHOW_ALERT_MESSAGE} from "../actions/notifications";

const state = {
  processing_faxes: []
};

const getters = {
  processingFaxes: state => state.processing_faxes
};

const actions = {
  [CREATE_FAX]: ({commit, dispatch}, attributes) => {
    return new Promise((resolve, reject) => {
      apiCall.post('/faxes', attributes)
        .then(function (response) {
          // commit(CREATE_FAX_SUCCESS, response)
          dispatch(RESET_FAX_HISTORY);
          resolve(response);
        })
        .catch(function (error) {
          var message;
          if (error.response.data.errors[0].status === "422") {
            message = error.response.data.errors[0].detail
          } else {
            message = "Something went wrong";
          }
          dispatch(SHOW_ALERT_MESSAGE, message);
          reject(error);
        });
    })
  },
};

const mutations = {
  [CREATE_FAX_SUCCESS]: (state, resp) => {
    state.processing_faxes.push(resp);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
}
