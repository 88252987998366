<template>
    <div>
      <section class="main_content">
        <div class="container">
            <div class="top_line clearfix">
                <div class="left_side">
                    <h1>Configurations</h1>
                </div>
                <div class="right_side" v-if="isChangesMade">
                    <span class="grey-color-lighter chenges_title">
                        Changes were made  -
                    </span>
                    <a href="#" v-on:click.prevent="cancelChanges()" class="white-button">
                        <span class="fax-icon--cancel fax-icon"></span><span>Cancel</span>
                    </a>
                    <button class="blue-button" v-on:click.prevent="saveChanges()">
                        <span class="fax-icon--plus fax-icon"></span>
                        <span>Save Changes</span>
                    </button>
                </div>
            </div>
            <div class="main_sides clearfix">
                <configurationLeft></configurationLeft>
                <div class="right_side" v-show="!isLoading">
                  <div class="receiving_options_list" v-if="!isEmptyOtions || isStorageAddNow">
                      <label class="grey-color-lighter">RECEIVE FAX TO</label>
                      <div
                          class="receiving_options_list__item"
                          v-for="(receiving_option) in enabledReceivingOptions"
                          :key="receiving_option.id"
                      >
                          <div class="receiving_options_list__type">
                              <div class="browse_directory no_hover_pointer">
                                  <span class="browse_folder">
                                      <span :class="'fax-icon fax-icon--' + receiving_option.icon"></span>
                                      <span>{{ receiving_option.name }}</span>
                                  </span>
                              </div>
                          </div>
                          <div class="receiving_options_list__value">
                              <div class="browse_directory browse_directory_delim no_hover_pointer">
                                  <span class="browse_folder">
                                      <span class="grey-color-lighter">
                                          {{ receiving_option.remote_storage_name || receiving_option.email }}
                                      </span>
                                  </span>
                                  <span
                                      class="remove_deastination fax-icon--big-red-cross fax-icon"
                                      @click="removeReceivingOption(receiving_option.id)"
                                  ></span>
                              </div>
                          </div>
                      </div>
                      <div class="receiving_options_list__item" v-if="isStorageAddNow">
                          <div class="receiving_options_list__type">
                              <multiselect
                                  v-model="storageList"
                                  :options="storageListOptions"
                                  :searchable="false"
                                  :custom-label="customLabel"
                                  :show-labels="false"
                                  :allow-empty="false"
                                  label="title"
                                  placeholder="Select option"
                              >
                                <template slot="singleLabel" slot-scope="props">
                                  <span :class="'fax-icon fax-icon--' + props.option.icon"></span>
                                  <span class="option__desc">
                                    <span class="option__title">{{ props.option.title }}</span>
                                  </span>
                                </template>
                                <template slot="option" slot-scope="props">
                                  <span :class="'fax-icon fax-icon--' + props.option.icon"></span>
                                  <span class="option__desc">
                                    <span class="option__title">{{ props.option.title }}</span>
                                  </span>
                                </template>
                              </multiselect>
                          </div>
                          <div class="receiving_options_list__value" :style='storageList.method_type === undefined ? "opacity: 0;" : ""'>
                              <div class="browse_directory browse_directory_delim no_hover_pointer" v-if="storageList.method_type === 'email'">
                                <input type="text" placeholder="Email" v-model="storageListEmail" />
                              </div>
                              <div class="no_hover_pointer" style="display: inline-block;" v-else>
                                <multiselect
                                    v-model="storageListMethod"
                                    :options="remoteStoragesOptions"
                                    :searchable="false"
                                    :allow-empty="false"
                                    select-label=""
                                    deselect-label=""
                                    placeholder="Select option"
                                >
                                    <template slot="singleLabel" slot-scope="props">
                                      <span class="option__desc">
                                          <span class="option__title">{{ props.option.attributes.account_name }}</span>
                                      </span>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                        <span class="storage-list-method">{{ props.option.attributes.account_name }}</span>
                                    </template>
                                </multiselect>
                              </div>
                              <button @click="addNewReceivingOption" class="blue-button" style="vertical-align: top;height: 39px;">
                                <span class="fax-icon--plus fax-icon"></span>
                                <span>Add</span>
                              </button>
                          </div>
                      </div>
                      <div class="clearfix">
                          <div class="form_group">
                              <div class="addDestination" v-show="!isStorageAddNow" v-if="enabledReceivingOptions.length < 3" @click="addDestination">
                                  <span v-show="enabledReceivingOptions.length > 0" class="addDestination_title"><span>and</span></span>
                                  <span class="fax-icon--blue-plus fax-icon"></span>
                                  <span>Add destination</span>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="emty_data" v-else>
                        <div class="emty_data_icon">
                            <span class="fax-icon--receiving fax-icon"></span>
                        </div>
                        <div class="emty_data_title">
                            There is no any receiving option
                        </div>
                        <div class="emty_data_button">
                            <a class="blue-button" href="#" @click.prevent="addReceivingOption">
                              <span class="fax-icon--plus fax-icon"></span>
                              <span>Add New Option</span>
                          </a>
                        </div>
                  </div>
                </div>
            </div>
        </div>
      </section>
      <overlay :visible="isLoading"></overlay>
      <div class="modal_container" v-show="showModal">
        <div class="modal_block">
            <div class="top_line clearfix">
                <div class="left_side">
                    <h1>Choose folder</h1>
                </div>
                <div class="right_side">
                    <a class="white-button" href="#" v-on:click.prevent="showModal = !showModal">
                        <span class="fax-icon--cancel fax-icon"></span>
                        <span>Cancel</span>
                    </a>
                    <a class="blue-button" href="#">
                        <span class="fax-icon--plus fax-icon"></span>
                        <span>Add</span>
                    </a>
                </div>
            </div>
            <form action="">
                <div class="table">
                    <ul class="result_item folder_table">
                        <li class="table_no_hover">
                            <span class="back_folder">
                                <span class="fax-icon--arrow-left fax-icon"></span>
                                <span>Back</span>
                            </span>
                        </li>
                        <li>
                            <ul>
                                <li>
                                    <label>
                                        <input type="radio" name="folder" value="0">
                                        <div class="checkbox_custom_not_check">
                                            <span class="fax-icon--radio fax-icon"></span>
                                        </div>
                                        <div class="checkbox_custom_check">
                                            <span class="fax-icon--radio-selected fax-icon"></span>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <span class="fax-icon--folder fax-icon"></span>
                                    <span>Folder 1</span>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li>
                                    <label>
                                        <input type="radio" name="folder" value="0">
                                        <div class="checkbox_custom_not_check">
                                            <span class="fax-icon--radio fax-icon"></span>
                                        </div>
                                        <div class="checkbox_custom_check">
                                            <span class="fax-icon--radio-selected fax-icon"></span>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <span class="fax-icon--folder fax-icon"></span>
                                    <span>Folder 1</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </form>
        </div>
      </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Overlay from './components/Overlay.vue';
import configurationLeft from './components/configurationLeft.vue';
import { GET_RECEIVING_OPTIONS, BULK_MANAGE_RECEIVING_OPTIONS } from '../store/actions/receiving_options';
import { GET_REMOTE_STORAGES, GET_LOCAL_OR_LOAD_REMOTE_STORAGE } from '../store/actions/remote_storages';

export default {
  components: {
    'overlay': Overlay,
    configurationLeft,
    Multiselect
  },
  data() {
    return {
        showModal: false,
        isLoading: true,
        isStorageAddNow: false,
        storageList: {},
        storageListEmail: null,
        storageListMethod: null,
        initStorageListOptions: [
            { method_type: 'email', icon: 'email', title: 'Email' },
            { method_type: 'dropbox', icon: 'dropbox', title: 'Dropbox' },
            { method_type: 'google_drive', icon: 'google-drive', title: 'Google Drive' }
        ],
        receivingOptionsCollection: []
    };
  },
  computed: {
    storageListOptions() {
      return this.$lodash.filter(this.initStorageListOptions, (item) => {
        return !this.itemAlreadyAdded(item) && this.hasStoragesConnectedOfType(item.method_type);
      });
    },
    receivingOptions() {
      return this.$lodash.map(this.receivingOptionsCollection, (item) => {
        return this.$lodash.assignIn(item.attributes, {id: item.id}, this.receivingOptionViewData(item));
      });
    },
    remoteStoragesOptions() {
      var that = this;
      return this.$lodash.filter(this.$store.getters.remoteStorages, function(item) {
        return item.attributes.integration_type === that.storageList.method_type;
      });
    },
    enabledReceivingOptions() {
      return this.$lodash.filter(this.receivingOptions, (item) => {
        return !item.removed;
      });
    },
    isEmptyOtions(){
      return this.receivingOptionsCollection.length === 0;
    },
    isChangesMade() {
      return this.itemsToSave.length > 0;
    },
    itemsToSave() {
      return this.$lodash.filter(this.receivingOptionsCollection, function(item) {
        return item.attributes.new_record || item.attributes.removed;
      })
    }
  },
  methods: {
    hasStoragesConnectedOfType(type) {
      if (type === "email") {
        return true;
      }

      return this.$store.getters.remoteStorages.some(item => item.attributes.integration_type === type);
    },
    addReceivingOption: function() {
        return this.isStorageAddNow = true;
    },
    addNewReceivingOption: function() {
      var method = this.storageList.method_type,
          newId = new Date().getTime(),
      newItem;
      if (method === 'email') {
        newItem = {
          id: newId,
          type: "receiving_option_emails",
          attributes: {
            new_record: true,
            email: this.storageListEmail,
            id: newId,
          }
        }
      } else {
        newItem = {
          id: newId,
          type: "receiving_option_remote_storages",
          attributes: {
            new_record: true,
            remote_storage_id: this.storageListMethod.id,
            remote_storage_type: this.storageList.method_type,
            remote_storage_name: this.storageListMethod.attributes.account_name,
            id: newId
          }
        }
      }

      if (newItem) {
        this.receivingOptionsCollection.push(newItem);
      }

      this.isStorageAddNow = false;
      this.storageList = {};
      this.storageListEmail = null;
      this.storageListMethod = null;

      return true;
    },
    saveChanges: function() {
      var items;

      items = this.$lodash.map(this.itemsToSave, function(item) {
        var result;
        if (item.attributes.removed) {
           result = {
             id: item.attributes.receiving_option_id,
             removed: true
           }
        } else {
          if (item.type === "receiving_option_emails") {
            result = {
              new_record: true,
              type: 'email',
              attributes: {
                  email: item.attributes.email
              }
            }
          } else {
            result = {
              new_record: true,
              type: 'remote_storage',
              attributes: {
                  remote_storage_uuid: item.attributes.remote_storage_id
              }
            }
          }
        }
        return result;
      });

      this.$store.dispatch(BULK_MANAGE_RECEIVING_OPTIONS, { receiving_options: items }).then(() => {
        this.$store.dispatch(GET_RECEIVING_OPTIONS).then(() => {
          this.cancelChanges();
          this.$store.dispatch('showSuccessMessage', 'Successfully Updated!');
        });
      })
    },
    cancelChanges: function() {
      const items = this.$store.getters.receivingOptions || [];
      this.receivingOptionsCollection = items.slice();
    },
    itemAlreadyAdded: function(dropdownItem) {
      var arr = this.$lodash.filter(this.enabledReceivingOptions, (item) => {
        return item.method_type === dropdownItem.method_type;
      });

      return arr.length > 0;
    },
    customLabel ({ icon, title }) {
        return title
    },
    addFolder (value) {
        if (this.storageListOptions[0].title !== value.title) {
            //console.log(value.title)
        }
    },
    addDestination () {
        this.isStorageAddNow = !this.isStorageAddNow
    },
    removeReceivingOption(id) {
      var i = this.receivingOptionsCollection.findIndex(o => o.id === id);
      if (this.receivingOptionsCollection[i].attributes.new_record === true) {
        this.receivingOptionsCollection.splice(i, 1);
      } else {
        var obj = Object.assign({}, this.receivingOptionsCollection[i]);
        obj['attributes']['removed'] = true
        this.receivingOptionsCollection.splice(i, 1);
        this.receivingOptionsCollection.push(obj);
      }
    },
    receivingOptionViewData: function(item) {
      var resp;
      if (item.attributes.remote_storage_type === 'dropbox') {
        resp = { method_type: 'dropbox', name: 'Dropbox', icon: 'dropbox' }
      } else if (item.attributes.remote_storage_type === 'google_drive') {
        resp = { method_type: 'google_drive', name: 'Google Drive', icon: 'google-drive' }
      } else {
        resp = { method_type: 'email', name: 'Email', icon: 'email' }
      }
      return resp
    }
  },
  created: function() {
    this.$store.dispatch(GET_REMOTE_STORAGES).then(() => {
      this.$store.dispatch(GET_RECEIVING_OPTIONS).then(() => {
        this.cancelChanges();
        this.isLoading = false;
      });
    }).catch(() => {
      this.isLoading = false;
    });
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  .receiving-options {
    .multiselect {
        width: 240px;
    }

    .multiselect__content-wrapper {
        /*top: 48px;*/
        border-radius: 4px;
        -webkit-box-shadow: 0 9px 24px 0 rgba(88,116,158,0.35);
        -moz-box-shadow: 0 9px 24px 0 rgba(88,116,158,0.35);
        box-shadow: 0 9px 24px 0 rgba(88,116,158,0.35);
    }

    .multiselect__element {
        span {
            padding: 0;

            span {
                vertical-align: middle;
            }

            .storage-list-method {
              padding: 12px;
              display: block;
            }
        }

        .multiselect__option {
            display: flex;
            align-items: center;
            padding-left: 8px;

            .fax-icon {
                flex: 0 0 auto;
            }

            .option__desc {
                flex: 1 1 auto;
                padding-left: 4px;
            }

            &--selected,
            &--highlight {
              background-color: rgba(229, 234, 244, 0.5);
              color: #35495e;
            }
        }
    }


    .multiselect--active {
        z-index: 1;

        &:not(.multiselect--above) {
            .multiselect__current,
            .multiselect__input,
            .multiselect__tags {
                border-radius: 4px;
            }
        }
    }
  }
</style>
