<template>
    <div @click="dropDownClose()">
      <section class="main_content">
          <div class="container">
              <div class="top_line clearfix">
                  <div class="left_side">
                      <h1>Configurations</h1>
                  </div>
                  <div class="right_side" v-if="!isEmptyOtions">
                      <a class="blue-button" href="#" v-on:click.prevent.stop="dropDownOpen('top')">
                          <span class="fax-icon--plus fax-icon"></span>
                          <span>Add Remote Storage</span>
                      </a>
                      <ul class="dropdonw_action_block" v-show="isTopDropDown">
                          <li v-on:click.prevent="createIntegration('dropbox')">
                              <span>
                                <span class="fax-icon--dropbox fax-icon"></span>
                                <span class="type_name">
                                    Dropbox
                                </span>
                              </span>
                          </li>
                          <li v-on:click.prevent="createIntegration('google_drive')">
                              <span>
                                <span class="fax-icon--google-drive fax-icon"></span>
                                <span class="type_name">
                                    Google Drive
                                </span>
                              </span>
                          </li>
                      </ul>
                  </div>
              </div>
              <div class="main_sides clearfix">
                  <configurationLeft></configurationLeft>
                  <div class="right_side" v-show="!isLoading">
                      <div class="table" v-if="!isEmptyOtions">
                          <ul class="table_top with_sort" v-show="show_panel">
                              <li>
                                  <label>
                                      <input type="checkbox" value="0" id="table_check_all" v-model="selectAll">
                                      <div class="checkbox_custom_not_check">
                                          <span class="fax-icon--checkbox fax-icon"></span>
                                      </div>
                                      <div class="checkbox_custom_check">
                                          <span class="fax-icon--checkbox-selected fax-icon"></span>
                                      </div>
                                  </label>
                              </li>
                              <li>
                                  <span>STORAGE</span>
                                  <a class="white-button white-button-pannel white-button-empty-word no-margin-both" href="#"
                                  @click.prevent="filter_table('from_name')">
                                      <span class="fax-icon--arrow fax-icon"></span>
                                  </a>
                              </li>
                              <li>
                                  <span>STATUS</span>
                                  <a class="white-button white-button-pannel white-button-empty-word no-margin-both" href="#"
                                  @click.prevent="filter_table('from_phone')">
                                      <span class="fax-icon--arrow fax-icon"></span>
                                  </a>
                              </li>
                              <li>
                                  <span>FOLDER SIZE</span>
                                  <a class="white-button white-button-pannel white-button-empty-word no-margin-both" href="#">
                                      <span class="fax-icon--arrow fax-icon"></span>
                                  </a>
                              </li>
                          </ul>
                          <ul class="table_top table_top_action" v-show="show_action">
                              <li>
                                  <label>
                                      <input type="checkbox" value="0" id="table_check_all" v-model="selectAll">
                                      <div class="checkbox_custom_not_check">
                                          <span class="fax-icon--checkbox fax-icon"></span>
                                      </div>
                                      <div class="checkbox_custom_check">
                                          <span class="fax-icon--checkbox-selected fax-icon"></span>
                                      </div>
                                  </label>
                              </li>
                              <li>
                                  <span>Choose an action:</span>
                                  <a class="white-button white-button-pannel" 
                                     href="#"
                                     v-show="isShowReauthorize"
                                  >
                                      <span class="fax-icon--lock fax-icon"></span>
                                      <span>Reauthorize</span>
                                  </a>
                                  <a class="white-button white-button-pannel" 
                                     href="#"
                                     @click.prevent="bulkDeleteRemoteStorages()"
                                  >
                                      <span class="fax-icon--trash fax-icon"></span>
                                      <span>Delete</span>
                                  </a>

                              </li>
                          </ul>
                          <div class="table_search clearfix"  :class="{ active: searchVal.length > 0 ? true : false }">
                              <div>
                                  <span class="fax-icon--search fax-icon"></span>
                                  <a href="#"  @click.prevent="clearSearch">
                                      <span class="fax-icon--big-red-cross fax-icon"></span>
                                  </a>
                              </div>
                              <div>
                                  <input type="text" name="" id="" placeholder="Search" v-model="isActiveInput">
                              </div>
                          </div>
                          <ul class="result_item">
                              <li v-for="(remote_storage, index) in filteredRemoteStorages" :key='index'>
                                  <ul>
                                      <li>
                                          <label>
                                              <input type="checkbox" :id="remote_storage.id" :value="remote_storage.id" v-model="checkedRemoteStorages" @change="showPanel" number>
                                              <div class="checkbox_custom_not_check">
                                                  <span class="fax-icon--checkbox fax-icon"></span>
                                              </div>
                                              <div class="checkbox_custom_check">
                                                  <span class="fax-icon--checkbox-selected fax-icon"></span>
                                              </div>
                                          </label>
                                      </li>
                                      <li>
                                        <span v-if="remote_storage.integration_type === 'dropbox'" class="fax-icon--dropbox fax-icon"></span>
                                        <span v-else class="fax-icon--google-drive fax-icon"></span>
                                        <span class="user_from">
                                            {{ remote_storage.account_name || 'dropbox' }}
                                        </span>
                                      </li>
                                      <li class="fx-table__cell">
                                          <div>
                                              <span
                                                  class="fax-icon--done-green fax-icon"
                                                  v-if="remote_storage.status === 'connected'"
                                              ></span>
                                              <span
                                                  class="fax-icon--red-cross fax-icon"
                                                  v-else-if="remote_storage.status === 'revoked'"
                                              ></span>
                                              <span v-if="remote_storage.status === 'connected'">Active</span>
                                              <span v-else-if="remote_storage.status === 'revoked'">Reauthorization required</span>
                                              <span v-else-if="remote_storage.status === 'pending'">Pending</span>
                                          </div>
                                      </li>
                                      <li>
                                          <span>{{ remote_storage.notes }}</span>
                                      </li>
                                  </ul>
                              </li>
                          </ul>
                      </div>
                      <div class="emty_data" v-else>
                            <div class="emty_data_icon">
                                <span class="fax-icon--remote-storage fax-icon"></span>
                            </div>
                            <div class="emty_data_title">
                                There is no any remote storages
                            </div>
                            <div class="emty_data_button" style="position: relative;max-width: 170px;margin: 0 auto;">
                                <a class="blue-button" href="#" @click.prevent.stop="dropDownOpen()">
                                    <span class="fax-icon--plus fax-icon"></span>
                                    <span>Add New Option</span>
                                </a>
                                <ul class="dropdonw_action_block" v-show="isCenterDropDown">
                                    <li v-on:click.prevent="createIntegration('dropbox')">
                                        <span>
                                          <span class="fax-icon--dropbox fax-icon"></span>
                                          <span class="type_name">
                                              Dropbox
                                          </span>
                                        </span>
                                    </li>
                                    <li v-on:click.prevent="createIntegration('google_drive')">
                                        <span>
                                          <span class="fax-icon--google-drive fax-icon"></span>
                                          <span class="type_name">
                                              Google Drive
                                          </span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <overlay :visible="isLoading"></overlay>
    </div>
</template>

<script>
import Overlay from './components/Overlay.vue';
import configurationLeft from './components/configurationLeft.vue';
import { CREATE_INTEGRATION,
         GET_REMOTE_STORAGES,
         BULK_DELETE_REMOTE_STORAGES } from '../store/actions/remote_storages';

export default {
  components: { 
    configurationLeft,
    'overlay': Overlay
  },
  data() {
    return {
      name: '',
      file_name: '',
      selected: [],
      show_panel: true,
      show_action: false,
      checkedRemoteStorages: [],
      faxesFiltered: [],
      searchVal: "",
      showModal: false,
      isTopDropDown: false,
      isCenterDropDown: false,
      isLoading: true
    };
  },
  computed: {
    selectAll: {
      get: function() {
        this.showPanel();
        return this.filteredRemoteStorages
          ? this.checkedRemoteStorages.length == this.filteredRemoteStorages.length
          : false;
      },
      set: function(value) {
        console.log(value);
        var checked = [];

        if (value) {
          this.filteredRemoteStorages.forEach(function(item) {
            checked.push(item.id);
          });
        }

        this.checkedRemoteStorages = checked;
      }
    },
    isActiveInput: {
      get: function() {
        return this.searchVal;
      },
      // сеттер:
      set: function(value) {
        //console.log(value);
        this.searchVal = value;
        this.searchingFilter(value);
      }
    },
    isShowReauthorize: function() {
      if (this.checkedRemoteStorages.length === 0 || this.checkedRemoteStorages.length > 1) {
        return false;
      }
      const id = this.checkedRemoteStorages[0];
      const item = this.$lodash.find(this.remoteStorages, function(i) { 
        return id === i.id; 
      })
    
      return item.auth_url !== null;
    },
    remoteStorages: function() {
      return this.$lodash.map(this.$store.getters.remoteStorages, (item) => {
        return this.$lodash.assignIn(item.attributes, {id: item.id});
      });
    },
    filteredRemoteStorages() {
      if (!this.searchVal) {
        return this.remoteStorages;
      }

      return this.remoteStorages.filter(storage => {
        return [storage.account_name, storage.notes].some(fieldValue => {
          if (!fieldValue) {
            return false;
          }

          return fieldValue.toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1;
        });
      });
    },
    isEmptyOtions(){
      return this.remoteStorages.length === 0 ? true : false;
    }
  },
  methods: {
    filter_table: function(label) {
      /*str = '+38 (051) 670 - 92 - 48';
        str = str.replace(/[^\w\s]/gi, '');
        str = str.replace(/\s/g, '');
        console.log(str);*/

      function compare(a, b) {
        if (a[label] < b[label]) {
          console.log(a[label], b[label]);
          return -1;
        }
        if (a[label] > b[label]) {
          return 1;
        }
        return 0;
      }

      return (this.faxesFiltered = this.faxes.sort(compare));
    },
    showPanel: function() {
      var checked = this.checkedRemoteStorages;
      if (checked.length === 0) {
        this.show_panel = true;
        this.show_action = false;
      } else {
        this.show_panel = false;
        this.show_action = true;
      }
    },
    dropDownOpen(type) {
      if (type === 'top') {
        this.isTopDropDown = !this.isTopDropDown;
      } else {
        this.isCenterDropDown = !this.isCenterDropDown;
      }
    },
    dropDownClose() {
      if (this.isTopDropDown) {
        this.isTopDropDown = false;
      }

      if (this.isCenterDropDown) {
        this.isCenterDropDown = false;
      }
    },
    clearSearch: function() {
      this.isActiveInput = "";
    },
    searchingFilter: function(value) {
      //Seaching filter
      if (value.length > 0) {
        var search = value.toLowerCase();

        return (this.remoteStorages.filter(contact => {
          for (var prop in remoteStorage) {
            return remoteStorage[prop].toLowerCase().indexOf(search.toLowerCase()) > -1;
          }
        }));
      } else {
        return this.filteredRemoteStorages;
      }
    },
    createIntegration: function(type) {
      const that = this;
      this.isLoading = true;
      that.isTopDropDown = false;
      that.isCenterDropDown = false;
      this.isRemoteStorageDropDown = false;
      return that.$store.dispatch(CREATE_INTEGRATION, type).then(() => {
        window.open(that.$store.getters.last_integration_url, 'Remote Storage', 'location=no, toolbar=no, menubar=no');
        window.addEventListener('focus', function() {
          that.$store.dispatch(GET_REMOTE_STORAGES).then(() => {
            that.isLoading = false;
          });
       });
      }).catch(function (error) {
        that.isLoading = false;
      });
    },
    bulkDeleteRemoteStorages: function() {
      this.$store.dispatch(BULK_DELETE_REMOTE_STORAGES, { remote_storage_ids: this.checkedRemoteStorages }).then(() => {
        this.checkedRemoteStorages = [];
        this.$store.dispatch('showSuccessMessage', 'Successfully deleted!');
      })
    },
  },
  created() {
    this.searchingFilter("");
    this.$store.dispatch(GET_REMOTE_STORAGES).then(() => {
      this.isLoading = false
    }).catch(function (error) {
      this.isLoading = false
    });
  }
};
</script>
