<template>
  <div class="overlay" v-if="visible">
    <Spinner class="overlay__spinner"></Spinner>
  </div>
</template>

<script>
  import Spinner from "./Spinner.vue";

  export default {
    components: {
      Spinner,
    },
    props: ['visible'],
  }
</script>

<style lang="scss" scoped>
  @import "../../../src/scss/mainvariables";

  .overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: $overlay-color;

    &__spinner {
      background: none;
    }
  }
</style>
