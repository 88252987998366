<template>
  <div class="auth_block">
    <app-auth-top title="Restore Password"></app-auth-top>
    <div class="auth_block__middle">
      <div class="counter">
        Step 2/3
      </div>
      <p class="auth_block__middle_decription">
        We have sent you a restore code. Please add this code to field below to confirm that your account.
      </p>
      <div class="form_group">
        <div class="one-digit-inputs--wrapper">
          <input @input="submitForm" v-model="smsCode[0]" autofocus type="text" maxLength="1" value=""/>
          <input @input="submitForm" v-model="smsCode[1]" type="text" maxLength="1" value=""/>
          <input @input="submitForm" v-model="smsCode[2]" type="text" maxLength="1" value=""/>
          <input @input="submitForm" v-model="smsCode[3]" type="text" maxLength="1" value=""/>
        </div>
      </div>
    </div>
    <div class="auth_block__bottom">
        <ul class="links">
            <li>
                <router-link to="login">Back to Login Page</router-link>
            </li>
            <div v-show="showResendMessage" class="divider"></div>
            <li v-show="showResendMessage">
                <a href="" @click.prevent="resendCode()">Haven't received an SMS? Resend</a>
            </li>
        </ul>
    </div>
  </div>
</template>

<script>
import AuthBlockTop from './components/welcome/AuthBlockTop.vue';
import { RESTORE_VERIFY_REQUEST } from '../store/actions/password_recovery_verification';
import {RESTORE_REQUEST} from "../store/actions/password_recovery";

export default {
  components: {
      'app-auth-top': AuthBlockTop
  },
  data(){
    return {
      showResendMessage: false,
      smsCode: ['','','','']
    }
  },
  created:function() {
    if (this.userRegistrationPhone === null) {
      this.$router.push({name: 'registration'});
    }
    this.showResendMessageByTimeout();
  },
  methods:{
    showResendMessageByTimeout(value) {
      const msg_time = value || 15000;

      return setTimeout(function () {
        this.showResendMessage = true;
      }.bind(this), msg_time)
    },
    resendCode() {
      this.showResendMessage = false;

      const phone_number = this.userRegistrationPhone;
      this.$store.dispatch(RESTORE_REQUEST, { phone_number }).then(() => {
        this.showResendMessageByTimeout(30000);
      });
    },
    submitForm(el) {
      const smsCodeToSend = this.smsCode.join(''),
            nextEl = el.target.nextElementSibling;

      if (el.data !== null && nextEl) {
        nextEl.focus();
      }

      if (smsCodeToSend.length === 4) {
        const pin = smsCodeToSend;
        const phone_number = this.userRegistrationPhone;
        this.$store.dispatch(RESTORE_VERIFY_REQUEST, { pin, phone_number }).then(() => {
          this.$router.push({name: 'password-recovery-confirmation'})
        }).catch((e)=>{
          this.smsCode = ['','','','']
        })
      }
    }
  },
  computed: {
    userRegistrationPhone: function() {
      return this.$store.getters.userRestorePhone;
    }
  }
}
</script>
