import { 
    GET_RECEIVING_OPTIONS,
    GET_RECEIVING_OPTIONS_SUCCESS,
    BULK_MANAGE_RECEIVING_OPTIONS
} from '../actions/receiving_options';
import apiCall from '../../utils/api';

const state = { 
  receivingOptions: []
}

const getters = {
  receivingOptions: state => state.receivingOptions
}

const actions = {
  [GET_RECEIVING_OPTIONS]: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      apiCall.get('/receiving_options?include=receiving_optionable')
      .then(function (response) {
        commit(GET_RECEIVING_OPTIONS_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        dispatch('showAlertMessage', 'Something went wrong')
        reject(error);
      });
    })
  },
  [BULK_MANAGE_RECEIVING_OPTIONS]: ({commit, dispatch}, attributes) => {
    return new Promise((resolve, reject) => {
      apiCall.post('/bulk_manage_receiving_options', { data: { 
                                              type: "bulk_manage_receiving_options", 
                                              attributes: attributes 
                                            }})
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        var message;
        if (error.response.data.errors[0].status === "422") {
          message = error.response.data.errors[0].detail
        } else {
          message = 'Something went wrong'
        }
        dispatch('showAlertMessage', message)
        reject(error);
      });
    })
  }
}

const mutations = {
  [GET_RECEIVING_OPTIONS_SUCCESS]: (state, resp) => {
    state.receivingOptions = resp.data.included
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}