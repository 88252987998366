<template>
  <label class="fx-checkbox">
    <input type="checkbox" :id="index" :value="index" v-model="model">
    <div class="fax-icon--checkbox fax-icon"></div>
    <div class="fax-icon--checkbox-selected fax-icon"></div>
    <slot class="fx-checkbox__label"></slot>
  </label>
</template>

<script>
  export default {
    name: "Checkbox",
    props: {
      index: String,
      value: Boolean,
    },
    computed: {
      model: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .fx-checkbox {
    .fax-icon {
      display: none;
    }

    input {
      & ~ .fax-icon--checkbox {
        display: inline-flex;
      }

      &:checked {
        & ~ .fax-icon--checkbox {
          display: none;
        }

        & ~ .fax-icon--checkbox-selected {
          display: inline-flex;
        }
      }
    }
  }
</style>
