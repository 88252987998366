import store from "../store";
import router from '../router'
import {GET_USER_PLANS} from "../store/actions/user_plans";
import {requiresLogin} from "./UserHasPermissions";

function checkUserPlanOrRedirect(hasPlan, next) {
  if (hasPlan) {
    return next();
  }

  return router.push({name: "new-package"});
}

export function CheckPlanIsChosen(router) {
  router.beforeEach((to, from, next) => {
    const skipRoutes = ["new-package", "new-package-details"];
    const isRouteRequiresLogin = requiresLogin(to);

    if (skipRoutes.indexOf(to.name) !== -1 || !isRouteRequiresLogin) {
      return next();
    }

    if (store.getters.hasPlan) {
      return next();
    }

    return store.dispatch(GET_USER_PLANS).then(() => {
      return checkUserPlanOrRedirect(store.getters.hasPlan, next);
    });
  });
}
