import Vue from 'vue'
import VueRouter from 'vue-router'

import History from './views/History/History.vue'
import Login from './views/Login.vue'
import Registration from './views/Registration.vue'
import Verification from './views/Verification.vue'
import Confirmation from './views/Confirmation.vue'
import NewFax from './views/NewFax/NewFax.vue'
import PhoneBook from './views/PhoneBook/PhoneBook.vue'
import PhoneBookGroups from './views/PhoneBook/PhoneBookGroups.vue'
import Configurations from './views/Configurations.vue'
import ReceivingOptions from './views/ReceivingOptions.vue'
import RemoteStorages from './views/RemoteStorages.vue'
import AccountDetails from './views/AccountDetails/AccountDetails.vue'
import Billing from './views/Billing.vue'
import Orders from './views/Order.vue'
import PasswordRecovery from './views/PasswordRecovery.vue'
import PasswordRecoveryVerification from './views/PasswordRecoveryVerification.vue'
import PasswordRecoveryConfirmation from './views/PasswordRecoveryConfirmation.vue'
import NewPackage from './views/NewPackage.vue'
import NewPackageDetails from './views/NewPackageDetails.vue'

Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    base: __dirname,
    routes: [
        {
          name: 'root',
          path: '/',
          component: History,
          meta: {
            requiresLogin: true
          }
        },
        {
          name: 'login',
          path: '/login',
          component: Login,
          meta: {
            bodyClass: 'login'
          }
        },
        {
          name: 'password-recovery',
          path: '/password-recovery',
          component: PasswordRecovery,
          meta: {
            bodyClass: 'login'
          }
        },
        {
          name: 'password-recovery-verification',
          path: '/password-recovery-verification',
          component: PasswordRecoveryVerification,
          meta: {
            bodyClass: 'login'
          }
        },
        {
          name: 'password-recovery-confirmation',
          path: '/password-recovery-confirmation',
          component: PasswordRecoveryConfirmation,
          meta: {
            bodyClass: 'login'
          }
        },
        {
          name: 'registration',
          path: '/registration',
          component: Registration,
          meta: {
            bodyClass: 'registration'
          }
        },
        {
          name: 'verification',
          path: '/verification',
          component: Verification,
          meta: {
            bodyClass: 'verification'
          }
        },
        {
          name: 'confirmation',
          path: '/confirmation',
          component: Confirmation,
          meta: {
            bodyClass: 'confirmation'
          }
        },
        {
          name: 'history',
          path: '/history',
          component: History,
          meta: {
            requiresLogin: true,
            bodyClass: 'history'
          }
        },
        {
          name: 'new-fax',
          path: '/new-fax',
          component: NewFax,
          meta: {
            requiresLogin: true,
            bodyClass: 'new-fax'
          }
        },
        {
          name: 'phonebook',
          path: '/phonebook',
          component: PhoneBook,
          meta: {
            requiresLogin: true,
            bodyClass: 'phonebook'
          }
        },
        {
          name: 'groups',
          path: '/groups',
          component: PhoneBookGroups,
          meta: {
            requiresLogin: true,
            bodyClass: 'groups'
          }
        },
        {
          name: 'configurations',
          path: '/configurations',
          component: Configurations,
          meta: {
            requiresLogin: true,
            bodyClass: 'configurations'
          }
        },
        {
          name: 'receiving-options',
          path: '/receiving-options',
          component: ReceivingOptions,
          meta: {
            requiresLogin: true,
            bodyClass: 'receiving-options'
          }
        },
        {
          name: 'remote-storages',
          path: '/remote-storages',
          component: RemoteStorages,
          meta: {
            requiresLogin: true,
            bodyClass: 'remote-storages'
          }
        },
        {
          name: 'account-details',
          path: '/account-details',
          component: AccountDetails,
          meta: {
            requiresLogin: true,
            bodyClass: 'account-details'
          }
        },
        {
          name: 'billing',
          path: '/billing',
          component: Billing,
          meta: {
            requiresLogin: true,
            bodyClass: 'billing'
          }
        },
        {
          name: 'orders',
          path: '/orders',
          component: Orders,
          meta: {
            requiresLogin: true,
            bodyClass: 'orders'
          }
        },
        {
          name: 'new-package',
          path: '/new-package',
          component: NewPackage,
          meta: {
            requiresLogin: true,
            bodyClass: 'orders'
          }
        },
        {
          name: 'new-package-details',
          path: '/new-package-details',
          component: NewPackageDetails,
          meta: {
            requiresLogin: true,
            bodyClass: 'orders'
          }
        }
    ]
})
