import {
    GET_REMOTE_CITIES,
    GET_REMOTE_CITIES_SUCCESS,
    RESET_REMOTE_CITIES,
    RESET_REMOTE_CITIES_SUCCESS
} from '../actions/remote_cities';
import apiCall from '../../utils/api';

const state = {
  remoteCities: []
}

const getters = {
  remoteCities: state => state.remoteCities
}

const actions = {
  [GET_REMOTE_CITIES]: ({commit, dispatch}, attributes) => {
    return new Promise((resolve, reject) => {
      var region_selection = '';
      if (attributes.region_id) {
        region_selection = '&filter[region_id]=' + attributes.region_id;
      }
      apiCall.get('/cities?filter[country_id]=' + attributes.country_id + region_selection)
      .then(function (response) {
        commit(GET_REMOTE_CITIES_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        dispatch('showAlertMessage', 'Something went wrong')
        reject(error);
      });
    })
  },
  [RESET_REMOTE_CITIES]: ({commit}) => {
    commit(RESET_REMOTE_CITIES_SUCCESS);
  }
}

const mutations = {
  [GET_REMOTE_CITIES_SUCCESS]: (state, resp) => {
    state.remoteCities = resp.data.data
  },
  [RESET_REMOTE_CITIES_SUCCESS]: (state) => {
    state.remoteCities = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
