<template>
  <div class="account_info">
    <div class="account_info_left account_info_block">
      <p>Account Plan: <span class="primary_text">{{plan.attributes.plan_name}}</span> <span class="green">{{state}}</span></p>
      <p>Expiration Date: <span class="primary_text">{{planExpiration}}</span></p>
    </div>
    <div
        class="account_info_right account_info_block"
        v-if="action_buttons"
    >
      <a href="#" class="fx-button"
         v-if="plan.attributes.renewable && hasActivePlan"
         :class="{'fx-button--loading': busy}"
         @click.prevent="disableAutoRenew()"
      >Stop Auto Renew</a>

      <a href="#" class="fx-button"
         v-if="!plan.attributes.renewable && hasActivePlan"
         :class="{'fx-button--loading': busy}"
         @click.prevent="enableAutoRenew()"
      >Enable Auto Renew</a>

      <a href="#" class="fx-button"
         v-if="!plan.attributes.renewable && (hasActivePlan || hasDeactivatedPlan)"
         :class="{'fx-button--loading': manual_renew_busy}"
         @click.prevent="manualRenew()"
      >Manual Renew</a>
    </div>
  </div>
</template>

<script>
  import {upperFirst} from "lodash/string";
  import moment from "moment";
  import {DISABLE_AUTO_RENEW, ENABLE_AUTO_RENEW, MANUAL_RENEW, GET_USER_PLANS} from "../../store/actions/user_plans";
  import {SHOW_ALERT_MESSAGE, SHOW_SUCCESS_MESSAGE} from "../../store/actions/notifications";

  export default {
    name: "AccountPlan",
    data() {
      return {
        busy: false,
        action_buttons: true,
        manual_renew_busy: false,
      };
    },
    computed: {
      plan() {
        return this.$store.getters.userPlans[0];
      },
      planExpiration() {
        var result = '-'
        if (this.plan && this.plan.attributes.billed_to) {
          result = moment(this.plan.attributes.billed_to).format("L");
        }
        return result;
      },
      hasActivePlan() {
        return this.$store.getters.hasActivePlan;
      },
      hasDeactivatedPlan() {
        return this.$store.getters.hasDeactivatedPlan;
      },
      state() {
        return upperFirst(this.plan.attributes.state);
      },
    },
    methods: {
      enableAutoRenew() {
        this.busy = true;
        this.$store
          .dispatch(ENABLE_AUTO_RENEW)
          .then((response) => {
            const {payment_url} = response.attributes;

            if (!payment_url) {
              return;
            }

            window.location = payment_url;
          }).catch(() => {
            this.busy = false;
            this.$store.dispatch(SHOW_ALERT_MESSAGE, "Unable to enable auto renew");
          });
      },

      manualRenew() {
        this.manual_renew_busy = true;
        this.$store
          .dispatch(MANUAL_RENEW)
          .then((response) => {
            const {payment_url} = response.attributes;

            if (!payment_url) {
              return;
            }

            window.location = payment_url;
          }).catch(() => {
            this.manual_renew_busy = false;
            this.$store.dispatch(SHOW_ALERT_MESSAGE, "Unable to renew plan manually");
          });
      },

      disableAutoRenew() {
        this.busy = true;

        this.$store
          .dispatch(DISABLE_AUTO_RENEW)
          .then(() => {
            this.$store.dispatch(SHOW_SUCCESS_MESSAGE, "Auto renew has been disabled");
          })
          .finally(() => {
            this.busy = false;
            this.$store.dispatch(GET_USER_PLANS)
          });
      },
    },
    created() {
      const { status } = this.$route.query;
      if (status == 'pending') {
        this.action_buttons = false;
        this.$store.dispatch(SHOW_SUCCESS_MESSAGE, "Autorenew enable in progress");
      }
    },
  }
</script>
