<template>
  <div class="auth_block">
    <app-auth-top title="Restore Password"></app-auth-top>
    <div class="auth_block__middle">
      <div class="counter">
        Step 1/3
      </div>
      <form @submit.prevent="submitForm">
        <p class="auth_block__middle_decription">
            Please fill your phone number. We will send you a restore code by SMS
        </p>
        <div class="form_group phone_number single_input" :class={phone_number_active}>
          <input ref="phone" type="number" placeholder="Phone number" v-model="phone_number" maxlength="20" @focus="focusInkNumberInput()" @blur="focusOutNumberInput()"/>
        </div>
        <div class="form_group_button">
            <button type="submit" class="blue-button" v-bind:disabled="!phone_number">
                SEND
            </button>
        </div>
      </form>
    </div>
    <div class="auth_block__bottom">
        <ul class="links">
            <li>
                <router-link to="login">Back to login page</router-link>
            </li>
        </ul>
    </div>
  </div>
</template>

<script>
  import AuthBlockTop from './components/welcome/AuthBlockTop.vue';
  import { RESTORE_REQUEST } from '../store/actions/password_recovery';

  export default {
    components: {
      'app-auth-top': AuthBlockTop
    },
  	data() {
      return {
        phone_number: '',
        phone_number_active: false
      }
    },
    methods: {
      focusOutNumberInput: function() {
        if (this.phone_number == '') {
          this.phone_number_active = false;
          this.$refs.phone.placeholder = 'Phone number'
        }
      },
      focusInkNumberInput:function() {
        if (this.phone_number == '') {
          this.phone_number_active = true;
          this.$refs.phone.placeholder = ''
        }
      },
      submitForm:function() {
        const phone_number = this.phone_number;
        const phone_country_code = this.phone_country_code;

        this.$store.dispatch(RESTORE_REQUEST, { phone_number }).then(() => {
          this.$router.push({name: 'password-recovery-verification'})
          // this.$store.dispatch('showSuccessMessage', 'We sent you SMS with verification code.');
        })

      }
    }
  }
</script>
