import {
    GET_ORDERS,
    GET_ORDERS_SUCCESS,
    CREATE_PLAN_ORDER,
    GET_POSSIBLE_FAX_PAGES,
    GET_POSSIBLE_FAX_PAGES_SUCCESS,
    GET_PLANS,
    GET_PLANS_SUCCESS,
    PREPARE_PLAN_FOR_ORDER,
    UPDATE_SELECTED_PLAN
} from '../actions/orders';
import apiCall from '../../utils/api';

const state = {
  orders: [],
  possible_fax_pages: [],
  plans: [],
  selected_plan: {}
}

const getters = {
  orders: state => state.orders,
  possible_fax_pages: state => state.possible_fax_pages,
  plans: state => state.plans,
  selected_plan: state => state.selected_plan
}

const actions = {
  [GET_ORDERS]: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      apiCall.get('/orders?sort=-created_at')
      .then(function (response) {
        commit(GET_ORDERS_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        dispatch('showAlertMessage', 'Something went wrong')
        reject(error);
      });
    })
  },
  [CREATE_PLAN_ORDER]: ({commit, dispatch}, attributes) => {
    return new Promise((resolve, reject) => {
      apiCall.post('/plan_orders', {
        data: {
          type: "plan_orders",
          attributes: attributes
        }
      }).then(function (response) {
        resolve(response)
      }).catch(function (error) {
        var message;
        if (error.response.data.errors[0].status === "422") {
          message = error.response.data.errors[0].detail
        } else {
          message = 'Something went wrong'
        }
        dispatch('showAlertMessage', message)
        reject(error);
      });
    })
  },
  [GET_POSSIBLE_FAX_PAGES]: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      apiCall.get('/fax_pages')
      .then(function (response) {
        commit(GET_POSSIBLE_FAX_PAGES_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        dispatch('showAlertMessage', 'Something went wrong')
        reject(error);
      });
    })
  },
  [GET_PLANS]: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      apiCall.get('/plans?sort=price_monthly')
      .then(function (response) {
        commit(GET_PLANS_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        dispatch('showAlertMessage', 'Something went wrong')
        reject(error);
      });
    })
  },
  [PREPARE_PLAN_FOR_ORDER]: ({commit, dispatch}, atributes) => {
    return new Promise((resolve, reject) => {
      commit(UPDATE_SELECTED_PLAN, atributes);
      resolve(true);
    })
  },
}

const mutations = {
  [GET_ORDERS_SUCCESS]: (state, resp) => {
    state.orders = resp.data.data
  },
  [GET_POSSIBLE_FAX_PAGES_SUCCESS]: (state, resp) => {
    state.possible_fax_pages = resp.data.data
  },
  [GET_PLANS_SUCCESS]: (state, resp) => {
    state.plans = resp.data.data
  },
  [UPDATE_SELECTED_PLAN]: (state, data) => {
    state.selected_plan = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
