<template>
    <div>
      <section class="main_content">
          <div class="container">
              <div class="top_line clearfix">
                  <div class="left_side">
                      <h1>Configurations</h1>
                  </div>
              </div>
              <div class="main_sides clearfix">
                  <configurationLeft></configurationLeft>
                  <div class="right_side" v-show="!isLoading">
                      <div class="table without_check_all" v-if="!isEmptyOptions">
                          <ul class="table_top">
                              <li>
                                  <span>REFERENCE</span>
                              </li>
                              <li>
                                  <span>TYPE</span>
                              </li>
                              <li>
                                  <span>DATE</span>
                              </li>
                              <li>
                                  <span>STATUS</span>
                              </li>
                              <li>
                                  <span>AMMOUNT</span>
                              </li>
                          </ul>
                          <ul class="result_item">
                              <li v-for="(order, index) in orders" :key='index'>
                                  <ul>
                                      <li>
                                        <span class="user_from">
                                            {{ order.reference }}
                                        </span>
                                      </li>
                                      <li>
                                        <span>{{ order.sti_type }}</span>
                                      </li>
                                      <li>
                                        <span>{{ order.created_at }}</span>
                                      </li>
                                      <li>
                                          <span>{{ order.status }}</span>
                                      </li>
                                      <li>
                                        <span class="table_price">
                                            $ {{ order.total_amount }}
                                        </span>
                                      </li>
                                  </ul>
                              </li>
                          </ul>
                      </div>
                      <div class="emty_data" v-else>
                          <div class="emty_data_icon">
                              <span class="fax-icon--billing fax-icon"></span>
                          </div>
                          <div class="emty_data_title">
                              There is no any orders history
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    </div>
</template>

<script>
import configurationLeft from './components/configurationLeft.vue';
import { GET_ORDERS } from '../store/actions/orders';

export default {
components: {
  configurationLeft
 },
 data() {
   return {
     isLoading: true
   };
 },
  computed: {
    orders: function() {
      return this.$lodash.map(this.$store.getters.orders, (item) => {
        return this.$lodash.assignIn(item.attributes, {id: item.id});
      });
    },
    isEmptyOptions(){
      return this.orders.length === 0;
    }
  },
  created() {
    this.$store.dispatch(GET_ORDERS).finally(() => {
      this.isLoading = false;
    });
  }
};
</script>
