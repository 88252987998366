<template>
  <div class="notifications" :class="position === 'top' ? 'notifications__top' : 'notifications__bottom'">
    <notification v-for="notification in notifications" :notification="notification" v-bind:key="notification.id">
    </notification>
  </div>
</template>

<script>
import Notification from './Notification.vue'

export default {
  components: {
    notification: Notification
  },
  props: ['position'],
  data () {
    return {
      //notifications: this.notificationStore()
    }
  },
  computed: {
    notifications: function() {
      return this.$store.getters.getNotificationStore
    }
  }
}
</script>

<style lang="scss">
  .notifications {
    position: fixed;
    left: 50%;
    z-index: 1;

    &__top {
      top: 56px;
    }

    &__bottom {
      bottom: 56px;
    }

    .notification {
      min-width: 277px;
      margin: 15px;
      box-shadow: 0 9px 24px 0 rgba(88,116,158,0.35);
      z-index: 9999;
      border: 1px solid #E5EAF4;
      border-radius: 4px;
      background: #fff;
      position: relative;
      left: -50%;

      &__block {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        min-height: 56px;

        > div:first-child {
          flex: 1 50px;
          margin: 12px 15px;
        }

        > div:nth-child(2) {
          flex: 1 100%;
          font-size: 12px;
          line-height: 55px;
          white-space: nowrap;
          font-weight: 600;
          padding-right: 10px;
        }

        > div:last-child {
          flex: 1 50px;
          text-align: center;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 20%;
            width: 1px;
            height: 60%;
            background-color: #E5EAF4;
          }

          span {
            cursor: pointer;
            margin: 10px 0;
            font-size: 3.3em;
          }
        }
      }

      &__icon {
        &--error {
          border: 2px solid #e36262;
          border-radius: 16px;
          box-sizing: border-box;
        }

        &--success {
          border: 2px solid #5ac064;
          border-radius: 16px;
          box-sizing: border-box;
        }
      }
    }
  }
</style>
