<template>
  <span class="fx-icon" :class="iconClassName"></span>
</template>

<script>
export default {
  props: ['country_code'],
  data() {
    return {}
  },
  computed: {
    iconClassName() {
      const countryCode = this.country_code || "";

      return `fx-icon--flag-${countryCode.toLocaleLowerCase()}`;
    }
  }
}
</script>
