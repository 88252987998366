import axios from 'axios';
import assignIn from 'lodash/assignIn';
import store from '../store'
import router from '../router'
import {AUTH_LOGOUT} from '../store/actions/auth';
import {SHOW_ALERT_MESSAGE} from "../store/actions/notifications";

const apiCall = axios.create({
  baseURL: process.env.API_URL + 'api/rest/public/',
  headers: {
    'Content-Type': 'application/vnd.api+json',
    'Accept': 'application/vnd.api+json'
  }
});

apiCall.interceptors.request.use(function (config) {
  let token = window.localStorage.getItem('user-token')
  if (token) {
    assignIn(config.headers, {'api-key': token})
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

apiCall.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response === undefined) {
    store.dispatch(SHOW_ALERT_MESSAGE, 'Server is not responding.');
  } else if (error.response.status === 401) {
    store.dispatch(AUTH_LOGOUT, null, {root: true}).then(() => {
      router.push({name: 'login'});

      store.dispatch(SHOW_ALERT_MESSAGE, 'Your session was expired.');
    });
  } else if (error.response.status === 502) {
    store.dispatch(SHOW_ALERT_MESSAGE, 'Server maintenance.');
  }
  return Promise.reject(error);
});

export default apiCall;
