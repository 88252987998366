import { 
    GET_CONTACTS,
    GET_CONTACTS_SUCCESS,
    GET_CONTACT,
    GET_CONTACT_SUCCESS,
    CREATE_CONTACT,
    CREATE_CONTACT_SUCCESS,
    CREATE_CONTACT_ERROR,
    UPDATE_CONTACT,
    UPDATE_CONTACT_SUCCESS,
    UPDATE_CONTACT_ERROR,
    BULK_DELETE_CONTACTS,
    BULK_DELETE_CONTACTS_SUCCESS,
    BULK_DELETE_CONTACTS_ERROR,
    BULK_CREATE_CONTACTS,
    BULK_CREATE_CONTACTS_SUCCESS,
    BULK_CREATE_CONTACTS_ERROR
} from '../actions/contacts';
import apiCall from '../../utils/api';
import findIndex from 'lodash/findIndex';
import reject from 'lodash/reject';
import includes from 'lodash/includes';
import union from 'lodash/union';

const state = { 
  contacts: []
}

const getters = {
  contacts: state => state.contacts
}

const actions = {
  [GET_CONTACTS]: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      apiCall.get('/contacts')
      .then(function (response) {
        commit(GET_CONTACTS_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        dispatch('showAlertMessage', 'Can`t load contacts. Please try again later.')
        reject(error);
      });
    })
  },
  [GET_CONTACT]: ({commit, dispatch}, id) => {
    return new Promise((resolve, reject) => {
      apiCall.get('/contacts/' + id)
      .then(function (response) {
        commit(GET_CONTACT_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        dispatch('showAlertMessage', 'Remote contact not found!')
        reject(error);
      });
    })
  },
  [CREATE_CONTACT]: ({commit, dispatch}, attributes) => {
    return new Promise((resolve, reject) => {
      apiCall.post('/contacts', { data: { 
                                              type: "contacts", 
                                              attributes: attributes 
                                            }})
      .then(function (response) {
        commit(CREATE_CONTACT_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        var message;
        if (error.response.data.errors[0].status === "422") {
          message = error.response.data.errors[0].detail
        } else {
          message = 'Something went wrong'
        }
        dispatch('showAlertMessage', message)
        commit(CREATE_CONTACT_ERROR)
        reject(error);
      });
    })
  },
  [UPDATE_CONTACT]: ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
      apiCall.patch('/contacts/' + data.id, { data: { 
                                              id: data.id,
                                              type: "contacts", 
                                              attributes: data.attributes 
                                            }})
      .then(function (response) {
        commit(UPDATE_CONTACT_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        var message;
        if (error.response.data.errors[0].status === "422") {
          message = error.response.data.errors[0].detail
        } else {
          message = 'Something went wrong'
        }
        dispatch('showAlertMessage', message)
        // commit(CREATE_CONTACT_ERROR)
        reject(error);
      });
    })
  },
  [BULK_DELETE_CONTACTS]: ({commit, dispatch}, attributes) => {
    return new Promise((resolve, reject) => {
      apiCall.post('/bulk_delete_contacts', { data: { 
                                              type: "bulk_delete_contacts", 
                                              attributes: attributes 
                                            }})
      .then(function (response) {
        commit(BULK_DELETE_CONTACTS_SUCCESS, attributes.contact_ids)
        resolve(response)
      })
      .catch(function (error) {
        var message;
        if (error.response.data.errors[0].status === "422") {
          message = error.response.data.errors[0].detail
        } else {
          message = 'Something went wrong'
        }
        dispatch('showAlertMessage', message)
        // commit(BULK_DELETE_CONTACTS_ERROR)
        reject(error);
      });
    })
  },
  [BULK_CREATE_CONTACTS]: ({commit, dispatch}, attributes) => {
    return new Promise((resolve, reject) => {
      apiCall.post('/bulk_create_contacts', { data: { 
                                              type: "bulk_create_contacts", 
                                              attributes: attributes 
                                            }})
      .then(function (response) {
        commit(BULK_CREATE_CONTACTS_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        var message;
        if (error.response.data.errors[0].status === "422") {
          message = error.response.data.errors[0].detail
        } else {
          message = 'Something went wrong'
        }
        dispatch('showAlertMessage', message)
        // commit(BULK_CREATE_CONTACTS_ERROR)
        reject(error);
      });
    })
  }
}

const mutations = {
  [GET_CONTACTS_SUCCESS]: (state, resp) => {
    state.contacts = resp.data.data
  },
  [GET_CONTACT_SUCCESS]: (state, resp) => {
    state.contacts.push(resp.data.data);
  },
  [CREATE_CONTACT_SUCCESS]: (state, resp) => {
    state.contacts.push(resp.data.data);
  },
  [UPDATE_CONTACT_SUCCESS]: (state, resp) => {
    var index = findIndex(state.contacts, {id: resp.data.data.id});
    state.contacts.splice(index, 1, resp.data.data);
  },
  [BULK_DELETE_CONTACTS_SUCCESS]: (state, contact_ids) => {
    var newArray = reject(state.contacts, function(c) { 
                    return includes(contact_ids, c.id); 
                  });
    state.contacts = newArray;
  },
  [BULK_CREATE_CONTACTS_SUCCESS]: (state, resp) => {
    var newArray = union(state.contacts, resp.data.data.attributes.saved_contacts);
    state.contacts = newArray;
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}