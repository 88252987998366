<template>
  <div class="fx-configure-options modal_block">
    <span class="fx-configure-options__icon fax-icon--good fax-icon"></span>
    <div class="fx-configure-options__title">Congratulations!</div>
    <div class="fx-configure-options__text">{{text}}. The last step is to configure the location where you want to
      receive your faxes.</div>
    <router-link to="receiving-options" class="blue-button">
      <span class="fax-icon--settings fax-icon"></span>
      <span>Configure receiving options</span>
    </router-link>
  </div>
</template>

<script>
  export default {
    name: "ConfigureOptionsModal",
    props: {
      paymentPassed: Boolean,
    },
    computed: {
      text() {
        return this.paymentPassed
          ? "You successfully purchased the package"
          : "Your payment for package is being verified"
      }
    },
  }
</script>

<style lang="scss" scoped>
  .fx-configure-options {
    text-align: center;
    padding: 16px 0 72px !important;

    &__icon {
      height: 80px;
      width: 80px;
    }

    &__title {
      color: #415A7F;
      font-size: 22px;
      font-weight: 600;
      line-height: 32px;
    }

    &__text {
      font-size: 12px;
      font-weight: 600;
      line-height: 22px;
      width: 360px;
      margin: 16px auto 22px;
    }
  }
</style>
