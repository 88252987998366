<template>
  <div>
    <div class="input-with-dropdown--wrapper">
      <div class="country-phone-code-block">
        <span class="pointer" :class="dropdownIcon" @click="openCountriesDropdown()"></span>
        <IconFlag v-bind:country_code="countryPhoneModel.iso"/>
        <span>+ {{ countryPhoneModel.dial_code }}</span>
      </div>
      <div class="input-block">
        <input type="phone" v-model="phoneMasked" maxlength="20" />
      </div>
    </div>
    <Multiselect
      v-model="countryPhoneModel"
      ref="countryMultiselect"
      :options="countriesOptions"
      :searchable="false"
      :show-labels="false"
      :preselect-first="true"
      :allow-empty="false"
      label="name"
      track-by="name"
      @input="onCountryCodeChanged"
      @open="dropdownNarrow = 'open'"
      @close="dropdownNarrow = 'close'"
    >
      <template slot="option" slot-scope="props">
        <IconFlag v-bind:country_code="props.option.iso"/>
        <span class="option__title">{{ props.option.name }}</span>
      </template>
    </Multiselect>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect';
  import IconFlag from "./IconFlag.vue";
  import CountryPhoneCodes from '../../constants/country_phone_codes';

  export default {
    components: {
      IconFlag,
      Multiselect,
    },

    props: {
      value: {
        type: String,
        default: '',
      },
      code: {
        type: String,
        default: null,
      }
    },

    data() {
      const phoneModel = CountryPhoneCodes.find(phoneCode => {
        return phoneCode.iso === this.code;
      });

      return {
        countryPhoneModel: phoneModel || "",
        countriesOptions: CountryPhoneCodes,
        dropdownIcon: 'fax-icon--arrow-down fax-icon',
      };
    },

    computed: {
        phoneMasked: {
            get: function () {
                return this.value.replace(/^(\d{3})(\d{1,3})(\d*)/, '($1) $2 $3');
            },
            set: function (value) {
              value = value.replace(/\D/g, '');

              this.$emit('input', {
                  phone: value,
                  countryPhoneModel: this.countryPhoneModel,
              });
            }
        },
    },

    methods: {
      openCountriesDropdown() {
        this.$refs.countryMultiselect.$el.focus();
      },
      onCountryCodeChanged(countryPhoneModel) {
        this.$emit("input", {
          phone: this.value,
          countryPhoneModel,
        });
      },
    },
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.registration {
  .multiselect {
    height: 0;
    min-height: 0;
  }
  .multiselect__select, .multiselect__tags {
    display: none;
  }
  .multiselect__content-wrapper {
    margin-top: 5px;
    border-top: 1px solid #e8e8e8;
    border-radius: 5px;
  }
  .multiselect, .multiselect__input, .multiselect__single {
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
  }
  .multiselect__element {
    position: relative;
  }
  .multiselect__element:before {
    content: '';
    position: absolute;
    left: 15%;
    bottom: 0;
    width: 80%;
    border-bottom: 1px solid #E5EAF4;
  }
  .multiselect__option {
    display: flex;
    flex-flow: row nowrap;
  }
  .multiselect__option span:last-child {
    padding-left: 10px;
  }
  .multiselect__option--highlight {
    background-color: rgba(229,234,244,0.5);
    color: #35495e;
  }
  .multiselect__option--selected, .multiselect__option--highlight.multiselect__option--selected {
    background-color: #6391D6;
    color: #fff;
  }
}
</style>
