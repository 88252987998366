<template>
  <div>
    <h2>Top up Page Ballance</h2>
    <div class="bottom-16 clearfix">
      <div class="form_group">
        <label class="grey-color-lighter">Qty Pages</label>
        <div class="browse_directory no_hover_pointer">
          <span class="browse_folder">
            <select v-model="selected">
              <option v-for="packet in packets" :value="packet">{{packet.attributes.qty}}</option>
            </select>
          </span>
        </div>
        <div class="account_info_block_account" v-if="selected">
          <a href="#" @click.prevent="purchase">Pay ${{selected.attributes.amount}} by PayPal</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {GET_PAGES_PACKETS, SUBMIT_PACKET_ORDER} from "../../store/actions/pages_packets";

  export default {
    name: "BalanceTopUp",

    data() {
      return {
        selected: null,
      };
    },

    computed: {
      packets() {
        return this.$store.getters.pagesPackets;
      },
    },

    methods: {
      purchase() {
        if (!this.selected) {
          return;
        }

        this.$store
          .dispatch(SUBMIT_PACKET_ORDER, {id: this.selected.id})
          .then(responseData => {
            window.location = responseData.attributes.payment_url;
          });
      },
    },

    created() {
      this.$store.dispatch(GET_PAGES_PACKETS);
    },
  }
</script>

<style>

</style>
