import {
    GET_REMOTE_COUNTRIES,
    GET_REMOTE_COUNTRIES_SUCCESS
} from '../actions/remote_countries';
import apiCall from '../../utils/api';

const state = {
  remoteCountries: []
}

const getters = {
  remoteCountries: state => state.remoteCountries
}

const actions = {
  [GET_REMOTE_COUNTRIES]: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      apiCall.get('/countries')
      .then(function (response) {
        commit(GET_REMOTE_COUNTRIES_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        dispatch('showAlertMessage', 'Something went wrong')
        reject(error);
      });
    })
  }
}

const mutations = {
  [GET_REMOTE_COUNTRIES_SUCCESS]: (state, resp) => {
    state.remoteCountries = resp.data.data
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
