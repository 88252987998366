<template>
    <div class="page__content page__content--dashboard">
        <app-header></app-header>

        <transition name="fade" mode="out-in">
            <router-view></router-view>
        </transition>

        <app-notifications></app-notifications>
    </div>
</template>

<script>
import headerTemplate from './components/header.vue';
import Notifications from './components/Notifications.vue';

export default {
    components: {
        'app-header': headerTemplate,
        'app-notifications': Notifications
    }
}
</script>
