import store from '../store'

export default function RedirectIfAuthenticated(router) {
  router.beforeEach((to, from, next) => {
    let isAuthenticated = store.getters.isAuthenticated;

    if ((isAuthenticated) && (to.name === 'login' || to.name === 'register')) {
      return router.push({name: 'history'});
    }

    return next();
  })
}
