import { REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE } from '../actions/register';
import apiCall from '../../utils/api';

const state = { 
  registrationPhone: null
}

const getters = {
  userRegistrationPhone: state => state.registrationPhone
}

const actions = {
  [REGISTER_REQUEST]: ({commit, dispatch}, attributes) => {
    return new Promise((resolve, reject) => {
      apiCall.post('/registration', { data: { 
                                              type: "registrations", 
                                              attributes: attributes 
                                            }})
      .then(function (response) {
        commit(REGISTER_SUCCESS, attributes)
        resolve(response)
      })
      .catch(function (error) {
        var message;
        if (error.response.data.errors[0].status === "422") {
          message = error.response.data.errors[0].detail
        } else {
          message = 'Something went wrong'
        }
        commit(REGISTER_FAILURE)
        dispatch('showAlertMessage', message)
        reject(error)
      });
    })
  }
}

const mutations = {
  [REGISTER_SUCCESS]: (state, attributes) => {
    state.registrationPhone = attributes.phone_number
  },
  [REGISTER_FAILURE]: (state) => {
    state.registrationPhone = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}