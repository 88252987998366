import {
    GET_SENDER_NUMBERS,
    GET_SENDER_NUMBERS_SUCCESS,
    UPDATE_SENDER_NUMBER,
    UPDATE_SENDER_NUMBER_SUCCESS,
} from '../actions/sender_numbers';
import apiCall from '../../utils/api';
import findIndex from 'lodash/findIndex';

const state = {
  sender_numbers: []
}

const getters = {
  senderNumbers: state => state.sender_numbers
}

const actions = {
  [GET_SENDER_NUMBERS]: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      apiCall.get('/sender_numbers')
      .then(function (response) {
        commit(GET_SENDER_NUMBERS_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        dispatch('showAlertMessage', 'Something went wrong')
        reject(error);
      });
    })
  },
  [UPDATE_SENDER_NUMBER]: ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
      apiCall.patch('/sender_numbers/' + data.id, { data: {
                                              id: data.id,
                                              type: "sender_numbers",
                                              attributes: data.attributes
                                            }})
      .then(function (response) {
        if (data.attributes.is_default === true) {
          dispatch(GET_SENDER_NUMBERS).then(() => {
            resolve(response)
          })
        } else {
          commit(UPDATE_SENDER_NUMBER_SUCCESS, response)
          resolve(response)
        }
      })
      .catch(function (error) {
        var message;
        if (error.response.data.errors[0].status === "422") {
          message = error.response.data.errors[0].detail
        } else {
          message = 'Something went wrong'
        }
        dispatch('showAlertMessage', message)
        // commit(CREATE_CONTACT_ERROR)
        reject(error);
      });
    })
  }
}

const mutations = {
  [GET_SENDER_NUMBERS_SUCCESS]: (state, resp) => {
    state.sender_numbers = resp.data.data
  },
  [UPDATE_SENDER_NUMBER_SUCCESS]: (state, resp) => {
    var index = findIndex(state.sender_numbers, {id: resp.data.data.id});
    state.sender_numbers.splice(index, 1, resp.data.data);
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
