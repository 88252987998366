var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fx-recipients-select"},[_c('ul',{staticClass:"recipients_list"},_vm._l((_vm.innerModel),function(recipient){return _c('li',[(recipient.isGroup())?_c('span',{staticClass:"fax-icon--group fax-icon"}):_c('span',{staticClass:"fax-icon--user fax-icon"}),_vm._v(" "),(recipient.isPhone())?_c('FormattedNumber',{staticClass:"user_from",attrs:{"phone":recipient.entity.number}}):_c('span',{staticClass:"user_from"},[_vm._v(_vm._s(recipient.label))]),_vm._v(" "),_c('a',{staticClass:"remove_recipient",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.removeRecipient(recipient.id)}}},[_c('span',{staticClass:"fax-icon--big-red-cross fax-icon"})])],1)}),0),_vm._v(" "),_c('div',{staticClass:"recipients-dropdown",class:{
      'recipients-dropdown--has-list': _vm.innerModel.length,
      'recipients-dropdown--full-list': _vm.isFullList,
     }},[_c('multiselect',{ref:"multiselect",attrs:{"options":_vm.options,"searchable":true,"show-labels":false,"hide-selected":true,"multiple":true,"taggable":false,"custom-label":_vm.searchLabel,"open-direction":"bottom","track-by":"id","placeholder":"Add recipients"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
     var values = ref.values;
     var search = ref.search;
     var isOpen = ref.isOpen;
return [(values.length && !isOpen)?_c('span',{staticClass:"multiselect__placeholder"},[_c('span',{staticClass:"fax-icon--blue-plus fax-icon"}),_vm._v(" "),_c('span',[_vm._v("Add recipients")])]):(values.length || isOpen)?_c('span',{staticClass:"fax-icon--search fax-icon"}):_c('span')]}},{key:"option",fn:function(props){return [(props.option.isGroup())?_c('div',{staticClass:"option__icon fax-icon--group fax-icon"}):_c('div',{staticClass:"option__icon fax-icon--user fax-icon"}),_vm._v(" "),_c('div',{staticClass:"option__title"},[(props.option.isPhone())?_c('FormattedNumber',{attrs:{"phone":props.option.entity.number}}):_c('span',[_vm._v(_vm._s(props.option.label))])],1)]}},{key:"noResult",fn:function(props){return [_c('div',{staticClass:"recipients-dropdown__new-option",on:{"click":function($event){return _vm.onNewClick(props.search)}}},[_c('span',{staticClass:"fax-icon",class:{'fax-icon--green-plus': _vm.isValidPhoneNumber(props.search)}}),_vm._v(" "),(_vm.isNumber(props.search))?_c('span',[(_vm.isValidPhoneNumber(props.search))?_c('FormattedNumber',{attrs:{"phone":_vm.extractNumber(props.search)}}):_c('span',[_vm._v("+"+_vm._s(_vm.extractNumber(props.search)))])],1):_c('span',[_vm._v("Not found")])])]}}]),model:{value:(_vm.innerModel),callback:function ($$v) {_vm.innerModel=$$v},expression:"innerModel"}},[_vm._v(" "),_vm._v(" "),_vm._v(" "),_c('template',{slot:"noOptions"},[_c('span',{staticClass:"fax-icon"}),_vm._v(" "),_c('span',[_vm._v("No contacts")])]),_vm._v(" "),_c('template',{slot:"placeholder"},[_c('span',{staticClass:"fax-icon--blue-plus fax-icon"}),_vm._v(" "),_c('span',[_vm._v("Add recipients")])])],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }