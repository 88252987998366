import apiCall from '../../utils/api';
import {
  DISABLE_AUTO_RENEW,
  ENABLE_AUTO_RENEW,
  MANUAL_RENEW,
  GET_USER_PLANS,
  GET_USER_PLANS_SUCCESS,
  PATCH_USER_PLAN_SUCCESS
} from "../actions/user_plans";
import {UserPlanState} from "../../constants/user_plan_state";

const state = {
  userPlans: [],
  hasPlan: false,
  updatePlanNeeded: false,
  hasActivePlan: false,
  hasDeactivatedPlan: false,
};

const getters = {
  userPlans: state => state.userPlans,
  hasPlan: state => state.hasPlan,
  updatePlanNeeded: state => state.updatePlanNeeded,
  hasActivePlan: state => state.hasActivePlan,
  hasDeactivatedPlan: state => state.hasDeactivatedPlan,
};

function autoRenewPlans(enabled) {
  return apiCall
    .post("/autorenew_plans", {
      data: {
        type: "autorenew_plans",
        attributes: {
          enabled,
        },
      },
    });
}

const actions = {
  [GET_USER_PLANS]: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      apiCall.get('/user_plans?page[size]=1&order=-created_at')
        .then(function (response) {
          commit(GET_USER_PLANS_SUCCESS, response);
          resolve(response)
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  [ENABLE_AUTO_RENEW]: () => {
    return new Promise((resolve, reject) => {
      autoRenewPlans(true)
        .then(response => resolve(response.data.data))
        .catch(error => reject(error));
    });
  },
  [MANUAL_RENEW]: () => {
    return new Promise((resolve, reject) => {
        apiCall.post("/manual_renew_plans", {
          data: {
            type: "manual_renew_plans",
            attributes: {}
          }
        })
        .then(response => resolve(response.data.data))
        .catch(error => reject(error));
    });
  },
  [DISABLE_AUTO_RENEW]: () => {
    return new Promise((resolve, reject) => {
      autoRenewPlans(false)
        .then(() => resolve())
        .catch(error => reject(error));
    });
  },
};

// function userPlansPredicate(planA, planB) {
//   const stateA = planA.attributes.state;
//   const stateB = planB.attributes.state;
// 
//   if (stateA !== stateB) {
//     return stateA === UserPlanState.active
//       ? -1
//       : 1;
//   }
// 
//   const billedFromA = Number(new Date(planA.attributes.billed_from));
//   const billedFromB = Number(new Date(planB.attributes.billed_from));
// 
//   return billedFromB - billedFromA;
// }

const mutations = {
  [GET_USER_PLANS_SUCCESS]: (state, resp) => {
    state.userPlans = resp.data.data; //.sort(userPlansPredicate);
    state.hasPlan = state.userPlans.length > 0;
    state.updatePlanNeeded = state.hasPlan && state.userPlans[0].attributes.update_plan_needed;
    state.hasActivePlan = state.userPlans.some(userPlan => userPlan.attributes.state === UserPlanState.active);
    state.hasDeactivatedPlan = state.userPlans.some(userPlan => userPlan.attributes.state === UserPlanState.deactivated);
  },
  [PATCH_USER_PLAN_SUCCESS]: (state, response) => {
    const planId = response.data.data.id;

    state.userPlans = state.userPlans.map(userPlan => {
      if (userPlan.id !== planId) {
        return userPlan;
      }

      return response.data.data;
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
}
