<template>
  <div class="auth_block">
    <app-auth-top title="fax.center"></app-auth-top>
    <div class="auth_block__middle">
      <form class="login" @submit.prevent="login">
        <div class="form_group phone_number" :class={phone_number_active}>
          <input ref="phone" type="login" autocomplete="login" name="username" placeholder="Phone number" autofocus v-model="phone_number" maxlength="20"
                 @focus="focusInkNumberInput()" @blur="focusOutNumberInput()"/>
        </div>
        <div class="form_group last password_input">
          <div class="show_pass" :class={hide:password_visibility} @click="showPasswordInput()"></div>
          <input ref="password" required type="password" v-model="password" placeholder="Password" />
        </div>
        <div class="form_group_button">
            <button type="submit" class="blue-button" v-bind:disabled="!phone_number || !password">
                LOGIN
            </button>
        </div>
      </form>
    </div>
    <div class="auth_block__bottom">
        <ul class="links">
            <li>
                <router-link to="registration">Not Registered? Create an Account</router-link>
            </li>
            <div class="divider"></div>
            <li>
                <router-link to="password-recovery">Restore Your Password</router-link>
            </li>
        </ul>
    </div>
  </div>
</template>

<script>
  import {AUTH_REQUEST} from '../store/actions/auth';
  import AuthBlockTop from './components/welcome/AuthBlockTop.vue';

  export default {
  components: {
    'app-auth-top': AuthBlockTop
  },
	data() {
	  return {
      password: '',
      phone_number: '',
      phone_number_active: false,
      password_visibility: false
	  }
	},
  methods: {
    openCountriesDropdown() {
      this.$refs.country_multiselect.$el.focus();
    },
    login: function () {
      const { phone_number, password } = this
      this.$store.dispatch(AUTH_REQUEST, { phone_number, password }).then(() => {
        this.$router.push({name: 'history'})
      })
    },
    focusOutNumberInput: function() {
      if (this.phone_number == '') {
        this.phone_number_active = false;
        this.$refs.phone.placeholder = 'Phone number'
      }
    },
    focusInkNumberInput:function() {
      if (this.phone_number == '') {
        this.phone_number_active = true;
        this.$refs.phone.placeholder = ''
      }
    },
    showPasswordInput: function() {
      this.password_visibility = !this.password_visibility;
      this.$refs.password.type = this.password_visibility ? 'text' : 'password'
    }
  }
}
</script>
