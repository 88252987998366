<template>
  <div class="fx-recipients-select">
    <ul class="recipients_list">
      <li v-for="recipient in innerModel">
        <span class="fax-icon--group fax-icon" v-if="recipient.isGroup()"></span>
        <span class="fax-icon--user fax-icon" v-else></span>

        <FormattedNumber class="user_from" v-if="recipient.isPhone()" :phone="recipient.entity.number" />
        <span v-else class="user_from">{{ recipient.label }}</span>

        <a class="remove_recipient"
           href="#"
           @click.prevent="removeRecipient(recipient.id)"
        >
          <span class="fax-icon--big-red-cross fax-icon"></span>
        </a>
      </li>
    </ul>
    <div class="recipients-dropdown"
       :class="{
        'recipients-dropdown--has-list': innerModel.length,
        'recipients-dropdown--full-list': isFullList,
       }">

      <multiselect
        ref="multiselect"
        v-model="innerModel"
        :options="options"
        :searchable="true"
        :show-labels="false"
        :hide-selected="true"
        :multiple="true"
        :taggable="false"
        :custom-label="searchLabel"
        open-direction="bottom"
        track-by="id"
        placeholder="Add recipients"
      >
        <template slot="selection" slot-scope="{ values, search, isOpen }">
          <span class="multiselect__placeholder" v-if="values.length && !isOpen">
            <span class="fax-icon--blue-plus fax-icon"></span>
            <span>Add recipients</span>
          </span>
          <span v-else-if="values.length || isOpen" class="fax-icon--search fax-icon"></span>
          <span v-else></span>
        </template>
        <template slot="option" slot-scope="props">
          <div class="option__icon fax-icon--group fax-icon" v-if="props.option.isGroup()"></div>
          <div class="option__icon fax-icon--user fax-icon" v-else></div>

          <div class="option__title">
            <FormattedNumber v-if="props.option.isPhone()" :phone="props.option.entity.number" />
            <span v-else>{{ props.option.label }}</span>
          </div>
        </template>
        <template slot="noResult" slot-scope="props">
          <div class="recipients-dropdown__new-option" @click="onNewClick(props.search)">
            <span class="fax-icon" :class="{'fax-icon--green-plus': isValidPhoneNumber(props.search)}"></span>
            <span v-if="isNumber(props.search)">
              <FormattedNumber v-if="isValidPhoneNumber(props.search)" :phone="extractNumber(props.search)" />
              <span v-else>+{{extractNumber(props.search)}}</span>
            </span>
            <span v-else>Not found</span>
          </div>
        </template>
        <template slot="noOptions">
          <span class="fax-icon"></span>
          <span>No contacts</span>
        </template>
        <template slot="placeholder">
          <span class="fax-icon--blue-plus fax-icon"></span>
          <span>Add recipients</span>
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect';
  import FormattedNumber from "../components/FormattedNumber.vue";
  import { isValidNumber } from "libphonenumber-js";

  export default {
    name: "RecipientsSelect",
    components: {
      Multiselect,
      FormattedNumber,
    },
    props: {
      options: Array,
      model: Array,
    },
    computed: {
      innerModel: {
        get() {
          return this.model
        },
        set(model) {
          this.$emit('update:model', model);
        },
      },
      isFullList() {
        return this.model.length > 0
          && this.model.length === this.options.length;
      }
    },
    methods: {
      onNewClick(value) {
        if (!this.isValidPhoneNumber(value)) {
          return;
        }

        this.$emit("onAdd", this.extractNumber(value));
      },
      extractNumber(str) {
        return String(str).replace(/[^0-9]+/, "");
      },
      isNumber(str) {
        return String(str).match(/[0-9]+/gi);
      },
      isValidPhoneNumber(number) {
        return isValidNumber("+" + this.extractNumber(number));
      },
      searchLabel({label, entity}) {
        const phone_number = entity.phone_number || "";

        return `${label} ${phone_number}`;
      },
      removeRecipient(id) {
        this.innerModel = this.$lodash.reject(
          this.innerModel,
          recipient => recipient.id === id,
        );
      },
      close() {
        this.$refs.multiselect.deactivate();
      },
    },
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  @import "../../scss/mainvariables";

  .recipients_list {
    &:empty + .add_recipient.hasList {
      border: 1px dashed $white-button-active;
      border-radius: $border-radius-all-4;
    }

    li {
      border-left: 1px solid $white-button-active;
      border-right: 1px solid $white-button-active;
      border-bottom: 1px solid $white-button-active;
      line-height: 38px;
      position: relative;
      padding: 0 4px 0 8px;

      &:first-child {
        border-top: 1px solid $white-button-active;
        border-radius: 4px 4px 0 0;
      }

      > span {
        vertical-align: middle;
      }

      .user_from {
        font-weight: 600;
      }

      .remove_recipient {
        position: absolute;
        right: 8px;
        top: 0;
      }
    }
  }

  .recipients-dropdown {
    position: relative;

    .multiselect {
      &__tags {
        border: 1px dashed #E5EAF4 !important;
        border-radius: 4px !important;
      }

      &__placeholder {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        color: #6391D6;

        .fax-icon {
          flex: 0 0 auto;
          margin-right: 4px;
        }
      }

      &__input {
        flex: 1 1 auto;
        margin-bottom: 0;
        padding-left: 4px;

        &::placeholder {
          color: #6391D6;
        }
      }

      &__select {
        display: none;
      }

      &__content-wrapper {
        margin-top: 4px;
        min-height: 40px;
      }

      &__option {
        .option__icon {
          margin-right: 4px;
        }
      }

      .fax-icon {
        flex: 0 0 auto;
      }
    }

    &__new-option {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
    }

    &--has-list {
      .multiselect__tags {
        border-radius: 0 0 4px 4px !important;
        border-top: 0 none !important;
      }
    }
  }
</style>
