import {GET_USER, GET_USER_SUCCESS, UPDATE_USER, UPDATE_USER_SUCCESS} from '../actions/user';
import apiCall from '../../utils/api';

const state = {
  user: {},
};

const getters = {
  user: state => state.user,
};

const actions = {
  [GET_USER]: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      apiCall.get('/user')
        .then(function (response) {
          commit(GET_USER_SUCCESS, response);
          resolve(response)
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  [UPDATE_USER]: ({commit, dispatch}, data) => {
    const userUrl = "/user";
    const userData = {
      data: {
        id: "",
        type: "users",
        attributes: data,
      }
    };

    return new Promise((resolve, reject) => {
      apiCall
        .patch(userUrl, userData)
        .then((response) => {
          commit(UPDATE_USER_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          error.response.data.errors.forEach(error => {
            dispatch("showAlertMessage", error.detail);
            reject(error);
          });
        });
    });
  },
};

const mutations = {
  [GET_USER_SUCCESS]: (state, resp) => {
    state.user = resp.data.data.attributes;
  },
  [UPDATE_USER_SUCCESS]: (state, resp) => {
    state.user = resp.data.data.attributes;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
}
