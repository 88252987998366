<template>
  <div class="fax-status" :class="{
      'fax-status--success': isSuccess,
      'fax-status--pending': isPending,
      'fax-status--failed': isFailed,
    }">
    <div :class="iconClass" class="fax-status__icon fax-icon"></div>
    <div class="fax-status__text">{{statusLabel}}</div>
    <div class="fax-status__options-status" v-if="showReceivingOptionsStatus">
      <span class="fax-status__options-status-prefix">to</span>
      <span
          class="fax-icon"
          v-for="(historyItem, index) in historyItems" :key="index"
          :class="receivingOptionIcon(historyItem.receiving_option_type)"
      ></span>
    </div>
  </div>
</template>

<script>
  import {upperFirst} from "lodash/string";

  const statusLabelsMap = {
    inbound: {
      success: "Received",
      failed: "Failed",
    },
    outbound: {
      success: "Sent",
      failed: "Failed",
      delivered: "Delivered",
      partial_success: "Has issues",
    },
  };

  export default {
    name: "FaxStatus",

    props: {
      status: String,
      type: String,
      historyItems: Array,
    },

    computed: {
      statusLabel() {
        if (!statusLabelsMap[this.type] || !statusLabelsMap[this.type][this.status]) {
          return upperFirst(this.status);
        }

        return statusLabelsMap[this.type][this.status];
      },
      isSuccess() {
        return ["delivered", "success", "received"].includes(this.status);
      },
      isPending() {
        return ["processing", "sending", "receiving", "in_progress", "pending"].includes(this.status);
      },
      isFailed() {
        return !this.isSuccess && !this.isPending;
      },
      iconClass() {
        if (this.isSuccess) {
          return "fax-icon--done-green";
        }

        if (this.isPending) {
          return "fax-icon--pending";
        }

        return "fax-icon--red-cross";
      },
      showReceivingOptionsStatus() {
        return this.type === "inbound" && this.isSuccess;
      }
    },

    methods: {
      receivingOptionIcon(type) {
        return type === "google_drive"
          ? "fax-icon--google-drive"
          : `fax-icon--${type}`;
      },
    },
  }
</script>

<style type="scss" scoped>
  .fax-status {
    display: inline-flex;
    align-items: center;

    &__icon {
      flex: 0 0 auto;
      margin-right: 2px;
    }

    &__text {
      flex: 1 1 auto;
      font-weight: 600;
    }

    &__options-status {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
    }

    &__options-status-prefix {
      margin-left: 8px;
      font-weight: 600;
    }

    &--success {
      .fax-status__text {
        color: #4CB955;
      }
    }

    &--pending {
      .fax-status__text {
        color: #F1C04E;
      }
    }

    &--failed {
      .fax-status__text {
        color: #E36262;
      }
    }
  }
</style>
