<template>
  <span>{{formatted}}</span>
</template>

<script>
  import moment from "moment";

  export default {
    name: "DateFormatted",

    props: {
      date: String,
      format: String,
    },

    computed: {
      formatted() {
        const format = this.format || "D MMM YYYY - hh:mm A";
        return moment(this.date).format(format);
      }
    },
  }
</script>
