export default
[
   {
      "name":"Canada",
      "iso":"CA",
      "dial_code":"1",
      "flag_country_code":"canada"
   },
   {
      "name":"United States",
      "iso":"US",
      "dial_code":"1",
      "flag_country_code":"united-states"
   },
   // {
   //    "name":"Argentina",
   //    "iso":"AR",
   //    "dial_code":"54",
   //    "flag_country_code":"argentina"
   // },
   // {
   //    "name":"Australia",
   //    "iso":"AU",
   //    "dial_code":"61",
   //    "flag_country_code":"australia"
   // },
   // {
   //    "name":"Bahrain",
   //    "iso":"BH",
   //    "dial_code":"973",
   //    "flag_country_code":"bahrain"
   // },
   // {
   //    "name":"Costa Rica",
   //    "iso":"CR",
   //    "dial_code":"506",
   //    "flag_country_code":"costa-rica"
   // },
   // {
   //    "name":"Croatia",
   //    "iso":"HR",
   //    "dial_code":"385",
   //    "flag_country_code":"croatia"
   // },
   // {
   //    "name":"Denmark",
   //    "iso":"DK",
   //    "dial_code":"45",
   //    "flag_country_code":"denmark"
   // },
   // {
   //    "name":"El Salvador",
   //    "iso":"SV",
   //    "dial_code":"503",
   //    "flag_country_code":"el-salvador"
   // },
   // {
   //    "name":"Estonia",
   //    "iso":"EE",
   //    "dial_code":"372",
   //    "flag_country_code":"estonia"
   // },
   // {
   //    "name":"Finland",
   //    "iso":"FI",
   //    "dial_code":"358",
   //    "flag_country_code":"finland"
   // },
   // {
   //    "name":"France",
   //    "iso":"FR",
   //    "dial_code":"33",
   //    "flag_country_code":"france"
   // },
   // {
   //    "name":"Germany",
   //    "iso":"DE",
   //    "dial_code":"49",
   //    "flag_country_code":"germany"
   // },
   // {
   //    "name":"Iceland",
   //    "iso":"IS",
   //    "dial_code":"354",
   //    "flag_country_code":"iceland"
   // },
   // {
   //    "name":"Ireland",
   //    "iso":"IE",
   //    "dial_code":"353",
   //    "flag_country_code":"ireland"
   // },
   // {
   //    "name":"Israel",
   //    "iso":"IL",
   //    "dial_code":"972",
   //    "flag_country_code":"israel"
   // },
   // {
   //    "name":"Italy",
   //    "iso":"IT",
   //    "dial_code":"39",
   //    "flag_country_code":"italy"
   // },
   // {
   //    "name":"Kazakhstan",
   //    "iso":"KZ",
   //    "dial_code":"7",
   //    "flag_country_code":"kazakhstan"
   // },
   // {
   //    "name":"Latvia",
   //    "iso":"LV",
   //    "dial_code":"371",
   //    "flag_country_code":"latvia"
   // },
   // {
   //    "name":"Lithuania",
   //    "iso":"LT",
   //    "dial_code":"370",
   //    "flag_country_code":"lithuania"
   // },
   // {
   //    "name":"Luxembourg",
   //    "iso":"LU",
   //    "dial_code":"352",
   //    "flag_country_code":"luxembourg"
   // },
   // {
   //    "name":"Netherlands",
   //    "iso":"NL",
   //    "dial_code":"31",
   //    "flag_country_code":"netherlands"
   // },
   // {
   //    "name":"New Zealand",
   //    "iso":"NZ",
   //    "dial_code":"64",
   //    "flag_country_code":"new-zealand"
   // },
   // {
   //    "name":"Norway",
   //    "iso":"NO",
   //    "dial_code":"47",
   //    "flag_country_code":"norway"
   // },
   // {
   //    "name":"Panama",
   //    "iso":"PA",
   //    "dial_code":"507",
   //    "flag_country_code":"panama"
   // },
   // {
   //    "name":"Poland",
   //    "iso":"PL",
   //    "dial_code":"48",
   //    "flag_country_code":"poland"
   // },
   // {
   //    "name":"Portugal",
   //    "iso":"PT",
   //    "dial_code":"351",
   //    "flag_country_code":"portugal"
   // },
   // {
   //    "name":"Romania",
   //    "iso":"RO",
   //    "dial_code":"40",
   //    "flag_country_code":"romania"
   // },
   // {
   //    "name":"Singapore",
   //    "iso":"SG",
   //    "dial_code":"65",
   //    "flag_country_code":"singapore"
   // },
   // {
   //    "name":"Slovakia",
   //    "iso":"SK",
   //    "dial_code":"421",
   //    "flag_country_code":"slovakia"
   // },
   // {
   //    "name":"South Africa",
   //    "iso":"ZA",
   //    "dial_code":"27",
   //    "flag_country_code":"south-africa"
   // },
   // {
   //    "name":"Spain",
   //    "iso":"ES",
   //    "dial_code":"34",
   //    "flag_country_code":"spain"
   // },
   // {
   //    "name":"Switzerland",
   //    "iso":"CH",
   //    "dial_code":"41",
   //    "flag_country_code":"switzerland"
   // },
   // {
   //    "name":"Turkey",
   //    "iso":"TR",
   //    "dial_code":"90",
   //    "flag_country_code":"turkey"
   // },
   // {
   //    "name":"Ukraine",
   //    "iso":"UA",
   //    "dial_code":"380",
   //    "flag_country_code":"ukraine"
   // },
   // {
   //    "name":"United Kingdom",
   //    "iso":"GB",
   //    "dial_code":"44",
   //    "flag_country_code":"united-kingdom"
   // }
]
