<template>
  <div class="fx-recipient">
    <span class="fx-recipient__icon fax-icon" v-if="name" :class="iconClassName()"></span>
    <span class="fx-recipient__prefix" v-if="prefix">{{prefix}}</span>
    <span class="fx-recipient__name" v-if="name">
      {{ name }}
      <span class="fx-recipient__hint">
       <span>
         <FormattedNumber v-bind:phone="number" :with-flag="true"></FormattedNumber>
       </span>
      </span>
    </span>
    <span class="fx-recipient__number" v-else>
      <FormattedNumber v-bind:phone="number" :with-flag="withFlag"></FormattedNumber>
    </span>
  </div>
</template>

<script>
  import FormattedNumber from "../components/FormattedNumber.vue";

  export default {
    name: "Participant",
    components: {
      FormattedNumber,
    },
    props: {
      number: String,
      name: String,
      type: String,
      prefix: String,
      withFlag: Boolean,
    },
    methods: {
      iconClassName() {
        return `fax-icon--${this.type}`;
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../scss/mainvariables";

  .fx-recipient {
    display: inline-flex;
    align-items: center;
    font-weight: 600;

    &__icon {
      flex: 0 0 auto;
    }

    &__prefix {
      flex: 0 0 auto;
      margin-left: 4px;
      color: #99A8BD;
    }

    &__name,
    &__name + &__number {
      margin-left: 8px;
    }

    &__name {
      position: relative;
      cursor: pointer;

      &:hover {
        .fx-recipient__hint {
          height: auto;
          opacity: 1;
          overflow: visible;
        }
      }
    }

    &__hint {
      height: 0;
      opacity: 0;
      overflow: hidden;
      transition: opacity $transition-time ease-in-out;
      will-change: opacity;
      position: absolute;
      bottom: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);

      > span {
        position: relative;
        display: inline-block;
        white-space: nowrap;
        background-color: $white-color;
        border: 1px solid $white-button-hover;
        padding: 0 10px 0 4px;
        border-radius: $border-radius-all-4;

        @include box-shadow;

        &::before {
          position: absolute;
          bottom: -4px;
          left: 50%;
          margin-left: -3px;
          z-index: -1;
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px;
          border-color: transparent transparent $white-button-hover $white-button-hover;
          transform: rotate(-45deg);

          @include box-shadow-corner;
        }

        &:after {
          position: absolute;
          bottom: -5px;
          left: 50%;
          margin-left: -3px;
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 6px 0 6px;
          border-color: $white-color transparent transparent transparent;
        }

        > span {
          vertical-align: middle;
        }
      }
    }
  }
</style>
