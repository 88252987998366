export const CREATE_INTEGRATION = 'CREATE_INTEGRATION'
export const CREATE_INTEGRATION_SUCCESS = 'CREATE_INTEGRATION_SUCCESS'
export const CREATE_INTEGRATION_ERROR = 'CREATE_INTEGRATION_ERROR'

export const GET_REMOTE_STORAGES = 'GET_REMOTE_STORAGES'
export const GET_REMOTE_STORAGES_SUCCESS = 'GET_REMOTE_STORAGES_SUCCESS'

export const GET_REMOTE_STORAGE = 'GET_REMOTE_STORAGE'
export const GET_REMOTE_STORAGE_SUCCESS = 'GET_REMOTE_STORAGE_SUCCESS'

export const BULK_DELETE_REMOTE_STORAGES = 'BULK_DELETE_REMOTE_STORAGES'
export const BULK_DELETE_REMOTE_STORAGES_SUCCESS = 'BULK_DELETE_REMOTE_STORAGES_SUCCESS'

export const GET_LOCAL_OR_LOAD_REMOTE_STORAGE = 'GET_LOCAL_OR_LOAD_REMOTE_STORAGE'