<template>
  <div class="auth_block">
      <app-auth-top title="Registration"></app-auth-top>
      <div class="auth_block__middle">
        <form @submit.prevent="submitForm">
          <p class="auth_block__middle_decription">
              Please fill your phone number. We will send you a confirmation code by SMS
          </p>
          <div class="form_group form_group_separate">
              <PhoneInput
                v-bind:value="phoneNumber"
                v-on:input="onPhoneChange($event)"
              />
          </div>
          <div class="form_group_button">
              <button type="submit" class="blue-button" v-bind:disabled="!phoneNumber">
                  SEND CODE BY SMS
              </button>
          </div>
        </form>
      </div>
      <div class="auth_block__bottom">
          <ul class="links">
              <li>
                  <router-link to="login">Back to login page</router-link>
              </li>
          </ul>
      </div>
  </div>
</template>

<script>
import AuthBlockTop from './components/welcome/AuthBlockTop.vue';
import PhoneInput from './components/PhoneInput.vue';
import { REGISTER_REQUEST } from '../store/actions/register';

export default {
  components: {
    'app-auth-top': AuthBlockTop,
    PhoneInput,
  },
  data() {
    return {
      countryPhoneModel: '',
      phoneNumber: '',
      dropdownIcon: 'fax-icon--arrow-down fax-icon'
    }
  },
  methods: {
    onPhoneChange(data) {
        this.phoneNumber = data.phone;
        this.countryPhoneModel = data.countryPhoneModel;
    },
    submitForm() {
      const payload = {
        phone_number: this.countryPhoneModel.dial_code + this.phoneNumber,
        phone_country_code: this.countryPhoneModel.iso,
      };

      this.$store.dispatch(REGISTER_REQUEST, payload).then(() => {
        this.$router.push({name: 'verification'});
        this.$store.dispatch('showSuccessMessage', 'We sent you SMS with verification code.');
      });
    }
  },
  computed: {
    dropdownNarrow: {
      get: function() {
        return this.dropdownIcon;
      },
      set: function(value) {
        const iconClass = value === 'open' ? 'fax-icon--arrow' : 'fax-icon--arrow-down';

        this.dropdownIcon = `${iconClass} fax-icon`;
      }
    },
  }
}
</script>
