import { RESTORE_VERIFY_REQUEST, RESTORE_VERIFY_SUCCESS, RESTORE_VERIFY_FAILURE } from '../actions/password_recovery_verification';
import apiCall from '../../utils/api';

const state = {
  restoreConfirmationToken: null
}

const getters = {
  restoreConfirmationToken: state => state.restoreConfirmationToken
}

const actions = {
  [RESTORE_VERIFY_REQUEST]: ({commit, dispatch}, attributes) => {
    return new Promise((resolve, reject) => {
      apiCall.post('/password_recovery_verification', { data: {
                                              type: "password_recovery_verifications",
                                              attributes: attributes
                                            }})
      .then(function (response) {
        commit(RESTORE_VERIFY_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        var message;
        if (error.response.data.errors[0].status === "422") {
          message = error.response.data.errors[0].detail
        } else {
          message = 'Something went wrong'
        }
        commit(RESTORE_VERIFY_FAILURE)
        dispatch('showAlertMessage', message)
        reject(error)
      });
    })
  }
}

const mutations = {
  [RESTORE_VERIFY_SUCCESS]: (state, response) => {
    state.restoreConfirmationToken = response.data.data.attributes.confirmation_token
  },
  [RESTORE_VERIFY_FAILURE]: (state) => {
    state.restoreConfirmationToken = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
