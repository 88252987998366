<template>
    <div>
        <header class="header_add">
            <div class="container clearfix">
                <div class="left_part">
                    <span class="blue-title">
                        <span class="fax-icon--plus fax-icon"></span> <span>New Fax</span>
                    </span>
                </div>
                <div class="right_part">
                    <router-link to="history" class="white-button white-button-invert">
                        <span class="white-button__icon-hover fax-icon--cancel fax-icon"></span>
                        <span class="white-button__icon fax-icon--cancel-white fax-icon"></span>
                        <span>Cancel</span>
                    </router-link>
                    <a class="white-button white-button-invert"
                       href="#"
                       :disabled="disableSend"
                       v-on:click.prevent="sendForm()"
                    >
                        <span class="white-button__icon-hover fax-icon--done fax-icon"></span>
                        <span class="white-button__icon fax-icon--done-white fax-icon"></span>
                        <span>Send</span>
                    </a>
                </div>
            </div>
        </header>
        <section class="main_content">
            <div class="container">
                <div class="main_sides without_panel clearfix">
                    <div class="left_side">
                        <div class="form_group">
                            <label for="">SELECT THE SENDER NUMBER</label>
                            <multiselect
                                v-model="sender_number"
                                :options="senderNumbers"
                                :searchable="false"
                                :show-labels="false"
                                :allow-empty="false"
                                label="phone_number"
                                track-by="id"
                                placeholder="Choose a number"
                            >
                              <template slot="singleLabel" slot-scope="props">
                                <number v-bind:phone="props.option.phone_number" with-flag="true" />
                              </template>
                              <template slot="option" slot-scope="props">
                                <span class="option__title">
                                  <number v-bind:phone="props.option.phone_number" with-flag="true" />
                                </span>
                              </template>
                            </multiselect>
                        </div>

                        <div class="form_group">
                            <label for="fax-recipients">RECIPIENTS</label>
                            <RecipientsSelect
                                id="fax-recipients"
                                ref="recipientsSelect"
                                :model="recipients"
                                :options="contacts"
                                @onAdd="onNewNumber"
                                @update:model="recipients = $event"
                            ></RecipientsSelect>
                        </div>
                    </div>
                    <div class="right_side">
                        <div class="file_container">
                            <label>DOCUMENT TO SEND (PDF)</label>
                            <div :class="dragging === true ? 'select_file dragging' : 'select_file'"
                                v-if="isNoPdf"
                                @dragenter="dragging=true"
                                @dragleave="dragging=false">
                                <file-base64
                                    class="file_select_button"
                                    v-bind:multiple="false"
                                    v-bind:start="onStart"
                                    v-bind:checkFiles="onCheckFiles"
                                    v-bind:done="onDone">
                                </file-base64>
                                <div class="file_selector_middle">
                                    <span class="file_select__icon fax-icon--doc-fax fax-icon"></span>
                                    <p class="drag_n_drop_title">Drag and Drop file here <br/> or</p>
                                    <div class="select_file_with_click">
                                        <file-base64
                                            class="file_select_button_hidden" id="file_select_button_hidden"
                                            v-bind:multiple="false"
                                            v-bind:start="onStart"
                                            v-bind:checkFiles="onCheckFiles"
                                            v-bind:done="onDone"
                                            ref="fileBase64">
                                        </file-base64>
                                        <label for="file_select_button_hidden" type="button" class="blue-button">
                                            <span class="fax-icon--search-white fax-icon"></span>
                                            <span>Choose the file</span>
                                        </label>
                                    </div>
                                </div>
                                <div :class="isLoad === true ? 'pdf_load loaded' : 'pdf_load'"  v-if="isPdf">
                                    <div class="pdf_remove">
                                        <button class="remove_pdf" @click="removePdf">
                                            <span class="fax-icon--trash fax-icon"></span>
                                        </button>
                                    </div>
                                    <div class="pdf_name">
                                        {{ pdfFileName }}
                                    </div>
                                    <pdf class="pdf_container"
                                         ref="myPdfComponent"
                                         :page="page"
                                         @loaded="loaded"
                                         @numPages="numPages = $event"
                                         :src="pdfToBase64"
                                    ></pdf>
                                    <div class="pdf_pagination">
                                        <button @click="prev_page">
                                            <span class="fax-icon--arrow-left fax-icon"></span>
                                        </button>
                                        Page {{ page }} of {{numPages}}
                                        <button @click="next_page">
                                            <span class="fax-icon--arrow-right fax-icon"></span>
                                        </button>
                                    </div>
                                </div>
                                <Spinner v-show="isSpinner"></Spinner>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
  import Multiselect from 'vue-multiselect';
  import {map, sortBy} from "lodash/collection";
  import {union, uniq} from "lodash/array";

  import FormattedNumber from '../components/FormattedNumber.vue';
  import pdf from '../components/pdf.vue';
  import fileBase64 from '../components/file-to-base-64.vue';
  import { GET_CONTACTS } from '../../store/actions/contacts';
  import { CREATE_FAX } from '../../store/actions/faxes';
  import { GET_SENDER_NUMBERS } from '../../store/actions/sender_numbers';
  import Spinner from "../components/Spinner.vue";
  import RecipientsSelect from "./RecipientsSelect.vue";
  import {FileNameHelper} from "./FileNameHelper";
  import {ImageToPdfConverter} from "./ImageToPdfConverter";
  import {GET_CONTACT_GROUPS} from "../../store/actions/contact_groups";

  const RecipientType = {
    group: "group",
    phone: "phone",
    user: "user",
  };

  class Recipient {
    constructor(entity, type) {
      this.type = type;
      this.entity = entity;
    }

    get id() {
      return this.entity.id;
    }

    get label() {
      if (this.isGroup()) {
        return this.entity.name;
      }

      if (this.isPhone()) {
        return this.entity.number;
      }

      return this.entity.full_name;
    }

    isGroup() {
      return this.type === RecipientType.group;
    }

    isUser() {
      return this.type === RecipientType.user;
    }

    isPhone() {
      return this.type === RecipientType.phone;
    }
  }

  export default {
    components: {
      Spinner,
      Multiselect,
      pdf,
      fileBase64,
      'number': FormattedNumber,
      RecipientsSelect,
    },
    props: {
      start: {
        type: Function,
        default: () => {},
      }
    },
	data() {
	  return {
      sender_number: '',
      phoneNumbers: [],
      recipients: [],
      file: null,
      pdfToBase64: '',
      dragging: false,
      isSpinner: false,
      isPdf: false,
      isNoPdf: true,
      isLoad: false,
      disableSend: false,
      pdfFileName: '',
      page: 1,
      numPages: 0,
	  }
	},
  computed: {
    contacts() {
      const {contacts, contactGroups} = this.$store.getters;

      return sortBy(
        union(
          map(contacts, item => {
            return new Recipient(
              {
                ...item.attributes,
                id: item.id,
              },
              RecipientType.user,
            );
          }),
          map(contactGroups, item => {
            return new Recipient(
              {
                ...item.attributes,
                id: item.id,
              },
              RecipientType.group,
            );
          }),
          this.phoneNumbers,
        ),
        "label",
      );
    },
    senderNumbers: function() {
      return this.$lodash.map(this.$store.getters.senderNumbers, (item) => {
        return this.$lodash.assignIn(item.attributes, {id: item.id});
      });
    },
  },
  methods: {
      canSubmit() {
        const hasSenderNumber = Boolean(this.sender_number);
        const hasRecipients = this.getRecipientsIds().length > 0
          || this.getRecipientsPhones().length > 0;
        const hasFile = Boolean(this.file);

        return hasSenderNumber && hasRecipients && hasFile;
      },
      sendForm() {
        if (!this.canSubmit()) {
          return;
        }

        const data = new FormData();

        this.disableSend = true;
        data.append('sender_number_id', this.sender_number.id);
        this.getRecipientsIds().forEach(id => {
          data.append('contact_ids[]', id);
        });
        this.getRecipientsPhones().forEach(phoneNumber => {
          data.append("phone_numbers[]", phoneNumber);
        });
        data.append('file', this.file);

        this.$store.dispatch(CREATE_FAX, data).then(() => {
          this.file = null;
          this.pdfToBase64 = '';
          this.sender_number = '';
          this.recipients = [];
          this.$router.push({name: 'history'});
          this.$store.dispatch('showSuccessMessage', 'Fax created!');
        }).finally(() => {
          this.disableSend = false;
        })
      },
      getRecipientsIds() {
        const ids = [];

        this.recipients.forEach(recipient => {
          if (recipient.isPhone()) {
            return;
          }

          if (recipient.isGroup()) {
            ids.push(...recipient.entity.contact_ids);
            console.log("group", ...recipient.entity.contact_ids);
          }

          console.log("user", ...recipient.entity.id);
          ids.push(recipient.entity.id);
        });

        return uniq(ids);
      },
      getRecipientsPhones() {
        return this.recipients
          .filter(recipient => recipient.isPhone())
          .map(recipient => String(recipient.entity.number).replace(/[^0-9]/g, ""));
      },
      onStart() {
          this.isSpinner = true;
      },
      onCheckFiles(fileList) {
          const filesArray = [...fileList];
          const allowedExtensionsRegExp = /(jpg|jpeg|png|tiff|tif|pdf)$/ig;
          const allFilesValid = filesArray.every(file => {
              const extension = FileNameHelper.getExtension(file.name);

              return allowedExtensionsRegExp.test(extension);
          });

          if (!allFilesValid) {
              this.isSpinner = false;
              alert(`Invalid file type. Please, use PDF/JPG/PNG/TIFF.`);

              return false;
          }

          return true;
      },
      onDone(filesInfo) {
          const [fileInfo] = filesInfo;

          if (FileNameHelper.isTiff(fileInfo.name)) {
              ImageToPdfConverter.fromTiff(fileInfo, (pdfObject) => this.processPdfObject(fileInfo, pdfObject));
              return;
          }

          if (FileNameHelper.isJpgPng(fileInfo.name)) {
              ImageToPdfConverter.fromJpgPng(fileInfo, (pdfObject) => this.processPdfObject(fileInfo, pdfObject));
              return;
          }

          if (!FileNameHelper.isPdf(fileInfo.name)) {
              return;
          }

          this.processPdf(fileInfo.file, fileInfo.base64, fileInfo.name);
      },
      processPdfObject(fileInfo, pdfObject) {
          const file = pdfObject.output("blob");
          const base64 = pdfObject.output("datauristring");
          const name = FileNameHelper.getNameWithoutExtension(fileInfo.name) + ".pdf";

          this.processPdf(file, base64, name);
      },
      processPdf(file, base64, name) {
          this.pdfFileName = name;
          this.pdfToBase64 = base64;
          this.file = file;
          this.isPdf = true;
          this.isSpinner = false;
          this.$refs["fileBase64"].$el.value = '';
      },
      loaded() {
          this.isLoad = true;
      },
      prev_page() {
          this.page === 1 ? this.page = 1 : this.page = this.page - 1
      },
      next_page() {
          this.page === this.numPages ? this.page = this.numPages : this.page = this.page + 1
      },
      removePdf() {
          this.pdfToBase64 = '';
          this.isPdf = false;
      },
      presetSenderNumber() {
        if (!this.senderNumbers || this.sender_number) {
          return;
        }

        this.sender_number = this.senderNumbers[0];
      },
      onNewNumber(phoneNumber) {
        const recipient = new Recipient({id: phoneNumber, number: phoneNumber}, RecipientType.phone);
        this.recipients.push(recipient);
        this.phoneNumbers.push(recipient);
        this.$refs.recipientsSelect.close();
      }
    },
    created() {
      this.$store.dispatch(GET_SENDER_NUMBERS);
      this.$store.dispatch(GET_CONTACTS);
      this.$store.dispatch(GET_CONTACT_GROUPS);
    },
    updated() {
      this.presetSenderNumber();
    },
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  .new-fax {
    .page__content--dashboard {
      > header {
        display: none;
      }

      .multiselect {
        &__tags {
          display: flex;
          align-items: center;

          padding: 0 40px 0 8px;
        }

        &__option {
          color: #415A7F;

          &--highlight {
            background: rgba(229, 234, 244, 0.5);
            color: #415A7F;
          }
        }


        &--active {
          z-index: 1;

          &:not(.multiselect--above) {
            .multiselect__current, .multiselect__input, .multiselect__tags {
              border-radius: 4px;
            }
          }
        }
      }

      .multiselect__content-wrapper {
        /*top: 48px;*/
        border-radius: 4px;
        -webkit-box-shadow: 0 9px 24px 0 rgba(88, 116, 158, 0.35);
        -moz-box-shadow: 0 9px 24px 0 rgba(88, 116, 158, 0.35);
        box-shadow: 0 9px 24px 0 rgba(88, 116, 158, 0.35);
      }
    }
  }
</style>
