import {throttle} from "lodash/function";

export class TableScroll {
  constructor(anchorElement, whenReachCallback) {
    this.anchorElement = anchorElement;
    this.scrollThrottle = throttle(() => {
      whenReachCallback();
    }, 150);
    this.subscribe();
  }

  destroy() {
    this.unsubscribe();
  }

  subscribe() {
    window.addEventListener("scroll", () => this.onWindowScroll());
  }

  unsubscribe() {
    window.removeEventListener("scroll", () => this.onWindowScroll());
  }

  isReachedElement() {
    const screenBottom = window.screenY + window.innerHeight;
    const anchorBottom = this.anchorElement.getBoundingClientRect().bottom;
    const distanceToBottom = anchorBottom - screenBottom;
    const offset = window.innerHeight * 0.3;

    return distanceToBottom > 0 && distanceToBottom <= offset;
  }

  onWindowScroll() {
    if (!this.isReachedElement()) {
      return;
    }

    this.scrollThrottle();
  };
}
