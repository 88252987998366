import {
  SCHEDULE_CHECK_PENDING_PACKET_ORDER,
  GET_PAGES_PACKETS,
  GET_PAGES_PACKETS_SUCCESS,
  RESET_PENDING_PACKET_ORDER,
  SET_PENDING_PACKET_ORDER,
  SUBMIT_PACKET_ORDER,
  SUBMIT_PACKET_ORDER_SUCCESS
} from "../actions/pages_packets";
import apiCall from "../../utils/api";
import {getCatchHandler} from "../../utils/response";
import {PaymentStatus} from "../../constants/payment_status";
import {SHOW_ALERT_MESSAGE, SHOW_SUCCESS_MESSAGE} from "../actions/notifications";
import {GET_USER} from "../actions/user";
import {Duration} from "../../utils/Duration";
import {GET_PAYMENTS} from "../actions/payments";

const state = {
  pagesPackets: [],
  pendingPacketOrderId: null,
};

const getters = {
  pagesPackets: state => state.pagesPackets,
  pendingPacketOrderId: state => state.pendingPacketOrderId,
};

const actions = {
  [GET_PAGES_PACKETS]: ({commit}) => {
    return new Promise((resolve, reject) => {
      apiCall
        .get("/fax_pages")
        .then(function (response) {
          commit(GET_PAGES_PACKETS_SUCCESS, response);
          resolve(response);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  },
  [SUBMIT_PACKET_ORDER]: ({commit, dispatch}, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload.id) {
        reject();
        return;
      }

      const data = {
        type: "fax_page_orders",
        attributes: {
          fax_page_id: payload.id,
        },
      };

      apiCall
        .post("/fax_page_orders", {data})
        .then(response => {
          resolve(response.data.data);
          commit(SUBMIT_PACKET_ORDER_SUCCESS, response);
        })
        .catch(getCatchHandler(dispatch, reject));
    });
  },
  [SET_PENDING_PACKET_ORDER]: ({commit, dispatch, getters}, payload) => {
    const packetPayment = getters.payments.find(payment => payment.id === payload.id);

    if (!packetPayment) {
      return;
    }

    if (packetPayment.attributes.status === PaymentStatus.completed) {
      dispatch(SHOW_SUCCESS_MESSAGE, `Your ${packetPayment.attributes.amount}$ payment completed successfully`);
      dispatch(GET_USER);
      commit(RESET_PENDING_PACKET_ORDER);

      return;
    }

    const isFailedOrCancelledPayment = [PaymentStatus.failed, PaymentStatus.canceled]
      .indexOf(packetPayment.attributes.status) !== -1;

    if (isFailedOrCancelledPayment) {
      dispatch(SHOW_ALERT_MESSAGE, `Unfortunately, your ${packetPayment.attributes.amount}$ payment didn't go through`);
      commit(RESET_PENDING_PACKET_ORDER);
      return;
    }

    commit(SET_PENDING_PACKET_ORDER, payload);
    dispatch(SCHEDULE_CHECK_PENDING_PACKET_ORDER);
  },
  [SCHEDULE_CHECK_PENDING_PACKET_ORDER]: ({dispatch, getters}) => {
    setTimeout(() => {
      dispatch(GET_PAYMENTS).then(() => {
        dispatch(SET_PENDING_PACKET_ORDER, {id: getters.pendingPacketOrderId});
      })
    }, Duration.seconds(10));
  },
  [RESET_PENDING_PACKET_ORDER]: ({commit}) => {
    commit(RESET_PENDING_PACKET_ORDER);
  },
};

const mutations = {
  [GET_PAGES_PACKETS_SUCCESS]: (state, response) => {
    state.pagesPackets = response.data.data;
  },
  [SET_PENDING_PACKET_ORDER]: (state, payload) => {
    state.pendingPacketOrderId = payload.id;
  },
  [RESET_PENDING_PACKET_ORDER]: (state) => {
    state.pendingPacketOrderId = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
}
