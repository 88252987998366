<template>
  <div class="auth_block__top">
    <a href="/" class="logo">
      <div class="logo__icon fax-icon--main-logo fax-icon"></div>
      <span class="logo__label">{{ title }}</span>
    </a>
  </div>
</template>

<script>
  export default {
    props: ['title'],
    data() {
      return {}
    }
  }
</script>

<style lang="scss">
  .auth_block__top {
    display: flex;
    justify-content: center;

    .logo {
      flex: 0 0 auto;
      display: flex;
      align-items: center;

      &__icon {
        width: 35px;
      }

      &__label {
        padding-left: 16px;
      }
    }
  }
</style>
