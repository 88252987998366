<template>
  <div class="auth_block">
    <app-auth-top title="Registration"></app-auth-top>
    <div class="auth_block__middle">
      <p class="auth_block__middle_decription">
        We have sent you an SMS witch confirmation code. Please fill the fild below with thic code to confirm your
        account.
      </p>
      <div class="form_group">
        <div class="one-digit-inputs--wrapper">
          <input autofocus @input="submitForm" v-model="smsCode[0]" type="text" maxLength="1" value=""/>
          <input @input="submitForm" v-model="smsCode[1]" type="text" maxLength="1" value=""/>
          <input @input="submitForm" v-model="smsCode[2]" type="text" maxLength="1" value=""/>
          <input @input="submitForm" v-model="smsCode[3]" type="text" maxLength="1" value=""/>
        </div>
      </div>
      <ul class="links">
        <li v-show="showResendMessage">
          <router-link to="login">Have not received an SMS? Resend</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import AuthBlockTop from './components/welcome/AuthBlockTop.vue';
  import {VERIFY_REQUEST} from '../store/actions/verify';

  export default {
    components: {
      'app-auth-top': AuthBlockTop,
    },
    data() {
      return {
        showResendMessage: false,
        smsCode: ['', '', '', ''],
      };
    },
    created() {
      if (this.userRegistrationPhone === null) {
        this.$router.push({name: 'registration'})
      }

      this.showResendMessageByTimeout();
    },
    methods: {
      showResendMessageByTimeout(value) {
        const msg_time = value || 15000;

        return setTimeout(() => {
          this.showResendMessage = true;
        }, msg_time);
      },
      submitForm(el) {
        const smsCodeToSend = this.smsCode.join('');
        const nextEl = el.target.nextElementSibling;

        if (el.data !== null && nextEl) {
          nextEl.focus();
        }

        if (smsCodeToSend.length === 4) {
          const pin = smsCodeToSend;
          const phone_number = this.userRegistrationPhone;

          this.$store.dispatch(VERIFY_REQUEST, {pin, phone_number}).then(() => {
            this.$router.push({name: 'confirmation'});
          }).catch((e) => {
            this.smsCode = ['', '', '', ''];
          });
        }
      }
    },
    computed: {
      userRegistrationPhone() {
        return this.$store.getters.userRegistrationPhone;
      }
    }
  }
</script>
