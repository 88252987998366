<template>
    <div>
      <section class="main_content">
          <div class="container">
              <div class="top_line clearfix">
                  <div class="left_side">
                      <h1>Configurations</h1>
                  </div>
                  <div class="right_side">
                      <a class="blue-button" href="#" v-show="false" v-on:click.prevent="dropDownOpen">
                          <span class="fax-icon--plus fax-icon"></span>
                          <span>Top Up Ballance</span>
                      </a>
                      <ul class="dropdonw_action_block" v-show="isPayDropDown">
                          <li @click="setModal('credit-card')" v-show="false">
                              <span>
                                  <span class="fax-icon--credit-card fax-icon"></span>
                                  <span class="type_name">Credit Card</span>
                              </span>
                          </li>
                          <li @click="setModal('paypal')">
                              <span>
                                  <span class="fax-icon--paypal fax-icon"></span>
                                  <span class="type_name">PayPal</span>
                              </span>
                          </li>
                          <li @click="setModal('wiretransfer')" v-show="false">
                              <span>
                                  <span class="fax-icon--wire fax-icon"></span>
                                  <span class="type_name">Wire Transfer</span>
                              </span>
                          </li>
                      </ul>
                  </div>
              </div>
              <div class="main_sides clearfix">
                  <configurationLeft></configurationLeft>
                  <div class="right_side" v-show="!isLoading">
                      <div class="table without_check_all" v-if="!isEmptyOptions">
                          <ul class="table_top">
                              <li>
                                  <span>TOP-UPS DATE</span>
                              </li>
                              <li>
                                  <span>TYPE</span>
                              </li>
                              <li>
                                  <span>STATUS</span>
                              </li>
                              <li>
                                  <span>NOTE</span>
                              </li>
                              <li>
                                  <span>AMMOUNT</span>
                              </li>
                          </ul>
                          <ul class="result_item">
                              <li v-for="(payment, index) in payments" :key='index'>
                                  <ul>
                                      <li>
                                        <span class="user_from">
                                            <DateFormatted
                                                format="MM.DD.YYYY hh\:mm A"
                                                :date="payment.created_at"
                                            ></DateFormatted>
                                        </span>
                                      </li>
                                      <li>
                                        <span>{{ payment.sti_name }}</span>
                                      </li>
                                      <li>
                                        <span class="fx-capitalize">{{ payment.status }}</span>
                                      </li>
                                      <li>
                                          <span>{{ payment.note }}</span>
                                      </li>
                                      <li>
                                        <span class="table_price">
                                            $ {{ payment.amount }}
                                        </span>
                                      </li>
                                  </ul>
                              </li>
                          </ul>
                      </div>
                      <div class="emty_data" v-else>
                          <div class="emty_data_icon">
                              <span class="fax-icon--billing fax-icon"></span>
                          </div>
                          <div class="emty_data_title">
                              There is no any billing history
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <Overlay :visible="isLoading" />
      <div class="modal_container" v-if="activeModal != null">
        <div class="modal_block" v-show="activeModal == 'credit-card'">
            <div class="top_line clearfix">
                <div class="left_side">
                    <h1>Credit Card</h1>
                </div>
                <div class="right_side">
                    <a class="white-button" href="#" v-on:click.prevent="closeModal()">
                        <span class="fax-icon--cancel fax-icon"></span>
                        <span>Cancel</span>
                    </a>
                    <a class="blue-button" href="#" @click="addPayment">
                        <span class="fax-icon--arrow-right-white fax-icon"></span>
                        <span>Next</span>
                    </a>
                </div>
            </div>
            <div class="credit_card_block">
                <form action="">
                    <div class="form_group">
                        <label>PAYMENT AMMOUNT</label>
                        <input class="ammount_text" type="text"/>
                    </div>
                    <div class="form_group card_front">
                        <input class="card_number" placeholder="CREDIT CARD NUMBER" type="text"/>
                        <input class="card_date" placeholder="MM / DD" type="text"/>
                    </div>
                    <div class="form_group card_back">
                        <input class="card_cvv" placeholder="CVV" type="text"/>
                    </div>
                </form>
            </div>
        </div>
        <div class="modal_block" v-show="activeModal == 'payment'">
            <div class="top_line clearfix">
                <div class="left_side">
                    <h1>Credit Card</h1>
                </div>
                <div class="right_side">
                    <a class="white-button" href="#" v-on:click.prevent="prevPayment">
                        <span class="fax-icon--arrow-left fax-icon"></span>
                        <span>Back</span>
                    </a>
                    <a class="blue-button" href="#">
                        <span class="fax-icon--arrow-right-white fax-icon"></span>
                        <span>Add Payment</span>
                    </a>
                </div>
            </div>
            <div class="credit_card_info">
                <form action="">
                    <div class="flex_line">
                        <div class="form_group">
                            <label>FIRST NAME</label>
                            <input class="" type="text"/>
                        </div>
                        <div class="form_group">
                            <label>SECOND NAME</label>
                            <input class="" type="text"/>
                        </div>
                    </div>
                    <div class="form_group">
                        <label>FIRST ADDRESS LINE</label>
                        <input class="" type="text"/>
                    </div>
                    <div class="form_group">
                        <label>SECOND ADDRESS LINE</label>
                        <input class="" type="text"/>
                    </div>
                    <div class="flex_line_3">
                        <div class="form_group">
                            <label>COUNTRY</label>
                            <input class="" type="text"/>
                        </div>
                        <div class="form_group">
                            <label>CITY</label>
                            <input class="" type="text"/>
                        </div>
                        <div class="form_group">
                            <label>ZIP CODE</label>
                            <input class="" type="text"/>
                        </div>
                    </div>
                    <div class="flex_line">
                        <div class="form_group">
                            <label>PHONE NUMBER</label>
                            <input class="" type="text"/>
                        </div>
                        <div class="form_group">
                            <label>EMAIL</label>
                            <input class="" type="text"/>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="modal_block" v-show="activeModal == 'paypal'">
            <div class="top_line clearfix">
                <div class="left_side">
                    <h1>Paypal</h1>
                </div>
                <div class="right_side">
                    <a class="white-button" href="#" v-on:click.prevent="closeModal()">
                        <span class="fax-icon--cancel fax-icon"></span>
                        <span>Cancel</span>
                    </a>
                    <a class="blue-button" href="#" v-on:click.prevent="submitForm()">
                        <span class="fax-icon--arrow-right-white fax-icon"></span>
                        <span>Add Payment</span>
                    </a>
                </div>
            </div>
            <div class="credit_card_block">
                <form action="">
                    <div class="form_group no-padding">
                        <label>PAYMENT AMMOUNT</label>
                        <input class="ammount_text no-margin" v-model="payPalAmount" type="text"/>
                    </div>
                </form>
            </div>
        </div>
        <div class="modal_block" v-show="activeModal == 'wiretransfer'">
            <div class="top_line clearfix">
                <div class="left_side">
                    <h1>Wire Transfer</h1>
                </div>
                <div class="right_side">
                    <a class="white-button" href="#" v-on:click.prevent="closeModal()">
                        <span class="fax-icon--cancel fax-icon"></span>
                        <span>Cancel</span>
                    </a>
                </div>
            </div>
            <div class="transfer_block">
                <p class="grey-color-lighter">
                    Please make sure to include the following details in the wire instructions to your bank:
                </p>
                <p>
                    Transaction Description/Reference: AB7A13
                </p>
                <p>
                    Bank Details:
                </p>
                <p>
                    Allied Irish Bank
                </p>
                <p>
                    Dublin Central Business Centre, AIB Westmoreland St,
                </p>
                <p>
                    Dublin 2, Ireland
                </p>
                <p>
                    For Funds in USD:
                </p>
                <p>
                    Account Number - 27976719
                </p>
                <p>
                    IBAN - IE79AIBK93006727976719 SWIFT/BIC AIBKIE2D
                </p>
                <p>
                    Sort Code - 930067
                </p>
                <p class="print">
                    <a class="white-button" href="#">
                        <span class="fax-icon--fax fax-icon"></span>
                        <span>Print</span>
                    </a>
                </p>
            </div>
        </div>
        <ConfigureOptionsModal :payment-passed="paymentPassed" v-if="activeModal === 'configure-options'" />
      </div>
    </div>
</template>

<script>
import { GET_PAYMENTS, CREATE_PAYPAL } from '../store/actions/payments';
import Overlay from './components/Overlay.vue';
import configurationLeft from './components/configurationLeft.vue';
import DateFormatted from "./components/DateFormatted.vue";
import {SET_PENDING_PACKET_ORDER} from "../store/actions/pages_packets";
import {SHOW_ALERT_MESSAGE, SHOW_SUCCESS_MESSAGE} from "../store/actions/notifications";
import {PaymentStatus} from "../constants/payment_status";
import ConfigureOptionsModal from "./ConfigureOptionsModal.vue";
import {GET_RECEIVING_OPTIONS} from "../store/actions/receiving_options";

export default {
components: {
  Overlay,
  DateFormatted,
  configurationLeft,
  ConfigureOptionsModal,
 },
  data() {
    return {
      isLoading: true,
      activeModal: null,
      isPayDropDown: false,
      payPalAmount: null,
      paymentPassed: false,
    };
  },
  computed: {
    payments() {
      return this.$lodash.map(this.$store.getters.payments, (item) => {
        return this.$lodash.assignIn(item.attributes, {id: item.id});
      });
    },
    isEmptyOptions(){
      return this.payments.length === 0;
    },
    pendingPacketOrderId() {
      return this.$store.getters.pendingPacketOrderId;
    }
  },
  methods: {
    dropDownOpen() {
      this.isPayDropDown = !this.isPayDropDown;
    },
    setModal(type) {
      this.activeModal = type;
      this.isPayDropDown = !this.isPayDropDown;
    },
    closeModal() {
      this.activeModal = null;
    },
    prevPayment() {
        this.activeModal = 'credit-card';
    },
    addPayment() {
        this.activeModal = 'payment';
    },
    submitForm() {
      this.isLoading = true;
      if (this.activeModal === 'paypal') {
        this.activeModal = null;
        this.$store.dispatch(CREATE_PAYPAL, this.payPalAmount).then((return_url) => {
          window.location = return_url;
        }).catch(() => {
          this.isLoading = false;
        });
      }
    },
    checkPendingPacketOrder() {
      const {status, payment_id} = this.$route.query;

      const payment = this.payments.find(payment => payment.id === payment_id);

      if (!payment) {
        return;
      }

      const isQueryStatusFailed = status === "failed";
      const isFailedOrCancelledPayment = [PaymentStatus.failed, PaymentStatus.canceled].indexOf(payment.status) !== -1;
      if (isQueryStatusFailed || isFailedOrCancelledPayment) {
        this.$store.dispatch(SHOW_ALERT_MESSAGE, `Unfortunately, your ${payment.amount}$ payment didn't go through`);
        return;
      }

      const isPendingOrProcessing = [PaymentStatus.pending, PaymentStatus.processing].indexOf(payment.status) !== -1;
      if (isPendingOrProcessing) {
        this.paymentPassed = false;
        this.$store.dispatch(SHOW_SUCCESS_MESSAGE, `Your ${payment.amount}$ payment is being processed`);
      }

      if (payment.status === PaymentStatus.completed) {
        this.paymentPassed = true;
      }

      this.showConfigureOptionsModal();
      this.$store.dispatch(SET_PENDING_PACKET_ORDER, {id: payment_id});
    },
    dropStatusQueryParam() {
      this.$router.replace({
        query: {},
      });
    },
    showConfigureOptionsModal() {
      this.$store.dispatch(GET_RECEIVING_OPTIONS).then(({data}) => {
          if (data && data.data.length > 0) {
            return;
          }

          this.activeModal = "configure-options";
      });
    }
  },
  created() {
    this.$store.dispatch(GET_PAYMENTS).finally(() => {
      this.isLoading = false;

      if (!this.$route.query.status) {
        return;
      }

      this.checkPendingPacketOrder();
      this.dropStatusQueryParam();
    });
  },
};
</script>
