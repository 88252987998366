<template>
  <div class="page" :class="pageClass">
    <div class="page__wrapper">
      <component :is="layout" />
    </div>
  </div>
</template>

<script>
import Welcome from './views/Welcome.vue';
import Dashboard from './views/Dashboard.vue';

export default {
  components: {
    'app-dashboard': Dashboard,
    'app-welcome': Welcome
  },
  computed: {
    isWelcomeLayout() {
      const hasName = Boolean(this.$route.name);
      const requiresLogin = Boolean(this.$route.meta.requiresLogin);

      return hasName && !requiresLogin;
    },
    pageClass() {
      return this.isWelcomeLayout
        ? "page--welcome"
        : "page--dashboard";
    },
    layout() {
      return this.isWelcomeLayout
        ? 'app-welcome'
        : 'app-dashboard';
    },
    isAuthenticated: function() {
      return this.$store.getters.isAuthenticated
    }
  }
}
</script>
