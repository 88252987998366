export const GET_CONTACT_GROUPS = 'GET_CONTACT_GROUPS'
export const GET_CONTACT_GROUPS_SUCCESS = 'GET_CONTACT_GROUPS_SUCCESS'

export const GET_CONTACT_GROUP = 'GET_CONTACT_GROUP'
export const GET_CONTACT_GROUP_SUCCESS = 'GET_CONTACT_GROUP_SUCCESS'

export const CREATE_CONTACT_GROUP = 'CREATE_CONTACT_GROUP'
export const CREATE_CONTACT_GROUP_SUCCESS = 'CREATE_CONTACT_GROUP_SUCCESS'
export const CREATE_CONTACT_GROUP_ERROR = 'CREATE_CONTACT_GROUP_ERROR'

export const UPDATE_CONTACT_GROUP = 'UPDATE_CONTACT_GROUP'
export const UPDATE_CONTACT_GROUP_SUCCESS = 'UPDATE_CONTACT_GROUP_SUCCESS'
export const UPDATE_CONTACT_GROUP_ERROR = 'UPDATE_CONTACT_GROUP_ERROR'

export const BULK_DELETE_CONTACT_GROUPS = 'BULK_DELETE_CONTACT_GROUPS'
export const BULK_DELETE_CONTACT_GROUPS_SUCCESS = 'BULK_DELETE_CONTACT_GROUPS_SUCCESS'