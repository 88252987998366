<template>
  <div class="auth_block">
    <app-auth-top title="Restore Password"></app-auth-top>
    <div class="auth_block__middle">
      <div class="counter">
        Step 3/3
      </div>
      <form @submit.prevent="submitForm">
        <p class="auth_block__middle_decription">
          Now you can create your new password. Make sure you are using stron one for the secutiry reasons
        </p>
        <div class="form_group form_group_separate">
          <input type="password" v-model="password" placeholder="Password" />
          <input type="password" v-model="confirm_password" placeholder="Password Confirmation" />
        </div>
        <div class="form_group_button">
            <button type="submit" class="blue-button" v-bind:disabled="!password || !confirm_password">
                SET NEW PASSWORD
            </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AuthBlockTop from './components/welcome/AuthBlockTop.vue';
import { RESTORE_CONFIRM_REQUEST } from '../store/actions/password_recovery_confirmation';

export default {
  components: {
    'app-auth-top': AuthBlockTop
  },
  data(){
    return {
      password: '',
      confirm_password: ''
    }
  },
  created: function () {
    if (this.restoreConfirmationToken === null) {
      this.$router.push({name: 'registration'})
      this.$store.dispatch('showAlertMessage', 'Registration session was expired')
    }
  },
  methods: {
    submitForm: function(el) {
      const  confirmation_token = this.restoreConfirmationToken;
      const phone_number = this.getUserumber;
      const  { password, confirm_password } = this;
      this.$store.dispatch(RESTORE_CONFIRM_REQUEST, { password, confirm_password, phone_number, confirmation_token }).then(() => {
        this.$router.push({name: 'login'})
        this.$store.dispatch('showSuccessMessage', 'Your password successfully changed. Now use it to login to the system');
      })
    }
  },
  computed: {
    restoreConfirmationToken: function() {
      return this.$store.getters.restoreConfirmationToken;
    },
    getUserumber:function() {
      return this.$store.getters.userRestorePhone;
    }
  }
}

</script>
