export const getCatchHandler = (dispatch, reject) => (errorRequestResult) => {
  const {errors} = errorRequestResult.response.data;

  if (!errors.length) {
    reject();

    return;
  }

  errors.forEach(error => {
    dispatch("showAlertMessage", error.detail);
  });

  reject(errorRequestResult);
};
