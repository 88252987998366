import { 
  CREATE_INTEGRATION,
  CREATE_INTEGRATION_SUCCESS,
  CREATE_INTEGRATION_ERROR,
  GET_REMOTE_STORAGES,
  GET_REMOTE_STORAGES_SUCCESS,
  GET_REMOTE_STORAGE,
  GET_REMOTE_STORAGE_SUCCESS,
  BULK_DELETE_REMOTE_STORAGES,
  BULK_DELETE_REMOTE_STORAGES_SUCCESS,
  GET_LOCAL_OR_LOAD_REMOTE_STORAGE
} from '../actions/remote_storages';
import apiCall from '../../utils/api';
import reject from 'lodash/reject';
import find from 'lodash/find';
import includes from 'lodash/includes';

const state = { 
  remote_storages: [],
  last_integration_url: null
}

const getters = {
  remoteStorages: state => state.remote_storages,
  last_integration_url: state => state.last_integration_url
}

const actions = {
  [CREATE_INTEGRATION]: ({commit, dispatch}, integration_type) => {
    return new Promise((resolve, reject) => {
      apiCall.post('/' + integration_type + '_integrations', { data: { 
                                              type: integration_type + '_integrations'
                                            }})
      .then(function (response) {
        commit(CREATE_INTEGRATION_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        var message;
        if (error.response.data.errors[0].status === "422") {
          message = error.response.data.errors[0].detail
        } else {
          message = 'Something went wrong'
        }
        dispatch('showAlertMessage', message)
        commit(CREATE_INTEGRATION_ERROR)
        reject(error);
      });
    })
  },
  [GET_REMOTE_STORAGES]: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      apiCall.get('/remote_storages')
      .then(function (response) {
        commit(GET_REMOTE_STORAGES_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        dispatch('showAlertMessage', 'Can`t load Remote Storages. Please try again later.')
        reject(error);
      });
    })
  },
  [GET_REMOTE_STORAGE]: ({commit, dispatch}, id) => {
    return new Promise((resolve, reject) => {
      apiCall.get('/remote_storages/' + id)
      .then(function (response) {
        commit(GET_REMOTE_STORAGE_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        dispatch('showAlertMessage', 'Can`t load Remote Storage.')
        reject(error);
      });
    })
  },
  [BULK_DELETE_REMOTE_STORAGES]: ({commit, dispatch}, attributes) => {
    return new Promise((resolve, reject) => {
      apiCall.post('/bulk_delete_remote_storages', { data: { 
                                              type: "bulk_delete_remote_storages", 
                                              attributes: attributes 
                                            }})
      .then(function (response) {
        commit(BULK_DELETE_REMOTE_STORAGES_SUCCESS, attributes.remote_storage_ids)
        resolve(response)
      })
      .catch(function (error) {
        var message;
        if (error.response.data.errors[0].status === "422") {
          message = error.response.data.errors[0].detail
        } else {
          message = 'Something went wrong'
        }
        dispatch('showAlertMessage', message)
        reject(error);
      });
    })
  },
  [GET_LOCAL_OR_LOAD_REMOTE_STORAGE]: ({commit, dispatch, getters}, id) => {
    return new Promise((resolve, reject) => {
      var selectedItem;

      selectedItem = find(getters.remoteStorages, function(c) { 
        return c.id === id; 
      });

      if (selectedItem == null) {
        dispatch(GET_REMOTE_STORAGE, id).then((response) => {
          selectedItem = response.data.data;
        });
      }
      resolve(selectedItem);
    })
  }
}

const mutations = {
  [CREATE_INTEGRATION_SUCCESS]: (state, resp) => {
    state.last_integration_url = resp.data.data.attributes.auth_url
  },
  [CREATE_INTEGRATION_ERROR]: (state, resp) => {
    state.last_integration_url = null
  },
  [GET_REMOTE_STORAGES_SUCCESS]: (state, resp) => {
    state.remote_storages = resp.data.data
  },
  [GET_REMOTE_STORAGE_SUCCESS]: (state, resp) => {
    state.remote_storages.push(resp.data.data);
  },
  [BULK_DELETE_REMOTE_STORAGES_SUCCESS]: (state, remote_storage_ids) => {
    var newArray = reject(state.remote_storages, function(c) { 
                    return includes(remote_storage_ids, c.id); 
                  });
    state.remote_storages = newArray;
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}