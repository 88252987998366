import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';
import userPlans from './modules/user_plans';

import notification from './modules/notification';
import auth from './modules/auth';
import register from './modules/register';
import verify from './modules/verify';
import confirm from './modules/confirm';

import contacts from './modules/contacts';
import contactGroups from './modules/contact_groups';

import pagesPackets from './modules/pages_packets';
import remoteStorages from './modules/remote_storages';
import receivingOptions from './modules/receiving_options';

import faxes from './modules/faxes';
import faxHistories from './modules/fax_histories';
import senderNumbers from './modules/sender_numbers';
import remoteCountries from './modules/remote_countries';
import remoteRegions from './modules/remote_regions';
import remoteCities from './modules/remote_cities';
import remoteDidGroups from './modules/remote_did_groups';

import payments from './modules/payments';
import orders from './modules/orders';

import passwordRecovery from './modules/password_recovery';
import passwordRecoveryVerification from './modules/password_recovery_verification';
import passwordRecoveryConfirmation from './modules/password_recovery_confirmation';
import {AUTH_LOGOUT} from "./actions/auth";
import {assign, forOwn} from "lodash/object";

Vue.use(Vuex);

const modules = {
  user,
  userPlans,
  notification,
  auth,
  register,
  passwordRecovery,
  passwordRecoveryVerification,
  passwordRecoveryConfirmation,
  verify,
  confirm,
  contacts,
  contactGroups,
  remoteStorages,
  receivingOptions,
  faxes,
  faxHistories,
  pagesPackets,
  senderNumbers,
  remoteCountries,
  remoteRegions,
  remoteCities,
  remoteDidGroups,
  payments,
  orders,
};

let initialState = {};
forOwn(modules, (value, key) => {
  initialState[key] = {...value.state};
});

export default new Vuex.Store({
  actions: {
    [AUTH_LOGOUT]: ({commit}) => {
      commit(AUTH_LOGOUT);
    },
  },
  mutations: {
    [AUTH_LOGOUT]: (state) => {
      forOwn(initialState, (value, key) => {
        assign(state[key], initialState[key]);
      });
    }
  },
  modules,
});
