export const GET_CONTACTS = 'GET_CONTACTS'
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS'
export const GET_CONTACT = 'GET_CONTACT'
export const GET_CONTACT_SUCCESS = 'GET_CONTACT_SUCCESS'

export const CREATE_CONTACT = 'CREATE_CONTACT'
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS'
export const CREATE_CONTACT_ERROR = 'CREATE_CONTACT_ERROR'

export const UPDATE_CONTACT = 'UPDATE_CONTACT'
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS'

export const BULK_DELETE_CONTACTS = 'BULK_DELETE_CONTACTS'
export const BULK_DELETE_CONTACTS_SUCCESS = 'BULK_DELETE_CONTACTS_SUCCESS'
export const BULK_DELETE_CONTACTS_ERROR = 'BULK_DELETE_CONTACTS_ERROR'

export const BULK_CREATE_CONTACTS = 'BULK_CREATE_CONTACTS'
export const BULK_CREATE_CONTACTS_SUCCESS = 'BULK_CREATE_CONTACTS_SUCCESS'
export const BULK_CREATE_CONTACTS_ERROR = 'BULK_CREATE_CONTACTS_ERROR'