<template>
    <div>
        <section class="main_content">
            <div class="container">
                <div class="top_line clearfix">
                    <div class="left_side">
                        <h1>Fax History</h1>
                        <span class="items_count"><span class="count_number">{{ faxHistoriesCount }}</span> items</span>
                    </div>
                    <div class="right_side">
                        <span class="grey_color_lighter semibold-font">
                            Filter by:
                        </span>
                        <a class="white-button static_action" :class="{ active: typeFilter === 'inbound' }" href="#"
                           @click.prevent="filterByType('inbound')">
                            <span class="fax-icon--inbound fax-icon"></span>
                            <span class="white-button__cross-icon fax-icon--big-red-cross fax-icon"></span>
                            <span>Inbound</span>
                        </a>
                        <a class="white-button static_action" :class="{ active: typeFilter === 'outbound' }" href="#"
                           @click.prevent="filterByType('outbound')">
                            <span class="fax-icon--outbound fax-icon"></span>
                            <span class="white-button__cross-icon fax-icon--big-red-cross fax-icon"></span>
                            <span>Outbound</span>
                        </a>
                        <router-link to="new-fax" class="blue-button">
                            <span class="fax-icon--plus fax-icon"></span>
                            <span>Send New Fax</span>
                        </router-link>
                    </div>
                </div>
                <div
                    class="table"
                    v-if="!isEmptyOptions"
                    :class="{
                      'fx-table--disabled': isDisabled,
                      'fx-table--subtable-active': activeSubtable,
                    }"
                >
                    <ul class="table_top with_sort" v-show="show_panel">
                        <li>
                          <Checkbox v-model="selectAll"></Checkbox>
                        </li>
                        <li>
                            <span>FROM</span>
                            <a class="white-button white-button-pannel white-button-empty-word no-margin-both" href="#"
                               @click.prevent="filter_table('from_name')">
                                <span class="fax-icon--arrow fax-icon"></span>
                            </a>
                        </li>
                        <li>
                            <span>TO</span>
                            <a class="white-button white-button-pannel white-button-empty-word no-margin-both" href="#"
                               @click.prevent="filter_table('to')">
                                <span class="fax-icon--arrow fax-icon"></span>
                            </a>
                        </li>
                        <li>
                            <span>STATUS</span>
                        </li>
                        <li>
                            <span>DATE</span>
                            <a class="white-button white-button-pannel white-button-empty-word no-margin-both" href="#"
                               @click.prevent="filter_table('created_at')">
                                <span class="fax-icon--arrow fax-icon"></span>
                            </a>
                        </li>
                        <li>
                            <span>PAGES</span>
                            <a class="white-button white-button-pannel white-button-empty-word no-margin-both" href="#"
                               @click.prevent="filter_table('pages')">
                                <span class="fax-icon--arrow fax-icon"></span>
                            </a>
                        </li>
                    </ul>
                    <ul class="table_top table_top_action" v-show="show_action">
                        <li>
                          <Checkbox v-model="selectAll"></Checkbox>
                        </li>
                        <li>
                            <span>Choose an action:</span>

                            <!--<a class="white-button white-button-pannel"
                               href="#"
                               v-show="selected.length > 1 ? false : true"
                            >
                                <span class="fax-icon--pass-eye fax-icon"></span>
                                <span>Show</span>
                            </a>-->

                            <a class="white-button white-button-pannel"
                               href="#"
                               v-show="isDetailsActionsShown()"
                               @click.prevent="toggleDetails()"
                            >
                                <span class="fax-icon--menu fax-icon"></span>
                                <span v-show="!activeSubtable">Show details</span>
                                <span v-show="activeSubtable">Hide details</span>
                            </a>

                            <a class="white-button white-button-pannel"
                               href="#"
                               @click.prevent="removeFaxRecord()"
                            >
                                <span class="fax-icon--trash fax-icon"></span>
                                <span>Remove</span>
                            </a>
                        </li>
                    </ul>
                    <div class="table_search clearfix" :class="{ active: searchVal.length > 0 }">
                        <div>
                            <span class="fax-icon--search fax-icon"></span>
                            <a href="#" @click.prevent="clearSearch">
                                <span class="fax-icon--big-red-cross fax-icon"></span>
                            </a>
                        </div>
                        <div>
                            <input type="text" name="" placeholder="Search" v-model="isActiveInput">
                        </div>
                    </div>
                    <ul class="result_item">
                        <li
                            class="fx-table__row"
                            v-for="(fax, index) in faxesFiltered"
                            :key="index"
                            :class="{
                              'fx-table__row--subtable-active': showSubtable(fax.entity),
                            }"
                        >
                            <ul class="fx-table__row-content">
                                <li>
                                    <Checkbox :value="isRowSelected(fax.entity)" @input="onRowSelectionToggle(fax.entity, $event)"></Checkbox>
                                </li>
                                <li>
                                  <div
                                      class="va-middle"
                                      :class="'fax-icon--' + fax.entity.history_type + ' fax-icon'"
                                  ></div>
                                  <Participant
                                      class="va-middle"
                                      type="user"
                                      prefix="Sender:"
                                      :number="fax.entity.fax_history_items[0].from"
                                      :name="fax.entity.fax_history_items[0].from_name"
                                      :with-flag="false"
                                  ></Participant>
                                </li>
                                <li class="fx-table__cell">
                                  <div v-if="isOutbound(fax) && isWithGroupOfRecipients(fax)">
                                    <span class="user_from semibold-font">
                                      <span class="fax-icon--group fax-icon"></span>
                                      <span style="margin-left: 4px;">{{ fax.entity.fax_history_items.length }} Recipients</span>
                                      <span class="user_hint">
                                         <span>
                                           <div
                                               v-for="(fax_history_item, index) in fax.entity.fax_history_items"
                                                :key="index"
                                           >
                                            <Participant
                                                :key="index"
                                                type="user"
                                                :name="fax_history_item.to_name"
                                                :number="fax_history_item.to"
                                                :with-flag="true"
                                            ></Participant>
                                           </div>
                                         </span>
                                      </span>
                                    </span>
                                  </div>
                                  <Participant
                                      v-else
                                      type="user"
                                      :name="fax.entity.fax_history_items[0].to_name"
                                      :number="fax.entity.fax_history_items[0].to"
                                      :with-flag="true"
                                  ></Participant>
                                </li>
                                <li>
                                  <FaxStatus
                                      :status="fax.entity.status"
                                      :type="fax.entity.history_type"
                                      :history-items="fax.entity.fax_history_items"
                                  ></FaxStatus>
                                </li>
                                <li class="fx-table__cell">
                                  <DateFormatted v-bind:date="fax.entity.created_at"></DateFormatted>
                                </li>
                                <li>
                                <span>
                                    {{ fax.entity.pages_count }}
                                </span>
                                </li>
                            </ul>
                            <FaxDetails
                                class="fx-table__subtable"
                                v-if="showSubtable(fax.entity)"
                                :entity="fax.entity"
                            ></FaxDetails>
                        </li>
                    </ul>
                </div>
                <div class="emty_data" v-else>
                      <div class="emty_data_icon">
                          <span class="fax-icon--doc-fax fax-icon"></span>
                      </div>
                      <div class="emty_data_title">
                          There is no any FAX history
                      </div>
                      <div class="emty_data_button">
                        <router-link to="new-fax" class="blue-button">
                            <span class="fax-icon--plus fax-icon"></span>
                            <span>Send New Fax</span>
                        </router-link>
                      </div>
                </div>
                <div ref="loadAnchor"></div>
            </div>
        </section>
        <div class="sender_notify no-display">
            <div class="left_side">
                <p class="sender_count_item">
                    <span class="fax-icon--doc-fax fax-icon"></span>
                    <span>12 faxes</span>
                </p>
                <p class="sender_count_total">from <span class="sender_count_total_numder">30</span> has been sent</p>
            </div>
            <div class="progress">
                <div class="progress_bar_back">
                    <div class="progress_bar_inner" style="width: 45%;"></div>
                </div>
            </div>
            <div class="right_side">
                <a class="white-button" href="#">
                    <span class="white-button__cross-icon fax-icon--big-red-cross fax-icon"></span>
                    <span>Stop Sending</span>
                </a>
            </div>
        </div>
        <Overlay :visible="showOverlay"></Overlay>
    </div>
</template>

<script>
  import Overlay from '../components/Overlay.vue';
  import FormattedNumber from '../components/FormattedNumber.vue';
  import {GET_FAX_HISTORIES, REMOVE_FAX_HISTORY_RECORD} from '../../store/actions/fax_histories';
  import DateFormatted from "../components/DateFormatted.vue";
  import FaxStatus from "./FaxStatus.vue";
  import {TableScroll} from "../../utils/TableScroll";
  import Checkbox from "../components/Checkbox.vue";
  import FaxDetails from "./FaxDetails.vue";
  import Participant from "./Participant.vue";

  export default {
    components: {
      Participant,
      FaxDetails,
      Checkbox,
      Overlay,
      'number': FormattedNumber,
      DateFormatted,
      FaxStatus,
    },
    data() {
      return {
        selectAllState: false,
        typeFilter: "",
        searchVal: "",
        selected: [],
        show_panel: true,
        show_action: false,
        inbound: false,
        outbound: false,
        faxesFiltered: [],
        isLoading: true,
        isDisabled: false,
        showOverlay: false,
        activeSubtable: null,
      };
    },
    computed: {
      selectAll: {
        get: function() {
          this.showPanel();
          return this.selectAllState;
        },
        set: function(value) {
          this.selectAllState = value;
          this.selected = value
            ? this.faxHistories.map(row => row.entity.id)
            : [];

          this.faxHistories.forEach(row => {
            row.state.selected = value;
          });
        }
      },
      isActiveInput: {
        get: function() {
          return this.searchVal;
        },
        set: function(value) {
          this.searchVal = value;
          this.faxesFiltering();
        }
      },
      isEmptyOptions(){
        return this.faxHistories.length === 0;
      },
      faxHistories() {
        return this.$lodash.map(this.$store.getters.faxHistories, (item) => {
          return {
            entity: {
              ...item.attributes,
              id: item.id,
            },
            state: {
              selected: this.selected.indexOf(item.id) !== -1,
              subtable: this.activeSubtable === item.id,
            },
          };
        });
      },
      faxHistoriesCount() {
        return this.$store.getters.faxHistoriesCount;
      }
    },
    methods: {
      filter_table: function(label) {
        function compare(a, b) {
          if (a.entity[label] < b.entity[label]) {
            return -1;
          }
          if (a.entity[label] > b.entity[label]) {
            return 1;
          }
          return 0;
        }

        return (this.faxesFiltered = this.faxHistories.sort(compare));
      },
      isOutbound(fax) {
          return fax.entity.history_type === "outbound";
      },
      isWithGroupOfRecipients(fax) {
          return fax.entity.fax_history_items.length > 1;
      },
      isRowSelected(entity) {
        return this.selected.indexOf(entity.id) !== -1;
      },
      showSubtable(entity) {
        return this.activeSubtable === entity.id;
      },
      onRowSelectionToggle(entity, value) {
        if (value) {
          this.selected.push(entity.id);
          return;
        }

        this.selectAllState = false;
        this.selected = this.selected.filter(id => id !== entity.id);
        this.showPanel();
      },
      isDetailsActionsShown() {
        const [selectedId] = this.selected;
        const selectedRow = this.faxHistories.find(row => row.entity.id === selectedId);

        return this.selected.length === 1
          && selectedRow
          && selectedRow.entity.fax_history_items.length > 1;
      },
      showPanel: function() {
        if (this.selected.length === 0) {
          this.show_panel = true;
          this.show_action = false;
        } else {
          this.show_panel = false;
          this.show_action = true;
        }
      },
      clearSearch: function() {
        this.isActiveInput = "";
      },
      faxesFiltering: function() {
        //Searching filter
        if (this.searchVal.length > 0 || this.typeFilter.length > 0) {
          const search = this.searchVal.toLowerCase();

          return (this.faxesFiltered = this.faxHistories.filter(fax => {
            let validType = true;
            let validSearch = true;

            if (this.typeFilter.length > 0) {
              validType = fax.entity.history_type.indexOf(this.typeFilter) > -1
            }

            if (this.searchVal.length > 0) {
              validSearch = false;
              ['from', 'from_name', 'to', 'to_name'].forEach(function(prop) {
                fax.entity.fax_history_items.forEach(function(fax_item) {
                  if (fax_item[prop] && fax_item[prop].toLowerCase().indexOf(search) > -1) {
                    validSearch = true;
                  }
                });
              });
            }

            return validType && validSearch;
          }));
        } else {
          return this.faxesFiltered = this.faxHistories.slice();
        }
      },
      toggleDetails() {
        const [selectedId] = this.selected;
        const state = !this.activeSubtable;

        if (!selectedId) {
          return;
        }

        this.activeSubtable = state ? selectedId : null;
        this.faxHistories.forEach(row => {
          row.state.subtable = row.entity.id === selectedId && state;
        });
      },
      removeFaxRecord() {
        const [selectedId] = this.selected;

        if (!selectedId) {
          return;
        }

        this.isDisabled = true;

        this.$store.dispatch(REMOVE_FAX_HISTORY_RECORD, {id: selectedId})
          .finally(() => {
            this.isDisabled = false;
            this.faxesFiltering();
            this.selected = [];
          })
      },
      filterByType: function(type) {
        if (type === this.typeFilter) {
          this.typeFilter = '';
        } else {
          this.typeFilter = type;
        }
        this.faxesFiltering();
      },
      loadFaxes(silent = false) {
        this.isLoading = true;
        this.showOverlay = !silent;

        this.$store.dispatch(GET_FAX_HISTORIES).finally(() => {
          this.isLoading = false;
          this.showOverlay = false;
          this.faxesFiltering();
        });
      }
    },
    created() {
      this.loadFaxes();
    },
    mounted() {
      this.tableScroll = new TableScroll(
        this.$refs.loadAnchor,
        () => {
          if (this.isLoading) {
            return;
          }

          this.loadFaxes(true);
        }
      );
    },
    destroyed() {
      if (!this.tableScroll) {
        return;
      }

      this.tableScroll.destroy();
    },
  };
</script>
