import {
    GET_REMOTE_DID_GROUPS,
    GET_REMOTE_DID_GROUPS_SUCCESS,
    RESET_REMOTE_DID_GROUPS,
    RESET_REMOTE_DID_GROUPS_SUCCESS
} from '../actions/remote_did_groups';
import apiCall from '../../utils/api';

const state = {
  remoteDidGroups: []
}

const getters = {
  remoteDidGroups: state => state.remoteDidGroups
}

const actions = {
  [GET_REMOTE_DID_GROUPS]: ({commit, dispatch}, city_id) => {
    return new Promise((resolve, reject) => {
      apiCall.get('/did_groups?filter[city_id]=' + city_id)
      .then(function (response) {
        commit(GET_REMOTE_DID_GROUPS_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        dispatch('showAlertMessage', 'Something went wrong')
        reject(error);
      });
    })
  },
  [RESET_REMOTE_DID_GROUPS]: ({commit}) => {
    commit(RESET_REMOTE_DID_GROUPS_SUCCESS);
  }
}

const mutations = {
  [GET_REMOTE_DID_GROUPS_SUCCESS]: (state, resp) => {
    state.remoteDidGroups = resp.data.data
  },
  [RESET_REMOTE_DID_GROUPS_SUCCESS]: (state) => {
    state.remoteDidGroups = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
