export class FileNameHelper {
  static isPdf(name) {
    const extension = FileNameHelper.getExtension(name);

    return extension.toLowerCase() === "pdf";
  }

  static isTiff(name) {
    const extension = FileNameHelper.getExtension(name);

    return /(tiff|tif)$/ig.test(extension);
  }

  static isJpgPng(name) {
    const extension = FileNameHelper.getExtension(name);

    return /(jpg|jpeg|png)$/ig.test(extension);
  }

  static getNameWithoutExtension(name) {
    return name.substr(0, name.lastIndexOf("."));
  }

  static getExtension(name) {
    return name.substr((name.lastIndexOf(".") + 1));
  }
}
