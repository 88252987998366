import { RESTORE_CONFIRM_REQUEST, RESTORE_CONFIRM_SUCCESS } from '../actions/password_recovery_confirmation';
import apiCall from '../../utils/api';

const actions = {
  [RESTORE_CONFIRM_REQUEST]: ({commit, dispatch}, attributes) => {
    return new Promise((resolve, reject) => {
      apiCall.post('/password_recovery_confirmation', { data: {
                                              type: "password_recovery_confirmations",
                                              attributes: attributes
                                            }})
      .then(function (response) {
        commit(RESTORE_CONFIRM_SUCCESS)
        resolve(response)
      })
      .catch(function (error) {
        var message;
        if (error.response.data.errors[0].status === "422") {
          message = error.response.data.errors[0].detail
        } else {
          message = 'Something went wrong'
        }
        dispatch('showAlertMessage', message)
        reject(error)
      });
    })
  }
}

const mutations = {
  [RESTORE_CONFIRM_SUCCESS]: (state, attributes) => {
    state.confirmationToken = null
    state.registrationPhone = null
  }
}

export default {
  actions,
  mutations
}
