import Tiff from "tiff.js";
import * as JsPDF from "jspdf";
import {FileNameHelper} from "./FileNameHelper";

export class ImageToPdfConverter {
  static fromTiff(fileInfo, cb) {
    if (!FileNameHelper.isTiff(fileInfo.name)) {
      return;
    }

    const fileReader = new FileReader();
    const pdfObject = new JsPDF();

    fileReader.onload = (event) => {
      Tiff.initialize({
        TOTAL_MEMORY: 100000000
      });
      const tiffObject = new Tiff({
        buffer: event.target.result
      });
      const len = tiffObject.countDirectory();

      for (let i = 0; i < len; ++i) {
        tiffObject.setDirectory(i);
        const dataURL = tiffObject.toDataURL();

        if (i > 0) {
          pdfObject.addPage();
        }
        pdfObject.addImage(dataURL, "PNG", 0, 0);
      }

      cb(pdfObject);
    };

    fileReader.readAsArrayBuffer(fileInfo.file);
  }

  static fromJpgPng(fileInfo, cb) {
    if (!FileNameHelper.isJpgPng(fileInfo.name)) {
      return;
    }

    const extension = FileNameHelper.getExtension(fileInfo.name);
    const fileReader = new FileReader();
    const pdfObject = new JsPDF();

    fileReader.onload = () => {
      if (!FileNameHelper.isJpgPng(fileInfo.name)) {
        return;
      }

      pdfObject.addImage(fileReader.result, extension.toUpperCase(), 0, 0);
      cb(pdfObject);
    };

    fileReader.readAsDataURL(fileInfo.file);
  }
}
