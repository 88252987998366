import { 
    GET_CONTACT_GROUPS,
    GET_CONTACT_GROUPS_SUCCESS, 
    GET_CONTACT_GROUP, 
    GET_CONTACT_GROUP_SUCCESS, 
    CREATE_CONTACT_GROUP,
    CREATE_CONTACT_GROUP_SUCCESS,
    CREATE_CONTACT_GROUP_ERROR,
    UPDATE_CONTACT_GROUP,
    UPDATE_CONTACT_GROUP_SUCCESS,
    UPDATE_CONTACT_GROUP_ERROR,
    BULK_DELETE_CONTACT_GROUPS,
    BULK_DELETE_CONTACT_GROUPS_SUCCESS,
} from '../actions/contact_groups';
import apiCall from '../../utils/api';
import map from 'lodash/map';
import reject from 'lodash/reject';
import findIndex from 'lodash/findIndex';
import includes from 'lodash/includes';

const state = { 
  contactGroups: []
}

const getters = {
  contactGroups: state => state.contactGroups
}

const actions = {
  [GET_CONTACT_GROUPS]: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      apiCall.get('/contact_groups')
      .then(function (response) {
        commit(GET_CONTACT_GROUPS_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        dispatch('showAlertMessage', 'Something went wrong')
        reject(error);
      });
    })
  },
  [GET_CONTACT_GROUP]: ({commit, dispatch}, id) => {
    return new Promise((resolve, reject) => {
      apiCall.get('/contact_groups/' + id)
      .then(function (response) {
        commit(GET_CONTACT_GROUP_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        dispatch('showAlertMessage', 'Remote contact group not found!')
        reject(error);
      });
    })
  },
  [CREATE_CONTACT_GROUP]: ({commit, dispatch}, attributes) => {
    return new Promise((resolve, reject) => {
      apiCall.post('/contact_groups', { data: { 
                                              type: "contact_groups", 
                                              attributes: attributes
                                            }})
      .then(function (response) {
        commit(CREATE_CONTACT_GROUP_SUCCESS, response);
        resolve(response)
      })
      .catch(function (error) {
        var message;
        if (error.response.data.errors[0].status === "422") {
          message = error.response.data.errors[0].detail
        } else {
          message = 'Something went wrong'
        }
        dispatch('showAlertMessage', message)
        commit(CREATE_CONTACT_GROUP_ERROR);
        reject(error);
      });
    })
  },
  [UPDATE_CONTACT_GROUP]: ({commit, dispatch}, data) => {
    return new Promise((resolve, reject) => {
      apiCall.patch('/contact_groups/' + data.id, { data: { 
                                              id: data.id,
                                              type: "contact_groups", 
                                              attributes: data.attributes 
                                            }})
      .then(function (response) {
        commit(UPDATE_CONTACT_GROUP_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        var message;
        if (error.response.data.errors[0].status === "422") {
          message = error.response.data.errors[0].detail
        } else {
          message = 'Something went wrong'
        }
        dispatch('showAlertMessage', message)
        reject(error);
      });
    })
  },
  [BULK_DELETE_CONTACT_GROUPS]: ({commit, dispatch}, attributes) => {
    return new Promise((resolve, reject) => {
      apiCall.post('/bulk_delete_contact_groups', { data: { 
                                              type: "bulk_delete_contact_groups", 
                                              attributes: attributes 
                                            }})
      .then(function (response) {
        commit(BULK_DELETE_CONTACT_GROUPS_SUCCESS, attributes.contact_group_ids)
        resolve(response)
      })
      .catch(function (error) {
        var message;
        if (error.response.data.errors[0].status === "422") {
          message = error.response.data.errors[0].detail
        } else {
          message = 'Something went wrong'
        }
        dispatch('showAlertMessage', message)
        reject(error);
      });
    })
  }
}

const mutations = {
  [GET_CONTACT_GROUPS_SUCCESS]: (state, resp) => {
    state.contactGroups = resp.data.data
  },
  [GET_CONTACT_GROUP_SUCCESS]: (state, resp) => {
    state.contactGroups.push(resp.data.data);
  },
  [CREATE_CONTACT_GROUP_SUCCESS]: (state, resp) => {
    state.contactGroups.push(resp.data.data);
  },
  [UPDATE_CONTACT_GROUP_SUCCESS]: (state, resp) => {
    var index = findIndex(state.contactGroups, {id: resp.data.data.id});
    state.contactGroups.splice(index, 1, resp.data.data);
  },
  [BULK_DELETE_CONTACT_GROUPS_SUCCESS]: (state, contact_group_ids) => {
    var newArray = reject(state.contactGroups, function(c) { 
                    return includes(contact_group_ids, c.id); 
                  });
    state.contactGroups = newArray;
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}