import {AUTH_ERROR, AUTH_LOGOUT, AUTH_REQUEST, AUTH_SUCCESS} from '../actions/auth';
import apiCall from '../../utils/api';

const userTokenKey = "user-token";
const state = {
  token: localStorage.getItem(userTokenKey) || '',
  hasErrorMessage: false
};

const getters = {
  isAuthenticated: state => Boolean(state.token),
  hasErrorMessage: state => state.hasErrorMessage,
};

const actions = {
  [AUTH_REQUEST]: ({commit, dispatch}, attributes) => {
    return new Promise((resolve, reject) => {
      apiCall.post("/session", {
          data: {
            type: "sessions",
            attributes: attributes
          }
        })
        .then(function (response) {
          commit(AUTH_SUCCESS, response);
          resolve(response)
        })
        .catch(function (error) {
          let message;

          if (error.response.data.errors[0].status === "422") {
            message = error.response.data.errors[0].detail;
          } else {
            message = "Something went wrong";
          }

          dispatch("showAlertMessage", message);
          commit(AUTH_ERROR);
          reject(error);
        });
    })
  },
};

const mutations = {
  [AUTH_SUCCESS]: (state, response) => {
    const {auth_token} = response.data.data.attributes;

    state.token = auth_token;
    state.hasErrorMessage = false;
    localStorage.setItem(userTokenKey, auth_token);
  },
  [AUTH_ERROR]: (state) => {
    state.hasErrorMessage = true;
    localStorage.removeItem(userTokenKey);
  },
  [AUTH_LOGOUT]: (state) => {
    state.token = '';
    state.hasErrorMessage = false;
    localStorage.removeItem(userTokenKey);
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
}
