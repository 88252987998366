<template>
  <input type="file" id="files" @change="onChange" :multiple="multiple" />
</template>

<script>
  export default {
    props: {
      // Support Multiple File?
      multiple: {
        type: Boolean,
        default: false
      },
      // Check file
      checkFiles: {
        type: Function,
        default: function (file) {
          return true;
        }
      },
      // Pass the files info when it's start
      start: {
        type: Function,
        default: () => {}
      },
      // Pass the files info when it's done
      done: {
        type: Function,
        default: () => {}
      }
    },
    methods: {
      onChange(e) {
        this.start(e.target.value);
        const files = e.target.files;

        if (!this.checkFiles(files)) {
          e.target.value = "";
          return;
        }

        const allFiles = [];
        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          const reader = new FileReader();

          reader.onload = () => {
            // Make a fileInfo Object
            const fileInfo = {
              name: file.name,
              type: file.type,
              size: Math.round(file.size / 1000)+' kB',
              base64: reader.result,
              file: file
            };
            allFiles.push(fileInfo);

            // If all files have been proceed
            if (allFiles.length === files.length){
              this.done(allFiles);
            }
          };
          reader.readAsDataURL(file);
        }
      },
    }
  };
</script>
