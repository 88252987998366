<template>
  <div>
    <section class="main_content">
      <div class="container">
        <div class="top_line clearfix">
          <div class="left_side">
            <h1>Configurations</h1>
          </div>
        </div>
        <div class="main_sides clearfix">
          <configurationLeft></configurationLeft>
          <div class="right_side">
            <AccountPlan></AccountPlan>
            <div class="account_details">
              <div class="account_details_block">
                <h2>Account Info</h2>
                <div class="bottom-16 clearfix">
                  <div class="form_group">
                    <label class="grey-color-lighter">FULL NAME</label>
                    <div class="browse_directory no_hover_pointer">
                      <span class="browse_folder">
                        <input type="text" v-model="userInfo.full_name" />
                      </span>
                    </div>
                  </div>
                </div>
                <div class="bottom-16 clearfix">
                  <div class="form_group">
                    <label class="grey-color-lighter">EMAIL</label>
                    <div class="browse_directory no_hover_pointer">
                      <span class="browse_folder">
                        <input type="text" v-model="userInfo.email" />
                      </span>
                    </div>
                  </div>
                </div>
                <!-- TODO: BRING IT BACK ON LATER STAGE -->
                <!--
                <div class="bottom-16 clearfix">
                  <div class="form_group">
                    <label class="grey-color-lighter">PHONE NUMBER</label>
                    <div class="browse_directory no_hover_pointer">
                      <span class="browse_folder">
                        <input type="text"/>
                      </span>
                    </div>
                  </div>
                </div>
                -->
                <div class="bottom-16 clearfix">
                  <div class="form_group">
                    <label class="grey-color-lighter">PASSWORD</label>
                    <div class="browse_directory no_hover_pointer">
                      <span class="browse_folder">
                        <input type="password" ref="password" autocomplete="new-password" />
                        <span class="fax-icon--pass-eye-hide fax-icon visibling_password"
                              @click="visiblingPassword"
                              v-if="showPassword">
                        </span>
                        <span class="fax-icon--pass-eye fax-icon visibling_password"
                              @click="visiblingPassword"
                              v-else>
                        </span>
                      </span>
                    </div>
                    <div class="form_group__hint">* Keep empty if don't want to change</div>
                  </div>
                </div>
                <div class="bottom-16 clearfix">
                  <div class="form_group">
                    <label class="grey-color-lighter">SMS NOTIFICATIONS</label>
                    <div class="browse_directory no_hover_pointer">
                      <span>
                        <Checkbox :value="userInfo.sms_low_fax_pages" @input="userInfo.sms_low_fax_pages = $event" style="margin-left: -8px;">Low FAX pages (Under 10 pages)</Checkbox>
                        <Checkbox :value="userInfo.sms_failed_to_autorenew_plan" @input="userInfo.sms_failed_to_autorenew_plan = $event" style="margin-left: -8px;">Failed to autorenew plan</Checkbox>
                        <Checkbox :value="userInfo.sms_plan_being_expire" @input="userInfo.sms_plan_being_expire = $event" style="margin-left: -8px;">Plan expiration (before 3 days)</Checkbox>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="account_info_block_account">
                  <a href="#" @click.prevent="saveUserInfo()">Save Changes</a>
                </div>
              </div>
              <BalanceTopUp class="account_details_block"></BalanceTopUp>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import configurationLeft from '../components/configurationLeft.vue';
  import Overlay from "../components/Overlay.vue";
  import {GET_USER, UPDATE_USER} from "../../store/actions/user";
  import AccountPlan from "./AccountPlan.vue";
  import BalanceTopUp from "./BalanceTopUp.vue";
  import Checkbox from "../components/Checkbox.vue";

  export default {
    components: {
      AccountPlan,
      Checkbox,
      BalanceTopUp,
      Overlay,
      configurationLeft
    },
    computed: {
      userInfo() {
        return this.$store.getters.user;
      }
    },
    data() {
      return {
        showPassword: false,
      };
    },
    methods: {
      saveUserInfo() {
        const {full_name, email, sms_low_fax_pages, sms_failed_to_autorenew_plan, sms_plan_being_expire} = this.userInfo;
        const password = Boolean(this.userInfo.password)
          ? this.userInfo.password
          : undefined;

        this.$store.dispatch(UPDATE_USER, {
          full_name,
          email,
          password,
          sms_low_fax_pages,
          sms_failed_to_autorenew_plan,
          sms_plan_being_expire
        });
      },
      visiblingPassword() {
        if (this.$refs.password.type !== 'text') {
          this.$refs.password.type = 'text';
          this.showPassword = true;
        } else {
          this.$refs.password.type = 'password';
          this.showPassword = false;
        }
      }
    },
    created() {
      this.$store.dispatch(GET_USER);
    },
  };
</script>
