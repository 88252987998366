<template>
    <div>
      <section class="main_content">
          <div class="container">
              <div class="top_line clearfix">
                  <div class="left_side">
                      <h1>Phone Book</h1>
                  </div>
                  <div class="right_side">
                      <a class="blue-button" href="#" v-on:click.prevent="addGroupModal = !addGroupModal">
                          <span class="fax-icon--plus fax-icon"></span>
                          <span>Add New Group</span>
                      </a>
                  </div>
              </div>
              <div class="main_sides clearfix">
                  <div class="left_side">
                      <router-link to="phonebook" class="white-button">
                          <span class="fax-icon--user fax-icon"></span>
                          <span>Contacts</span>
                      </router-link>
                      <router-link to="groups" class="white-button active_link">
                          <span class="fax-icon--group fax-icon"></span>
                          <span>Groups</span>
                      </router-link>
                  </div>
                  <div class="right_side" v-show="!isLoading">
                      <div class="table" v-if="!isEmptyOtions">
                          <ul class="table_top with_sort" v-show="show_panel">
                              <li>
                                  <label>
                                      <input type="checkbox" value="0" id="table_check_all" v-model="selectAll">
                                      <div class="checkbox_custom_not_check">
                                          <span class="fax-icon--checkbox fax-icon"></span>
                                      </div>
                                      <div class="checkbox_custom_check">
                                          <span class="fax-icon--checkbox-selected fax-icon"></span>
                                      </div>
                                  </label>
                              </li>
                              <li>
                                  <span>GROUP NAME</span>
                              </li>
                              <li>
                                  <span>QTT PARTICIPANTS</span>
                              </li>
                              <li>
                                  <span>NOTE</span>
                              </li>
                          </ul>
                          <ul class="table_top table_top_action" v-show="show_action">
                              <li>
                                  <label>
                                      <input type="checkbox" value="0" id="table_check_all" v-model="selectAll">
                                      <div class="checkbox_custom_not_check">
                                          <span class="fax-icon--checkbox fax-icon"></span>
                                      </div>
                                      <div class="checkbox_custom_check">
                                          <span class="fax-icon--checkbox-selected fax-icon"></span>
                                      </div>
                                  </label>
                              </li>
                              <li>
                                <span>Choose an action:</span>
                                <a class="white-button white-button-pannel" href="#" v-if="checkedContactGroups.length === 1" @click.prevent="showAssignToGroup">
                                    <span class="fax-icon--blue-plus fax-icon"></span>
                                    <span>Add Participants</span>
                                </a>
                                <a class="white-button white-button-pannel" href="#" v-if="checkedContactGroups.length === 1" @click.prevent="showEditGroup">
                                    <span class="fax-icon--pencil fax-icon"></span>
                                    <span>Edit</span>
                                </a>
                                <a class="white-button white-button-pannel"
                                  href="" 
                                  @click.prevent="bulkDeleteContactGroups()">
                                    <span class="fax-icon--trash fax-icon"></span>
                                    <span>Delete</span>
                                </a>
                              </li>
                          </ul>
                          <div class="table_search clearfix"  :class="{ active: searchVal.length > 0 ? true : false }">
                              <div>
                                  <span class="fax-icon--search fax-icon"></span>
                                  <a href="#"  @click.prevent="clearSearch">
                                      <span class="fax-icon--big-red-cross fax-icon"></span>
                                  </a>
                              </div>
                              <div>
                                  <input type="text" name="" id="" placeholder="Search" v-model="isActiveInput">
                              </div>
                          </div>
                          <ul class="result_item">
                              <li v-for="(contactGroup, index) in filteredContactGroups" :key='index'>
                                  <ul>
                                      <li>
                                          <label>
                                              <input type="checkbox" :id="contactGroup.id" :value="contactGroup.id" v-model="checkedContactGroups" @change="showPanel">
                                              <div class="checkbox_custom_not_check">
                                                  <span class="fax-icon--checkbox fax-icon"></span>
                                              </div>
                                              <div class="checkbox_custom_check">
                                                  <span class="fax-icon--checkbox-selected fax-icon"></span>
                                              </div>
                                          </label>
                                      </li>
                                      <li>
                                          <span class="fax-icon--group fax-icon"></span>
                                          <span class="user_from">{{ contactGroup.name }}</span>
                                      </li>
                                      <li>
                                          <span>
                                              {{ contactGroup.contacts_count }}
                                          </span>
                                      </li>
                                      <li>
                                          <span>
                                              {{ contactGroup.note }}
                                          </span>
                                      </li>
                                  </ul>
                              </li>
                          </ul>
                      </div>
                      <div class="emty_data" v-else>
                            <div class="emty_data_icon">
                                <span class="fax-icon--group fax-icon"></span>
                            </div>
                            <div class="emty_data_title">
                                There is no any contact groups
                            </div>
                            <div class="emty_data_button">
                                <a class="blue-button" href="#" v-on:click.prevent="addGroupModal = !addGroupModal">
                                    <span class="fax-icon--plus fax-icon"></span>
                                    <span>Add New Contact Group</span>
                                </a>
                            </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    <overlay :visible="isLoading"></overlay>
    <div class="fx-group-modal modal_container" v-if="assignModal">
        <div class="modal_block modal_block__csv">
            <form class="fx-group-modal__form" @submit.prevent="assignToGroup">
                <div class="fx-group-modal__top_line top_line clearfix">
                    <div class="left_side">
                        <h1>Add to Group</h1>
                    </div>
                    <div class="right_side">
                        <a class="white-button" href="#" v-on:click.prevent="closeModal">
                            <span class="fax-icon--cancel fax-icon"></span>
                            <span>Cancel</span>
                        </a>
                        <button class="blue-button" type="submit">
                            <span class="fax-icon--plus fax-icon"></span>
                            <span>Add</span>
                        </button>
                    </div>
                </div>
                <div class="fx-group-modal__table table">
                    <ul class="result_item csv_table">
                        <li v-for="(contact, index) in contactGroupContacts" :key="index"
                            v-show="!isContactBelongsToGroup(contact.id)">
                            <ul>
                                <li>
                                    <label>
                                        <input type="checkbox" value="0" v-model="contactGroupForm.contact_ids"
                                               :value="contact.id">
                                        <div class="checkbox_custom_not_check">
                                            <span class="fax-icon--checkbox fax-icon"></span>
                                        </div>
                                        <div class="checkbox_custom_check">
                                            <span class="fax-icon--checkbox-selected fax-icon"></span>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <span class="fax-icon--user fax-icon"></span>
                                    <span>
                                  {{ contact.full_name }}
                              </span>
                                </li>
                                <li>
                                    <icon-flag v-bind:country_code="contact.phone_country_code"/>
                                    <span>{{ contact.phone_number }}</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </form>
        </div>
    </div>
    <div class="fx-group-modal modal_container" v-if="editGroupModal">
      <div class="modal_block modal_block__csv">
          <form class="fx-group-modal__form" @submit.prevent="updateContactGroup">
            <div class="fx-group-modal__top_line top_line clearfix">
                <div class="left_side">
                    <h1>Edit Group</h1>
                </div>
                <div class="right_side">
                    <a class="white-button" href="#" v-on:click.prevent="closeModal">
                        <span class="fax-icon--cancel fax-icon"></span>
                        <span>Cancel</span>
                    </a>
                    <button class="blue-button" type="submit">
                        <span class="fax-icon--plus fax-icon"></span>
                        <span>Update</span>
                    </button>
                </div>
            </div>
              <div class="fx-group-modal__table table">
                  <ul class="result_item csv_table edit_table">
                      <li class="result_item__text_input">
                          <label>GROUP NAME</label>
                          <input type="text" v-model="contactGroupForm.name" placeholder="Enter group name" />
                      </li>
                      <li v-for="(contact, index) in contactGroupContacts" :key="index">
                          <ul>
                              <li style="width: 0;">
                                
                              </li>
                              <li>
                                <span class="fax-icon--user fax-icon"></span>
                                <span>{{ contact.full_name }}</span>
                              </li>
                              <li>
                                  <icon-flag v-bind:country_code="contact.phone_country_code"/>
                                  <number v-bind:phone="contact.phone_number"/>
                              </li>
                              <li>
                                  <a href="#" v-on:click.prevent="removeContactFromEditGroup(contact.id)">
                                      <span class="fax-icon--big-red-cross fax-icon"></span>
                                  </a>
                              </li>
                          </ul>
                      </li>
                      <div v-if="contactGroupContacts.length === 0" class="contact-groups__no-contacts">No contacts found.</div>
                  </ul>
              </div>
          </form>
      </div>
    </div>
    <div class="fx-group-modal modal_container" v-if="addGroupModal">
        <div class="modal_block">
            <form class="fx-group-modal__form" @submit.prevent="createContactGroup">
                <div class="fx-group-modal__top_line top_line clearfix">
                    <div class="left_side">
                        <h1>Add Group</h1>
                    </div>
                    <div class="right_side">
                        <a class="white-button" href="#" v-on:click.prevent="closeModal">
                            <span class="fax-icon--cancel fax-icon"></span>
                            <span>Cancel</span>
                        </a>
                        <button class="blue-button" type="submit">
                            <span class="fax-icon--plus fax-icon"></span>
                            <span>Create</span>
                        </button>
                    </div>
                </div>
                <div class="table">
                    <ul class="result_item csv_table">
                        <li class="result_item__text_input">
                            <label>GROUP NAME</label>
                            <input type="text" v-model="contactGroupForm.name" placeholder="Enter group name"/>
                        </li>
                    </ul>
                </div>
                <div class="fx-group-modal__table table">
                    <ul class="fx-group-modal__list result_item csv_table">
                        <li v-for="(contact, index) in contactGroupContacts" :key="index">
                            <ul>
                                <li>
                                    <label>
                                        <input type="checkbox" v-model="contactGroupForm.contact_ids"
                                               :value="contact.id">
                                        <div class="checkbox_custom_not_check">
                                            <span class="fax-icon--checkbox fax-icon"></span>
                                        </div>
                                        <div class="checkbox_custom_check">
                                            <span class="fax-icon--checkbox-selected fax-icon"></span>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <span class="fax-icon--user fax-icon"></span>
                                    <span>
                                  {{ contact.full_name }}
                              </span>
                                </li>
                                <li>
                                    <icon-flag v-bind:country_code="contact.phone_country_code"/>
                                    <number v-bind:phone="contact.phone_number"/>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </form>
        </div>
    </div>
  </div>
</template>

<script>
    import IconFlag from '../components/IconFlag.vue';
    import Overlay from '../components/Overlay.vue';
    import FormattedNumber from '../components/FormattedNumber.vue';
    import {
        BULK_DELETE_CONTACT_GROUPS,
        CREATE_CONTACT_GROUP,
        GET_CONTACT_GROUP,
        GET_CONTACT_GROUPS,
        UPDATE_CONTACT_GROUP
    } from '../../store/actions/contact_groups';
    import {GET_CONTACTS} from '../../store/actions/contacts';
    import includes from 'lodash/includes';

    const initialData = {
  contactGroupForm: {
    id: null,
    name: '',
    contact_ids: [],
    loadAll: true
  },
};

export default {
  components: {
    'icon-flag': IconFlag,
    'number': FormattedNumber,
    'overlay': Overlay,
  },
  data() {
    return {
      show_panel: true,
      show_action: false,
      checkedContactGroups: [],
      searchVal: "",
      showModal: false,
      isLoading: true,
      contactGroupForm: initialData.contactGroupForm,
      assignModal: false,
      editGroupModal: false,
      addGroupModal: false
    };
  },
  computed: {
    selectedAllContacts: {
        get: function () {
            return this.contactGroupContacts
                ? this.contactGroupForm.contact_ids.length === this.contactGroupContacts.length
                : false;
        },
        set: function (value) {
            var selected = [];

            if (value) {
                this.contactGroupContacts.forEach(function (user, index) {
                    selected.push(index);
                });
            }

            this.contactGroupForm.contact_ids = selected;
        }
    },
    selectAll: {
      get() {
        this.showPanel();
        return this.filteredContactGroups.length > 0
          ? this.checkedContactGroups.length === this.filteredContactGroups.length
          : false;
      },
      set(value) {
        const checkedContactGroups = [];

        if (value) {
          this.filteredContactGroups.forEach(item => {
            checkedContactGroups.push(item.id);
          });
        }

        this.checkedContactGroups = checkedContactGroups;
      }
    },
    isActiveInput: {
      get: function() {
        return this.searchVal;
      },
      set: function(value) {
        this.searchVal = value;
      }
    },
    contactGroups: function() {
      return this.$lodash.map(this.$store.getters.contactGroups, (item) => {
        return this.$lodash.assignIn(item.attributes, {id: item.id});
      });
    },
    filteredContactGroups() {
      if (!this.searchVal) {
        return this.contactGroups;
      }

      return this.contactGroups.filter(group => {
        return [group.name, group.note].some(fieldValue => {
          if (!fieldValue) {
            return false;
          }

          return fieldValue.toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1;
        });
      });
    },
    isEmptyOtions(){
      return this.contactGroups.length === 0;
    },
    contactGroupContacts: function() {
      var newArray = this.$store.getters.contacts,
          contact_ids = this.contactGroupForm.contact_ids;
      
      if (this.contactGroupForm.loadAll === false) {
        newArray = this.$lodash.filter(newArray, function(c) { 
                        return includes(contact_ids, c.id); 
                      });
      }
      
      return this.$lodash.map(newArray, (item) => {
        return this.$lodash.assignIn(item.attributes, {id: item.id});
      });
    }
  },
  methods: {
    closeModal() {
      this.assignModal = false;
      this.editGroupModal = false;
      this.addGroupModal = false;
      this.contactGroupForm = {
          ...initialData.contactGroupForm,
      };
    },
    showPanel: function() {
      var checked = this.checkedContactGroups;
      if (checked.length === 0) {
        this.show_panel = true;
        this.show_action = false;
      } else {
        this.show_panel = false;
        this.show_action = true;
      }
    },
    clearSearch: function() {
      this.isActiveInput = "";
    },
    searchingFilter: function(value) {
      //Seaching filter
      if (value.length > 0) {
        var search = value.toLowerCase();

        return (this.contactGroups.filter(contact => {
          for (var prop in contact) {
            return contact[prop].toLowerCase().indexOf(search.toLowerCase()) > -1;
          }
        }));
      } else {
        return this.filteredContactGroups;
      }
    },
    createContactGroup: function() {
      const { name, contact_ids } = this.contactGroupForm
      this.$store.dispatch(CREATE_CONTACT_GROUP, { name, contact_ids }).then(() => {
        this.closeModal();
        this.$store.dispatch('showSuccessMessage', 'Contact Group Created!');
      })
    },
    updateContactGroup: function() {
      const { id, name, contact_ids } = this.contactGroupForm
      this.$store.dispatch(UPDATE_CONTACT_GROUP, { id: id, attributes: { name, contact_ids } }).then(() => {
        this.closeModal();
        this.checkedContactGroups = [];
        this.$store.dispatch('showSuccessMessage', 'Contact Group Updated!');
      })
    },
    assignToGroup: function() {
      const { id, contact_ids } = this.contactGroupForm
      this.$store.dispatch(UPDATE_CONTACT_GROUP, { id: id, attributes: { contact_ids } }).then(() => {
        this.closeModal();
        this.checkedContactGroups = [];
        this.$store.dispatch('showSuccessMessage', 'Contacts Succesfully Assigned!');
      })
    },
    bulkDeleteContactGroups: function() {
      this.$store.dispatch(BULK_DELETE_CONTACT_GROUPS, { contact_group_ids: this.checkedContactGroups }).then(() => {
        this.checkedContactGroups = [];
        this.$store.dispatch('showSuccessMessage', 'Successfully deleted!');
      })
    },
    showNewGroup: function() {
        this.isLoading = true;
        var item = initialData.contactGroupForm;
        this.contactGroupForm = item;
        this.editGroupModal = true;
        this.isLoading = false;
        
        return true;
    },
    showEditGroup: function() {
        this.isLoading = true;
        
        var item;
        
        const selectedId = this.checkedContactGroups[0];
        
        item = this.$lodash.find(this.contactGroups, function(c) { return c.id === selectedId; });
        if (item === undefined) {
          this.$store.dispatch(GET_CONTACT_GROUP, selectedId).then(() => {
            item = this.$lodash.find(this.contactGroups, function(c) { return c.id === selectedId; });
          })
        }
        
        item = Object.assign({}, item);
        this.contactGroupForm = item;
        this.contactGroupForm.loadAll = false;
        
        this.editGroupModal = true;
        this.isLoading = false;
        
        return true;
    },
    showAssignToGroup: function() {
        this.isLoading = true;
        
        var item;
        
        const selectedId = this.checkedContactGroups[0];
        
        item = this.$lodash.find(this.contactGroups, function(c) { return c.id === selectedId; });
        if (item === undefined) {
          this.$store.dispatch(GET_CONTACT_GROUP, selectedId).then(() => {
            item = this.$lodash.find(this.contactGroups, function(c) { return c.id === selectedId; });
          })
        }
        
        item = Object.assign({}, item);
        this.contactGroupForm = item;
        
        this.assignModal = true;
        this.isLoading = false;
        
        return true;
    },
    removeContactFromEditGroup: function(id) {
      var newArray = this.$lodash.remove(this.contactGroupForm.contact_ids, function(n) {
        return n != id;
      });
      this.contactGroupForm.contact_ids = newArray;
    },
    isContactBelongsToGroup: function(contactId) {
      var item;
      const selectedId = this.checkedContactGroups[0];
      item = this.$lodash.find(this.contactGroups, function(c) { return c.id === selectedId; });
      if (!item) {
        return false;
      }
      return this.$lodash.findIndex(item.contact_ids, function(c) { return c === contactId; }) >= 0;
    }
  },
  created() {
    this.$store.dispatch(GET_CONTACT_GROUPS).then(() => {
      if (this.$store.getters.contacts.length === 0) {
        this.$store.dispatch(GET_CONTACTS);
      }
      this.isLoading = false;
    }).catch(() => {
      this.isLoading = false;
    });
  }
};
</script>

<style lang="scss">
    .fx-group-modal {
        .modal_block {
            height: 90%;
            max-height: 464px;
        }

        &__form {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        &__top_line {
            flex: 0 0 auto;
        }

        &__table {
            flex: 1 1 auto;
            overflow-y: auto;
        }
    }

    .contact-groups__no-contacts {
      color: #99A8BD;
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
      text-align: center;
      margin-top: 25px;
    }
</style>
