<template>
  <span class="formatted-number">
    <icon-flag class="formatted-number__icon" v-if="withFlag" v-bind:country_code="countryFlag"/>
    <span class="formatted-number__text" v-text="formattedNumber"></span>
  </span>
</template>

<script>
import { formatNumber, parseNumber } from 'libphonenumber-js'
import IconFlag from './IconFlag.vue';

export default {
    name: "FormattedNumber",
    props: ['phone', 'withFlag'],
    components: {
      'icon-flag': IconFlag
    },
    data: function () {
    	return {}
  	},
    computed: {
      countryFlag() {
        return parseNumber('+' + this.phone, {extended: true}).country
      },
      formattedNumber() {
        return formatNumber('+' + this.phone, 'International');
      }
    }
}
</script>

<style lang="scss" scoped>
  .formatted-number {
    display: inline-flex;
    align-items: center;

    &__icon {
      flex: 0 0 auto;
      height: 32px;
      width: 32px;

      display: flex;
      align-items: center;
      justify-content: center;

      margin-right: 4px;
    }

    &__text {
      flex: 1 1 auto;
    }
  }
</style>
