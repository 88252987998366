import {upperFirst} from "lodash/string";

const vendorPrefixes = ["ms", "Moz", "Webkit", "O"];

export function setStyleProp(element, name, value) {
  if (!element) {
    return;
  }

  if (typeof element.style[name] === "string") {
    element.style[name] = value;

    return;
  }

  const uName = upperFirst(name);
  for (let i = 0; i < vendorPrefixes.length; i++) {
    const prefixedName = vendorPrefixes[i] + uName;

    if (typeof element.style[name] === "string") {
      element.style[prefixedName] = value;

      return;
    }
  }
}
