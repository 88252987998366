<template>
    <div>
      <section class="main_content">
          <div class="container">
              <div class="top_line clearfix">
                  <div class="left_side">
                      <h1>Configurations</h1>
                  </div>
                  <div class="right_side" v-if="!isEmptyOtions">
                      <!--<button class="blue-button" href="#" v-on:click.prevent="showModalNew">
                          <span class="fax-icon--plus fax-icon"></span>
                          <span>Add New Number</span>
                      </button>-->
                  </div>
              </div>
              <div class="main_sides clearfix">
                  <configurationLeft></configurationLeft>
                  <div class="right_side" v-show="!isLoading">
                      <div class="table"  v-if="!isEmptyOtions">
                          <ul class="table_top with_sort" v-show="show_panel">
                              <li>

                              </li>
                              <li>
                                  <span>FAX NUMBER</span>
                              </li>
                              <li>
                                  <span>STATUS</span>
                              </li>
                              <li>
                                  <span>NOTE</span>
                              </li>
                              <li>
                                  <span>BILLED FROM</span>
                              </li>
                              <li>
                                  <span>BILLED TO</span>
                              </li>
                          </ul>
                          <!--<ul class="table_top table_top_action" v-show="show_action">
                              <li>
                                  <span style="border-left: none;">Choose an action:</span>
                                  <a class="white-button white-button-pannel"
                                     href="#"
                                     @click.prevent="makeDefault()"
                                  >
                                      <span class="fax-icon&#45;&#45;default fax-icon"></span>
                                      <span>Make Default</span>
                                  </a>

                                  &lt;!&ndash;<a class="white-button white-button-pannel"
                                     href="#"
                                     @click.prevent="openEditModal()"
                                  >
                                      <span class="fax-icon&#45;&#45;pencil fax-icon"></span>
                                      <span>Edit</span>
                                  </a>&ndash;&gt;

                                  &lt;!&ndash;<a class="white-button white-button-pannel"
                                     href="#"
                                     @click.prevent="cancelService()"
                                     v-show="this.possibleToCancelService">
                                      <span class="fax-icon&#45;&#45;trash fax-icon"></span>
                                      <span>Cancel service</span>
                                  </a>&ndash;&gt;
                              </li>
                          </ul>-->
                          <ul class="result_item">
                              <li v-for="(sender_number, index) in senderNumbers" :key='index'>
                                  <ul>
                                      <li>
                                          <!--<label>
                                            <input type="checkbox"
                                                   :id="sender_number.id"
                                                   :value="sender_number.id"
                                                   v-model="checkedSenderNumbers"
                                                   :disabled="(checkedSenderNumbers.length > 0 ? true : false) && checkedSenderNumbers[0] !== sender_number.id"
                                                   @change="showPanel">
                                            <div class="checkbox_custom_not_check">
                                              <span class="fax-icon&#45;&#45;checkbox fax-icon"></span>
                                            </div>
                                            <div class="checkbox_custom_check">
                                              <span class="fax-icon&#45;&#45;checkbox-selected fax-icon"></span>
                                            </div>
                                          </label>-->
                                      </li>
                                      <li>
                                        <!--<span
                                            class="fax-icon&#45;&#45;default fax-icon"
                                            v-if="sender_number.is_default"
                                        ></span>
                                        <span
                                            class="fax-icon&#45;&#45;default-inactive fax-icon"
                                            v-else-if="!sender_number.is_default"
                                        ></span>-->
                                        <number v-bind:phone="sender_number.phone_number" withFlag='true'/>
                                      </li>
                                      <li class="fx-table__cell">
                                          <span
                                              class="fax-icon--done-green fax-icon"
                                              v-if="sender_number.status === 'active'"
                                          ></span>
                                          <span
                                              class="fax-icon--done-yellow fax-icon"
                                              v-else-if="sender_number.status === 'pending cancelation'"
                                          ></span>
                                          <span
                                              class="fax-icon--red-cross fax-icon"
                                              v-else-if="sender_number.status === 'canceled' || sender_number.status === 'expired' || sender_number.status === 'rejected' || sender_number.status === 'blocked'"
                                          ></span>
                                          <span>{{ sender_number.status }}</span>
                                      </li>
                                      <li>
                                          <span>{{ sender_number.note }}</span>
                                      </li>
                                      <li>
                                          <span>{{ sender_number.dt_start }}</span>
                                      </li>
                                      <li>
                                          <span>{{ sender_number.dt_end }}</span>
                                      </li>
                                  </ul>
                              </li>
                          </ul>
                      </div>
                      <div class="emty_data" v-else>
                            <div class="emty_data_icon">
                                <span class="fax-icon--remote-storage fax-icon"></span>
                            </div>
                            <div class="emty_data_title">
                                There is no any numbers
                            </div>
                            <!--<div class="emty_data_button" style="position: relative;max-width: 170px;margin: 0 auto;">
                                <button class="blue-button" v-on:click.prevent="showModalNew">
                                    <span class="fax-icon--plus fax-icon"></span>
                                    <span>Add New Number</span>
                                </button>
                            </div>-->
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <overlay :visible="isLoading"></overlay>
      <div class="modal_container" v-if="show_modal_new">
        <div class="modal_block">
          <div class="top_line clearfix">
              <div class="left_side">
                  <h1>New Number</h1>
              </div>
              <div class="right_side">
                  <a class="white-button" href="#" v-on:click.prevent="closeOrderModal">
                      <span class="fax-icon--cancel fax-icon"></span>
                      <span>Cancel</span>
                  </a>
                  <button class="blue-button" v-on:click.prevent="finishOrderModal" :disabled="group == null">
                      <span class="fax-icon--plus fax-icon"></span>
                      <span>Order</span>
                  </button>
              </div>
          </div>
          <div class="form_group">
              <label for="">SELECT COUNTRY</label>
              <multiselect v-model="country" :options="countryOptions"
                           :searchable="true"
                           :show-labels="false"
                           label="name"
                           track-by="id"
                           placeholder="Choose a country"
                           @input="reloadCountryChildrens">
                           <template slot="option" slot-scope="props">
                             <icon-flag v-bind:country_code="props.option.iso_code"/>
                             <span class="option__title">{{ props.option.name }}</span>
                           </template>
              </multiselect>
          </div>
          <div class="form_group" v-if="regionsVisible">
              <label for="">SELECT REGION</label>
              <multiselect v-model="region" :options="regionOptions" :searchable="true"
                           :show-labels="false"
                           :disabled="regionOptions.length == 0"
                           label="name"
                           track-by="id"
                           placeholder="Choose a region"
                           @input="reloadRegionCities">
                           <template slot="option" slot-scope="props">
                             <span class="option__title">{{ props.option.name }}</span>
                           </template>
              </multiselect>
          </div>
          <div class="form_group">
              <label for="">SELECT CITY</label>
              <multiselect v-model="city" :options="citiesOptions" :searchable="true"
                           :show-labels="false"
                           :disabled="citiesOptions.length == 0"
                           label="name"
                           track-by="id"
                           placeholder="Choose a city"
                           @input="reloadCitiesGroups">
                           <template slot="option" slot-scope="props">
                             <span class="option__title">{{ props.option.name }}</span>
                           </template>
              </multiselect>
          </div>
          <div class="form_group">
              <label for="">SELECT TYPE</label>
              <multiselect v-model="group" :options="groupOptions" :searchable="true"
                           :show-labels="false"
                           :disabled="groupOptions.length == 0"
                           label="name"
                           track-by="id"
                           placeholder="Choose a type">
                           <template slot="option" slot-scope="props">
                             <span class="option__title">{{ props.option.name }}</span>
                           </template>
              </multiselect>
          </div>
          <div class="prices">
            <span class="setup_price">${{ setup_price }}<sup>setup</sup> </span><span class="monthly_price"> / ${{ monthly_price }} <sup>monthly</sup></span>
          </div>
        </div>
      </div>
      <div class="modal_container" v-if="show_modal_finish">
        <div class="modal_block">
          <div class="top_line clearfix">
              <div class="left_side">
                  <h1>New Number</h1>
              </div>
              <div class="right_side">
                  <a class="white-button" href="#" v-on:click.prevent="closeOrderModal">
                      <span class="fax-icon--cancel fax-icon"></span>
                      <span>Cancel</span>
                  </a>
                  <button class="blue-button" type="submit" v-on:click.prevent="createOrder">
                      <span class="fax-icon--plus fax-icon"></span>
                      <span>Confirm Order</span>
                  </button>
              </div>
          </div>
          <div class="total-prices">
            <div><b>Setup price:</b> ${{ setup_price }}</div>
            <div><b>Monthly price:</b> ${{ monthly_price }}</div>
            <div><b>Total:</b> ${{ total_price }}</div>
            <span>Funds will be charged from your balance</span>
          </div>
        </div>
      </div>
      <div class="modal_container" v-if="show_modal_edit">
        <div class="modal_block">
          <form @submit.prevent="updateSenderNumber">
            <div class="top_line clearfix">
                <div class="left_side">
                    <h1>Edit Sender Number</h1>
                </div>
                <div class="right_side">
                    <a class="white-button" href="#" v-on:click.prevent="closeEditModal">
                        <span class="fax-icon--cancel fax-icon"></span>
                        <span>Cancel</span>
                    </a>
                    <button class="blue-button" type="submit">
                        <span class="fax-icon--plus fax-icon"></span>
                        <span>Save</span>
                    </button>
                </div>
            </div>
            <div class="form_group no-padding-vertical">
                <label>NOTE</label>
                <textarea v-model="senderNumberNote"></textarea>
            </div>
          </form>
        </div>
      </div>
    </div>
</template>

<script>
import Overlay from './components/Overlay.vue';
import FormattedNumber from './components/FormattedNumber.vue';
import Multiselect from 'vue-multiselect';
import IconFlag from './components/IconFlag.vue';
import configurationLeft from './components/configurationLeft.vue';
import { GET_SENDER_NUMBERS, UPDATE_SENDER_NUMBER } from '../store/actions/sender_numbers';
import { GET_REMOTE_COUNTRIES } from '../store/actions/remote_countries';
import { GET_REMOTE_REGIONS, RESET_REMOTE_REGIONS } from '../store/actions/remote_regions';
import { GET_REMOTE_CITIES, RESET_REMOTE_CITIES } from '../store/actions/remote_cities';
import { GET_REMOTE_DID_GROUPS, RESET_REMOTE_DID_GROUPS } from '../store/actions/remote_did_groups';
import { CREATE_DID_ORDER } from '../store/actions/orders';

export default {
components: {
   configurationLeft,
   'icon-flag': IconFlag,
   'overlay': Overlay,
   'number': FormattedNumber,
   Multiselect
 },
  data() {
    return {
      show_panel: true,
      show_action: false,
      show_modal_new: false,
      show_modal_finish: false,
      show_modal_edit: false,
      isLoading: true,
      checkedSenderNumbers: [],
      senderNumberNote: "",
      country: null,
      region: null,
      city: null,
      group: null
    };
  },
  computed: {
    setup_price() {
      if (this.group == null) {
        return '___'
      } else {
        return this.group.setup_price
      }
    },
    monthly_price() {
      if (this.group == null) {
        return '___'
      } else {
        return this.group.monthly_price
      }
    },
    total_price() {
      if (this.group) {
        return this.$lodash.toString(this.$lodash.sumBy([this.group.setup_price, this.group.monthly_price], Number));
      }
    },
    isEmptyOtions(){
      return this.senderNumbers.length === 0 ? true : false;
    },
    senderNumbers() {
      return this.$lodash.map(this.$store.getters.senderNumbers, (item) => {
        return this.$lodash.assignIn(item.attributes, {id: item.id});
      });
    },
    countryOptions() {
      return this.$lodash.map(this.$store.getters.remoteCountries, (item) => {
        return this.$lodash.assignIn(item.attributes, {id: item.id});
      });
    },
    regionsVisible: function() {
      return this.country &&
       (this.country.iso_code == 'US' || this.country.iso_code == 'CA')
    },
    regionOptions: function() {
      return this.$lodash.map(this.$store.getters.remoteRegions, (item) => {
        return this.$lodash.assignIn(item.attributes, {id: item.id});
      });
    },
    citiesOptions: function() {
      return this.$lodash.map(this.$store.getters.remoteCities, (item) => {
        return this.$lodash.assignIn(item.attributes, {id: item.id});
      });
    },
    groupOptions: function() {
      return this.$lodash.map(this.$store.getters.remoteDidGroups, (item) => {
        return this.$lodash.assignIn(item.attributes, {id: item.id});
      });
    },
    possibleToCancelService: function() {
      if (this.checkedSenderNumbers[0]) {
        var id = this.checkedSenderNumbers[0],
            item = this.$lodash.find(this.senderNumbers, function(c) { return c.id === id });

        return item.status === 'active'
      }
    }
  },
  methods: {
    reloadCountryChildrens: function(value, id) {
      this.resetRegions();
      this.resetCities();
      this.resetGroups();
      if (value) {
        if (this.regionsVisible) {
          this.reloadRegions(value.id);
        } else {
          this.reloadCities({country_id: value.id});
        }
      }
    },
    reloadRegionCities: function(value, id) {
      this.resetGroups();
      this.resetCities();
      if (value) {
        this.reloadCities({
          country_id: this.country.id,
          region_id: this.region.id
        });
      }
    },
    reloadCitiesGroups: function(value, id) {
      this.resetGroups();
      if (value) {
        this.reloadGroups(value.id);
      }
    },
    reloadRegions: function(country_id) {
      this.region = null;
      this.$store.dispatch(GET_REMOTE_REGIONS, country_id);
    },
    reloadCities: function(options = {}) {
      this.city = null;
      this.$store.dispatch(GET_REMOTE_CITIES, options);
    },
    reloadGroups: function(city_id) {
      this.group = null;
      this.$store.dispatch(GET_REMOTE_DID_GROUPS, city_id);
    },
    resetRegions: function() {
      this.region = null;
      this.$store.dispatch(RESET_REMOTE_REGIONS);
    },
    resetCities: function() {
      this.city = null;
      this.$store.dispatch(RESET_REMOTE_CITIES);
    },
    resetGroups: function() {
      this.group = null;
      this.$store.dispatch(RESET_REMOTE_DID_GROUPS);
    },
    showPanel: function() {
      var checked = this.checkedSenderNumbers;
      if (checked.length === 0) {
        this.show_panel = true;
        this.show_action = false;
      } else {
        this.show_panel = false;
        this.show_action = true;
      }
    },
    showModalNew: function() {
      this.$store.dispatch(GET_REMOTE_COUNTRIES).finally(() => {
        this.show_modal_new = true;
      });
    },
    finishOrderModal: function() {
      this.show_modal_new = false;
      this.show_modal_finish = true;
    },
    closeOrderModal: function() {
      this.show_modal_new = false;
      this.show_modal_finish = false;
      this.country = null;
      this.resetRegions();
      this.resetCities();
      this.resetGroups();
    },
    closeEditModal: function() {
      this.show_modal_edit = false;
      this.senderNumberNote = ''
    },
    openEditModal: function() {
      var id = this.checkedSenderNumbers[0],
          item = this.$lodash.find(this.senderNumbers, function(c) { return c.id === id });
      this.senderNumberNote = item.note;
      this.show_modal_edit = true;
    },
    createOrder: function() {
      const { sku_id, setup_price, monthly_price } = this.group;
      const total_amount = this.total_price;
      this.$store.dispatch(CREATE_ORDER, {
        sku_id, setup_price, monthly_price, total_amount
      }).then(() => {
        this.closeOrderModal();
        this.$router.push('/orders');
        this.$store.dispatch('showSuccessMessage', 'Order successfully created.');
      })
    },
    makeDefault: function() {
      this.$store.dispatch(UPDATE_SENDER_NUMBER, {
        id: this.checkedSenderNumbers[0],
        attributes: {
          is_default: true
        }
      }).then(() => {
        this.checkedSenderNumbers = []
        this.$store.dispatch('showSuccessMessage', 'DID updated as Default');
      })
    },
    updateSenderNumber: function() {
      this.$store.dispatch(UPDATE_SENDER_NUMBER, {
        id: this.checkedSenderNumbers[0],
        attributes: {
          note: this.senderNumberNote
        }
      }).then(() => {
        this.checkedSenderNumbers = [];
        this.show_modal_edit = false;
        this.$store.dispatch('showSuccessMessage', 'DID updated successfully');
      })
    },
    cancelService: function() {
      this.$store.dispatch(UPDATE_SENDER_NUMBER, {
        id: this.checkedSenderNumbers[0],
        attributes: {
          cancel_service: true
        }
      }).then(() => {
        this.checkedSenderNumbers = [];
        this.$store.dispatch('showSuccessMessage', 'DID successfully canceled.');
      })
    }
  },
  created() {
    this.$store.dispatch(GET_SENDER_NUMBERS).finally(() => {
      this.isLoading = false;
    });
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.configurations {
  .total-prices div {
    font-size: 1.7em;
    line-height: 1.5;
  }
  .total-prices span {
    margin-top: 20px;
    display: block;
  }
  .prices {
    text-align: center;
  }
  .prices sup {
    font-size: 0.3em;
    vertical-align: top;
  }
  .setup_price {
    color: #5684d2;
    font-size: 3.5em;
  }
  .monthly_price {
    color: #384f75;
    font-size: 4.0em;
  }
  .multiselect__content-wrapper {
    margin-top: 0px;
    border-top: 1px solid #e8e8e8;
    border-radius: 0 0 5px 5px;
  }
  .multiselect, .multiselect__input, .multiselect__single {
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
  }
  .multiselect__element {
    position: relative;
  }
  .multiselect__element:before {
    content: '';
    position: absolute;
    left: 15%;
    bottom: 0;
    width: 80%;
    border-bottom: 1px solid #E5EAF4;
  }
  .multiselect__option {
    display: flex;
    flex-flow: row nowrap;
  }
  .multiselect__option span:last-child {
    padding-left: 10px;
  }
  .multiselect__option--highlight {
    background-color: rgba(229,234,244,0.5);
    color: #35495e;
  }
  .multiselect--disabled .multiselect__select {
      background: #fff;
      color: #a6a6a6;
  }
  .multiselect__option--selected, .multiselect__option--highlight.multiselect__option--selected {
    background-color: #6391D6;
    color: #fff;
  }
}
</style>
