<template>
    <div>
      <section class="main_content">
          <div class="container">
              <div class="top_line clearfix">
                  <div class="left_side">
                      <h1>Phone Book</h1>
                  </div>
                  <div class="right_side">
                      <label class="white-button file__button" href="#">
                          <input
                              type="file"
                              value=""
                              ref="input"
                              @change="getCsvFile"
                          />
                          <span class="fax-icon--doc-csv fax-icon"></span>
                          <span>Import CSV file</span>
                      </label>
                      <a class="blue-button" href="#" v-if="!isEmptyOtions" v-on:click.prevent="showContactModal">
                          <span class="fax-icon--plus fax-icon"></span>
                          <span>Add New Contact</span>
                      </a>
                  </div>
              </div>
              <div class="main_sides clearfix">
                  <div class="left_side">
                      <router-link to="phonebook" class="white-button active_link">
                          <span class="fax-icon--user fax-icon"></span>
                          <span>Contacts</span>
                      </router-link>
                      <router-link to="groups" class="white-button">
                          <span class="fax-icon--group fax-icon"></span>
                          <span>Groups</span>
                      </router-link>
                  </div>
                  <div class="right_side" v-show="!isLoading">
                      <div class="table" v-if="!isEmptyOtions">
                          <ul class="table_top with_sort" v-show="show_panel">
                              <li>
                                  <label>
                                      <input type="checkbox" value="0" id="table_check_all" v-model="selectAll">
                                      <div class="checkbox_custom_not_check">
                                          <span class="fax-icon--checkbox fax-icon"></span>
                                      </div>
                                      <div class="checkbox_custom_check">
                                          <span class="fax-icon--checkbox-selected fax-icon"></span>
                                      </div>
                                  </label>
                              </li>
                              <li>
                                  <span>NAME</span>
                              </li>
                              <li>
                                  <span>FAX NUMBER</span>
                              </li>
                              <li>
                                  <span>NOTE</span>
                              </li>
                          </ul>
                          <ul class="table_top table_top_action" v-show="show_action">
                              <li>
                                  <label>
                                      <input type="checkbox" value="0" id="table_check_all" v-model="selectAll">
                                      <div class="checkbox_custom_not_check">
                                          <span class="fax-icon--checkbox fax-icon"></span>
                                      </div>
                                      <div class="checkbox_custom_check">
                                          <span class="fax-icon--checkbox-selected fax-icon"></span>
                                      </div>
                                  </label>
                              </li>
                              <li>
                                  <span>Choose an action:</span>
                                  <a class="white-button white-button-pannel"
                                      href="#"
                                      @click.prevent="showContactModal(true)"
                                      v-show="checkedContacts.length > 1"
                                  >
                                        <span class="fax-icon--pencil fax-icon"></span>
                                        <span>Edit</span>
                                  </a>

                                  <a class="white-button white-button-pannel"
                                   href="#"
                                   @click.prevent="bulkDeleteContacts()">
                                      <span class="fax-icon--trash fax-icon"></span>
                                      <span>Delete</span>
                                  </a>

                              </li>
                          </ul>
                          <div class="table_search clearfix"  :class="{ active: searchVal.length > 0 ? true : false }">
                              <div>
                                  <span class="fax-icon--search fax-icon"></span>
                                  <a href="#" @click.prevent="clearSearch">
                                      <span class="fax-icon--big-red-cross fax-icon"></span>
                                  </a>
                              </div>
                              <div>
                                  <input type="text" name="" id="" placeholder="Search" v-model="isActiveInput">
                              </div>
                          </div>
                          <ul class="result_item">
                              <li v-for="(contact, index) in filteredContacts" :key='index'>
                                  <ul>
                                      <li>
                                          <label>
                                              <input type="checkbox" :id="contact.id" :value="contact.id" v-model="checkedContacts" @change="showPanel">
                                              <div class="checkbox_custom_not_check">
                                                  <span class="fax-icon--checkbox fax-icon"></span>
                                              </div>
                                              <div class="checkbox_custom_check">
                                                  <span class="fax-icon--checkbox-selected fax-icon"></span>
                                              </div>
                                          </label>
                                      </li>
                                      <li>
                                        <span class="fax-icon--user fax-icon"></span>
                                        <span class="user_from">
                                            {{ contact.full_name }}
                                        </span>
                                      </li>
                                      <li>
                                          <number v-bind:phone="contact.phone_number" with-flag="true" />
                                      </li>
                                      <li>
                                          <span>{{ contact.note }}</span>
                                      </li>
                                  </ul>
                              </li>
                          </ul>
                      </div>
                      <div class="emty_data" v-else>
                            <div class="emty_data_icon">
                                <span class="fax-icon--user fax-icon"></span>
                            </div>
                            <div class="emty_data_title">
                                There is no any contacts
                            </div>
                            <div class="emty_data_button">
                                <a class="blue-button" href="#" v-on:click.prevent="showContactModal">
                                    <span class="fax-icon--plus fax-icon"></span>
                                    <span>Add New Contact</span>
                                </a>
                            </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
      <overlay :visible="isLoading"></overlay>
      <div class="modal_container" v-if="showModal">
        <div class="modal_block">
          <form @submit.prevent="saveContact">
            <input type="hidden" v-model="contactForm.id">
            <div class="top_line clearfix">
                <div class="left_side">
                    <h1>{{ contactFormTitle }}</h1>
                </div>
                <div class="right_side">
                    <a class="white-button" href="#" v-on:click.prevent="closeContactModal">
                        <span class="fax-icon--cancel fax-icon"></span>
                        <span>Cancel</span>
                    </a>
                    <button class="blue-button" type="submit">
                        <span class="fax-icon--plus fax-icon"></span>
                        <span>{{ contactFormButton }}</span>
                    </button>
                </div>
            </div>
            <div class="form_group">
                <label>FULL NAME</label>
                <input type="text" v-model="contactForm.full_name" required>
            </div>
            <div class="form_group">
              <label>FAX NUMBER</label>
              <PhoneInput
                :value="contactForm.phone_number"
                :code="contactForm.phone_country_code"
                @input="onPhoneChange(contactForm, $event)"
              />
            </div>
            <div class="form_group no-padding-vertical">
                <label>NOTE</label>
                <textarea v-model="contactForm.note"></textarea>
            </div>
          </form>
        </div>
      </div>
      <div class="modal_container" v-if="showModalCsv">
        <div class="modal_block modal_block__csv">
            <div class="top_line clearfix">
                <div class="left_side">
                    <h1>Imported Contacts</h1>
                </div>
                <div class="right_side">
                    <a class="white-button" href="#" v-on:click.prevent="closeCsvUpload">
                        <span class="fax-icon--cancel fax-icon"></span>
                        <span>Cancel</span>
                    </a>
                    <button class="blue-button" v-on:click.prevent="bulkCreateContacts" v-bind:disabled="!selectedCsvContacts">
                        <span class="fax-icon--plus fax-icon"></span>
                        <span>Create</span>
                    </button>
                </div>
            </div>
            <form action="">
                <div class="table">
                    <ul class="result_item csv_table">
                        <li class="select_all__csv">
                            <ul>
                                <li>
                                    <label>
                                        <input type="checkbox" value="0" v-model="selectedAllCsvContacts">
                                        <div class="checkbox_custom_not_check">
                                            <span class="fax-icon--checkbox fax-icon"></span>
                                        </div>
                                        <div class="checkbox_custom_check">
                                            <span class="fax-icon--checkbox-selected fax-icon"></span>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <span>
                                        Select All
                                    </span>
                                </li>
                            </ul>
                        </li>
                        <li v-for="(line, index) in showCsvFile" :key="index">
                            <ul>
                                <li>
                                    <label>
                                        <input type="checkbox" v-model="selectedCsv" :value="line.tmp_id" number>
                                        <div class="checkbox_custom_not_check">
                                            <span class="fax-icon--checkbox fax-icon"></span>
                                        </div>
                                        <div class="checkbox_custom_check">
                                            <span class="fax-icon--checkbox-selected fax-icon"></span>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <span class="fax-icon--user fax-icon"></span>
                                    <span>{{ line.full_name }}</span>
                                </li>
                                <li>
                                    <icon-flag v-bind:country_code="line.phone_country_code"/>
                                    <number v-bind:phone="line.phone_number"/>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </form>
        </div>
      </div>
    </div>
</template>

<script>
  import csv from 'parse-csv';
  import includes from 'lodash/includes';
  import Multiselect from 'vue-multiselect';
  import {getCountryCallingCode, parseNumber} from 'libphonenumber-js'

  import IconFlag from '../components/IconFlag.vue';
  import Overlay from '../components/Overlay.vue';
  import FormattedNumber from '../components/FormattedNumber.vue';
  import CountryPhoneCodes from '../../constants/country_phone_codes';
  import {
    BULK_CREATE_CONTACTS,
    BULK_DELETE_CONTACTS,
    CREATE_CONTACT,
    GET_CONTACTS,
    UPDATE_CONTACT,
  } from '../../store/actions/contacts';
  import PhoneInput from "../components/PhoneInput.vue";

  const getInitialData = () => ({
  contactForm: {
    id: null,
    full_name: "",
    phone_number: "",
    phone_country_code: "",
    note: "",
    title: "New Contact",
    button: "Add",
  }
});

export default {
  components: {
      PhoneInput,
    'icon-flag': IconFlag,
    'number': FormattedNumber,
    'overlay': Overlay,
    Multiselect
  },
  data() {
    const initialData = getInitialData();

    return {
      selected: [],
      show_panel: true,
      show_action: false,
      inbound: false,
      outbound: false,
      checkedContacts: [],
      searchVal: "",
      showModal: false,
      isLoading: true,
      showModalCsv: false,
      showCsvFile: {},
      selectedCsv: [],
      contactForm: initialData.contactForm,
      contactFormTitle: initialData.contactForm.title,
      contactFormButton: initialData.contactForm.button,
      countriesOptions: CountryPhoneCodes
    };
  },
  computed: {
    selectedAllCsvContacts: {
        get: function () {
            return this.showCsvFile ? this.selectedCsv.length == this.showCsvFile.length : false;
        },
        set: function (value) {
            var selected = [];

            if (value) {
                this.showCsvFile.forEach(function (user, index) {
                    selected.push(index);
                });
            }

            this.selectedCsv = selected;
        }
    },
    selectedCsvContacts() {
      return this.selectedCsv ? this.selectedCsv.length > 0 : false;
    },
    selectAll: {
      get: function() {
        this.showPanel();
        return this.filteredContacts
          ? this.checkedContacts.length == this.filteredContacts.length
          : false;
      },
      set: function(value) {
        const checked = [];

        if (value) {
          this.filteredContacts.forEach(function(item) {
            checked.push(item.id);
          });
        }

        this.checkedContacts = checked;
      }
    },
    contactFormCountryCode: {
      get: function() {
        const iso = this.contactForm.phone_country_code;
        return this.$lodash.find(this.countriesOptions, function(c) { return c.iso === iso });
      },
      set: function(value) {
        this.contactForm.phone_country_code = value.iso;
      }
    },
    isActiveInput: {
      get: function() {
        return this.searchVal;
      },
      set: function(value) {
        this.searchVal = value;
        // this.searchingFilter(value);
      }
    },
    contacts: function() {
      return this.$lodash.map(this.$store.getters.contacts, (item) => {
        return this.$lodash.assignIn(item.attributes, {id: item.id});
      });
    },
    filteredContacts() {
      console.log(this.searchVal);

      if (!this.searchVal) {
        return this.contacts;
      }


      return this.contacts.filter(contact => {
        return [contact.full_name, contact.phone_number, contact.note].some(fieldValue => {
          if (!fieldValue) {
            return false;
          }

          return fieldValue.toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1;
        })
      });
    },
    isEmptyOtions(){
      return this.contacts.length === 0;
    }
  },
  methods: {
    onPhoneChange(contactForm, data) {
      contactForm.phone_number = data.phone;
      contactForm.phone_country_code = data.countryPhoneModel.iso
    },
    showContactModal(edit = false) {
      this.isLoading = true

      var item, title, buttonTitle;
      const selectedId = this.checkedContacts[0];

      if (edit === true) {
        title = 'Edit Contact';
        buttonTitle = 'Update';
        item = this.$lodash.find(this.contacts, function(c) { return c.id === selectedId; });

        if (item === undefined) {
          this.$store.dispatch(GET_CONTACT, selectedId).then(() => {
            item = this.$lodash.find(this.contacts, function(c) { return c.id === selectedId; });
          })
        }

        item = {
            ...item,
            phone_number: parseNumber('+' + item.phone_number, {extended: true}).phone
        };
      } else {
        const initialData = getInitialData();
        item = initialData.contactForm;
        title = initialData.contactForm.title;
        buttonTitle = initialData.contactForm.button;
      }

      this.contactForm = item;
      this.contactFormTitle = title;
      this.contactFormButton = buttonTitle;

      this.showModal = true;
      this.isLoading = false;

      return true;
    },
    closeContactModal() {
      const initialData = getInitialData();

      this.showModal = false;
      this.contactForm = initialData.contactForm;
      this.contactFormTitle = initialData.contactForm.title;
      this.contactFormButton = initialData.contactForm.button;
    },
    saveContact: function(){
      if (this.contactForm.id === null) {
        this.createContact();
      } else {
        this.updateContact();
      }
    },
    createContact: function() {
      const { full_name, note, phone_country_code } = this.contactForm;
      const country_calling_code = getCountryCallingCode(phone_country_code);
      const phone_number = country_calling_code + this.contactForm.phone_number;

      this.$store.dispatch(CREATE_CONTACT, { full_name, phone_country_code, phone_number, note }).then(() => {
        this.closeContactModal();
        this.$store.dispatch('showSuccessMessage', 'Contact Created!');
      })
    },
    updateContact: function() {
      const { id, full_name, note, phone_country_code } = this.contactForm;
      const country_calling_code = getCountryCallingCode(phone_country_code);
      const phone_number = country_calling_code + this.contactForm.phone_number;

      this.$store.dispatch(UPDATE_CONTACT, { id: id, attributes: { full_name, phone_country_code, phone_number, note } }).then(() => {
        this.closeContactModal();
        this.$store.dispatch('showSuccessMessage', 'Contact Updated!');
      })
    },
    bulkDeleteContacts: function() {
      this.isLoading = true
      this.$store.dispatch(BULK_DELETE_CONTACTS, { contact_ids: this.checkedContacts }).then(() => {
        this.checkedContacts = [];
        this.isLoading = false;
        this.$store.dispatch('showSuccessMessage', 'Successfully deleted!');
      }).catch(() => {
        this.isLoading = false;
      })
    },
    bulkCreateContacts: function() {
      const selectedCsv = this.selectedCsv;
      const selectedCsvContacts = this.$lodash.filter(this.showCsvFile, function(contact){
        return includes(selectedCsv, contact.tmp_id)
      });
      this.$store.dispatch(BULK_CREATE_CONTACTS, { contacts: selectedCsvContacts }).then(() => {
        this.selectedAllCsvContacts = null;
        this.closeCsvUpload();
        this.$store.dispatch('showSuccessMessage', 'Successfully imported!');
      })
    },
    closeCsvUpload: function() {
        this.showModalCsv = false;
        this.showCsvFile = {};
        this.selectedCsv = [];
    },
    showPanel: function() {
      var checked = this.checkedContacts;
      if (checked.length === 0) {
        this.show_panel = true;
        this.show_action = false;
      } else {
        this.show_panel = false;
        this.show_action = true;
      }
    },
    clearSearch: function() {
      this.isActiveInput = "";
    },
    searchingFilter: function(value) {
      //Seaching filter
      if (value.length > 0) {
        var search = value.toLowerCase();

        return (this.contacts.filter(contact => {
          for (var prop in contact) {
            return contact[prop].toLowerCase().indexOf(search.toLowerCase()) > -1;
          }
        }));
      }
    },
    getCsvFile: function(e) {
        var fileTypes = ['csv'];
        var reader = new FileReader();
        var filecontent;
        var extension = e.target.files[0].name.split('.').pop().toLowerCase();
        var success = fileTypes.indexOf(extension) > -1;
        if(!success) {
            alert("Invalid file type: "+extension+".  Please use csv.");
            return
        } else {
            this.isLoading = true;
        }

        reader.onload = function(e) {
            if(e.target.readyState != 2) return;
            if(e.target.error) {
                alert('Error while reading file');
                return;
            }
            filecontent = e.target.result;
        };
        reader.onloadend = () =>{
            var obj = this.$lodash.map(csv.toJSON(filecontent, {headers: {included: true}}), function(item, index) {
              const phone = item.phone.replace(/[^0-9.]/g, "");
              return {
                tmp_id: index,
                full_name: item.full_name,
                phone_number: phone,
                phone_country_code: parseNumber('+' + phone, {extended: true}).country
              }
            });
            this.showCsvFile = obj;
            this.$refs["input"].value = '';
            this.isLoading = false;
            this.showModalCsv = true;
        }
        reader.readAsText(e.target.files[0]);
    }
  },
  created() {
    this.$store.dispatch(GET_CONTACTS).then(() => {
      this.isLoading = false;
    }).catch(() => {
      this.isLoading = false;
    });
  }
};
</script>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.phonebook {
  .multiselect {
    height: 0;
    min-height: 0;
  }
  .multiselect__select, .multiselect__tags {
    display: none!important;
  }
  .multiselect__content-wrapper {
    margin-top: 0px;
    border-top: 1px solid #e8e8e8;
    border-radius: 0 0 5px 5px;
  }
  .multiselect, .multiselect__input, .multiselect__single {
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
  }
  .multiselect__element {
    position: relative;
  }
  .multiselect__element:before {
    content: '';
    position: absolute;
    left: 15%;
    bottom: 0;
    width: 80%;
    border-bottom: 1px solid #E5EAF4;
  }
  .multiselect__option {
    display: flex;
    flex-flow: row nowrap;
  }
  .multiselect__option span:last-child {
    padding-left: 10px;
  }
  .multiselect__option--highlight {
    background-color: rgba(229,234,244,0.5);
    color: #35495e;
  }
  .multiselect__option--selected, .multiselect__option--highlight.multiselect__option--selected {
    background-color: #6391D6;
    color: #fff;
  }
}
</style>
