const secondsInMinute = 60;
const minutesInHour = 60;
const hoursInDay = 24;

const SECOND = 1000;
const MINUTE = SECOND * secondsInMinute;
const HOUR = MINUTE * minutesInHour;
const DAY = HOUR * hoursInDay;

export class Duration {
  static seconds(amount) {
    return SECOND * amount;
  }

  static minutes(amount) {
    return MINUTE * amount;
  }

  static hours(amount) {
    return HOUR * amount;
  }

  static days(amount) {
    return DAY * amount;
  }
}
