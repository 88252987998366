import { 
    GET_PAYMENTS,
    GET_PAYMENTS_SUCCESS,
    CREATE_PAYPAL
} from '../actions/payments';
import apiCall from '../../utils/api';

const state = { 
  payments: []
}

const getters = {
  payments: state => state.payments
}

const actions = {
  [GET_PAYMENTS]: ({commit, dispatch}) => {
    return new Promise((resolve, reject) => {
      const params = {
        params: {
          sort: '-created_at',
        }
      };

      apiCall.get('/payments', params)
      .then(function (response) {
        commit(GET_PAYMENTS_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        dispatch('showAlertMessage', 'Something went wrong')
        reject(error);
      });
    })
  },
  [CREATE_PAYPAL]: ({commit, dispatch}, amount) => {
    return new Promise((resolve, reject) => {
      apiCall.post('/paypals', { 
        data: { 
          type: "paypals", 
          attributes: { amount: amount } 
        }
      }).then(function (response) {
        resolve(response.data.data.attributes.redirect_url)
      }).catch(function (error) {
        var message;
        if (error.response.data.errors[0].status === "422") {
          message = error.response.data.errors[0].detail
        } else {
          message = 'Something went wrong'
        }
        dispatch('showAlertMessage', message)
        reject(error);
      });
    })
  }
}

const mutations = {
  [GET_PAYMENTS_SUCCESS]: (state, resp) => {
    state.payments = resp.data.data
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
