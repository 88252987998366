import { VERIFY_REQUEST, VERIFY_SUCCESS, VERIFY_FAILURE } from '../actions/verify';
import apiCall from '../../utils/api';

const state = { 
  confirmationToken: null
}

const getters = {
  userConfirmationToken: state => state.confirmationToken
}

const actions = {
  [VERIFY_REQUEST]: ({commit, dispatch}, attributes) => {
    return new Promise((resolve, reject) => {
      apiCall.post('/verification', { data: { 
                                              type: "verifications", 
                                              attributes: attributes 
                                            }})
      .then(function (response) {
        commit(VERIFY_SUCCESS, response)
        resolve(response)
      })
      .catch(function (error) {
        var message;
        if (error.response.data.errors[0].status === "422") {
          message = error.response.data.errors[0].detail
        } else {
          message = 'Something went wrong'
        }
        commit(VERIFY_FAILURE)
        dispatch('showAlertMessage', message)
        reject(error)
      });
    })
  }
}

const mutations = {
  [VERIFY_SUCCESS]: (state, response) => {
    state.confirmationToken = response.data.data.attributes.confirmation_token
  },
  [VERIFY_FAILURE]: (state) => {
    state.confirmationToken = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}